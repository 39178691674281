<template>
  <div class="body">
    <jjfatop></jjfatop>
    <div class="hyjifa">
      <div class="allzyyw">
        <div class="allzyywleft">
          <div class="label h g">
            <div class="c"></div>
            <div class="d">生物制药</div>
          </div>
          <div class="label h f">
            <div class="a"></div>
            <div class="b" @click="AutomobileManufacturing">汽车制造</div>
          </div>
          <div class="label h f">
            <div class="a"></div>
            <div class="b" @click="UrbanDrainage">城市排水</div>
          </div>
          <div class="label h f">
            <div class="a"></div>
            <div class="b" @click="DataCenter">数据中心</div>
          </div>
          <div class="label h f">
            <div class="a"></div>
            <div class="b" @click="CommercialBuildings">商业建筑</div>
          </div>
        </div>
        <div class="allzyywright">
          <div class="title1">
            <div class="left"></div>
            <div class="title">核心业务</div>
            <div class="big"></div>
            <div class="middle"></div>
            <div class="small"></div>
          </div>
          <div class="unimg">
            <div class="title">
              <div class="yuan"></div>
              <div class="label">BMS系统</div>
            </div>
            <p class="content2">
              系统通过对现场空调系统、空压系统、真空系统、蒸汽系统、氮气系统及设备等的监控，满足洁净车间对温湿度、室内压差、尘埃粒子等参数要求，通过优化的控制逻辑，达到节能降耗的目的。
            </p>
            <div class="title">
              <div class="yuan"></div>
              <div class="label">EMS系统</div>
            </div>
            <p class="content2">
              采用满足制药行业GMP法规认证的冗余数据采集系统，在保证数据现场监控的需求前提下，通过系统冗余、审计追踪等技术措施，确保生产工艺的相关数据准确有效。
            </p>
            <div class="title">
              <div class="yuan"></div>
              <div class="label">能源管理系统</div>
            </div>
            <p class="content2">
              针对各种能源需求及用能情况;能源质量;产品能源单耗;各工序能耗、重大能耗设备的能源利用情况等进行能耗统计、同环比分析、能源成本分析、用能预测、碳排放分析。助力企业实现能碳精细化管理。
            </p>
            <div class="unimgflex">
              <div class="img">
                <img
                  src="./../assets/官网切图0430/官网切图0430/生物制药_01.png"
                  alt=""
                />
                <div class="label">BMS系统</div>
              </div>
              <div class="img">
                <img
                  src="./../assets/官网切图0430/官网切图0430/生物制药_02.png"
                  alt=""
                />
                <div class="label">EMS系统</div>
              </div>
              <div class="img">
                <img
                  src="./../assets/官网切图0430/官网切图0430/生物制药_03.png"
                  alt=""
                />
                <div class="label">能源管理系统</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <under></under>
  </div>
</template>

<script>
import under from "../components/under.vue";
import jjfatop from "../components/jjfatop.vue";
export default {
  components: { under, jjfatop },
  data: function () {
    return {};
  },
  created() {},
  methods: {
    AutomobileManufacturing() {
      this.$router.push("/AutomobileManufacturing");
    },
    UrbanDrainage() {
      this.$router.push("/UrbanDrainage");
    },
    DataCenter() {
      this.$router.push("/DataCenter");
    },
    CommercialBuildings() {
      this.$router.push("/CommercialBuildings");
    },
  },
};
</script>

<style lang="less" scoped>
.body {
  width: 100%;
  .hyjifa {
    width: 100vw;
    background: rgba(244, 245, 246, 1);
    border: 1px solid rgba(244, 245, 246, 1);
    padding-bottom: 5vh;
    .allzyyw {
      width: 60vw;
      margin-top: 5vh;
      margin-left: 20vw;
      display: flex;
      .allzyywleft {
        width: 12vw;
        height: 100%;

        .label {
          width: 100%;
          height: 6vh;
          cursor: pointer;
          display: flex;
          .c {
            margin-left: 0.5vw;
            width: 4px;
            height: 12px;
            margin-top: calc((6vh - 12px) / 2);
            border-radius: 5px;
            background: rgba(61, 174, 109, 1);
          }
          .d {
            width: 70%;

            line-height: 6vh;
            margin-left: 0.5vw;
            align-items: center;
            color: rgba(61, 174, 109, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 1vw;
          }
          .b {
            width: 70%;
            // border: 1px solid red;
            line-height: 6vh;
            margin-left: 0.5vw;
            align-items: center;
            color: rgba(31, 31, 31, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 1vw;
          }
          .a {
            width: 4px;
            height: 4px;
            margin-top: calc((6vh - 4px) / 2);
            margin-left: 0.5vw;
            border-radius: 50%;
            opacity: 0.5;
            background: rgba(61, 174, 109, 1);
          }
        }
        .label:hover {
          background: rgba(248, 253, 250, 1);
        }
        .g {
          border-right: 2px solid rgba(61, 174, 109, 1);
          background: linear-gradient(
            90deg,
            rgba(244, 255, 249, 0) 0%,
            rgba(248, 253, 250, 1) 100%
          );
        }
        .h {
          border-bottom: 1px solid rgba(61, 174, 109, 0.1);
        }
        .f {
          border-right: 2px solid rgba(61, 174, 109, 0.1);
        }
      }
      .allzyywright {
        width: 48vw;
        .title1 {
          display: flex;
          height: 5vh;
          margin-bottom: 3vh;
          .left {
            margin-top: 1.45vh;
            margin-left: 2.5vw;
            width: 0.7vh;
            height: 2.1vh;
            border-radius: 5px;
            background: rgba(61, 174, 109, 1);
          }
          .title {
            margin-left: 1vw;
            line-height: 5vh;
            color: rgba(51, 51, 51, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 2.5vh;
          }
          .big {
            margin-left: 0.5vw;
            margin-top: 2.05vh;
            width: 0.9vh;
            height: 0.9vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
          .middle {
            margin-left: 0.5vw;
            margin-top: 2.2vh;
            width: 0.6vh;
            height: 0.6vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
          .small {
            margin-left: 0.5vw;
            margin-top: 2.35vh;
            width: 0.3vh;
            height: 0.3vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
        }
        .unimg {
          width: 45vw;
          margin-left: 1.5vw;
          margin-bottom: 3vh;
          .title {
            height: 5vh;
            line-height: 5vh;
            display: flex;
            margin-left: 1vw;
            .yuan {
              width: 0.4vh;
              height: 0.4vh;
              border-radius: 50%;
              margin-top: 2.3vh;
              background: rgba(61, 174, 109, 1);
            }
            .label {
              margin-left: 0.5vw;
              color: rgba(51, 51, 51, 1);
              font-family: Microsoft YaHei;
              font-size: 0.85vw;
              font-weight: bold;
            }
          }
          .content2 {
            margin-left: 1.5vw;
            width: 42vw;
            color: rgba(102, 102, 102, 1);
            font-family: Microsoft YaHei;
            font-size: 0.85vw;
            line-height: 3.4vh;
            margin-top: 1vh;
            margin-bottom: 2vh;
          }
          .unimgflex {
            width: 45vw;
            display: flex;
            .img {
              width: 15vw;
              text-align: center;
              img {
                width: 90%;
              }
              .label {
                margin-top: 1vh;
                margin-bottom: 2vh;
                color: rgba(51, 51, 51, 1);
                font-family: Microsoft YaHei;
               font-size: 0.6vw;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}
</style>
