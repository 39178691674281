<template>
  <div class="head">
    <div class="top">
 <img src="./../assets/枢源LOGO02.svg" alt="" class="gslogo" />

      <div class="type_item type_item1" @click="index">首页</div>

      <div class="type_item type_item1" @click="AboutUs">关于枢源</div>

      <div class="type_item typeActive" @click="MainBusiness">
        主营业务
        <div class="typeActive1 '"></div>
      </div>

      <div class="type_item type_item1" @click="InoustaySolutions">
        行业解决方案
      </div>

      <div class="type_item type_item1" @click="ServiceCase">服务案例</div>

      <div class="type_item type_item1" @click="NewsInformation">新闻资讯</div>

      <div class="type_item type_item1" @click="ContactUs">联系我们</div>
      <div class="a">
        <div class="ab">服务热线：021-64026001</div>
      </div>
    </div>
    <img
      src="./../assets/官网子页面切图/官网子页面切图/标题文字_主营业务.svg"
      alt=""
      class="al"
    />
  </div>
</template>

<script>
export default {
  data: function () {
    return {};
  },
  methods: {
    index() {
      this.$router.push("/");
    },
    AboutUs() {
      this.$router.push("/AboutUs");
    },
    InoustaySolutions() {
      this.$router.push("/InoustaySolutions");
    },
    ServiceCase() {
      this.$router.push("/ServiceCase");
    },
    NewsInformation() {
      this.$router.push("/NewsInformation");
    },
    ContactUs() {
      this.$router.push("/ContactUs");
    },
    MainBusiness() {
      this.$router.push("/MainBusiness");
    },
  },
};
</script>

<style lang = "less" scoped>
.head {
  height: 17.1875vw;
  width: 100vw;
  background-image: url("./../assets/官网压缩图片/top_主营业务.png");
  background-size: cover;
  background-position: center center;
  .top {
    height: 9vh;
    background: rgba(31, 31, 31, 0.2);
    display: flex;
    .gslogo {
      margin-top: 1.5vh;
      margin-left: 15vw;
      height: 6vh;
      margin-right: 2vw;
    }
    .type_item {
      position: relative;
      margin-left: 2.2vw;
      color: rgb(255, 255, 255);
      font-size: 1vw;
      margin-top: 2.9vh;
      height: 3.2vh;
      cursor: pointer;
      line-height: 3.2vh;
    }
    .type_item1:hover {
      color: rgb(61, 174, 109);
    }
    .typeActive {
      /* color: rgb(61, 174, 109); */
      color: #87ffba;
      font-weight: bold;
    }
    .typeActive1 {
      width: 50%;
      margin-left: 25%;
      margin-top: 0.5vh;
      height: 2px;
      background: rgb(61, 174, 109);
    }

    .a {
      position: relative;
      margin-left: 3vw;
      color: rgb(255, 255, 255);
      .ab {
        background: rgb(61, 174, 109);
        width: 12vw;
        border-radius: 50px;
        text-align: center;
        font-size: 0.9vw;
        color: rgb(255, 255, 255);
        font-family: Microsoft YaHei;
        letter-spacing: 12%;
        margin-top: 2.5vh;
        height: 4vh;
        line-height: 4vh;
      }
    }
  }
  .al {
    height: 6vh;
    margin-top: 12vh;
    margin-left: 23vw;
  }
}
</style>
