<template>
  <div class="body">
    <xwzxtop></xwzxtop>
    <div class="xwxq">
      <div class="allzyywright">
        <div class="headtop">
          <div class="green" @click="NewsInformation">新闻资讯</div>
          <div class="middle">></div>
          <div class="black">新闻详情</div>
        </div>
        <div class="xqcontent">
          <div class="title">为节能减排做出贡献，楼宇自控系统比例巨大！</div>
          <div class="time">时间：2021-12-13</div>
          <div class="title1">
            <div class="left"></div>
            <div class="titles">楼宇自控系统的建设目标：</div>
            <div class="big"></div>
            <div class="middle"></div>
            <div class="small"></div>
          </div>
          <div class="litflex">
            <div class="yd"></div>
            创造舒适环境
          </div>
          <p class="content1">
            为使用者创造一个安全、方便、舒适、效率高、品质高的环境。
          </p>
          <div class="litflex">
            <div class="yd"></div>
            降低运营能耗
          </div>
          <p class="content1">
            对耗能，如暖通空调、冷热源装置、照明、水泵等机电设备严格进行监控、以节约能源、降低运营成本。
          </p>
          <div class="litflex">
            <div class="yd"></div>
            保障设备的安全
          </div>
          <p class="content1">
            把楼内的机电设备纳入楼宇自控系统，以实现对每一台设备的在线实时监控并进行科学的管理，确保各类机电设备的安全、可靠地运行并得到及时维护，延长其使用寿命。
          </p>
          <div class="litflex">
            <div class="yd"></div>
            实现物业管理现代化
          </div>
          <p class="content1">
            楼宇自控系统的主要任务之一是对机电设备实现优化管理，达到自动化、智能化，从而实现优化物业管理，在合理的投资下尽量提高建筑物的智能化与现代化的形象，以求大的经济效益和较高的回报率。
          </p>
          <div class="litflex">
            <div class="yd"></div>
            为楼宇自控系统集成奠定基础
          </div>
          <p class="content1">
            采用国际标准（ISO16484-5）开放型BACnet和LONWORKS协议的BA产品，为楼内其它弱电系统的集成提供软件、硬件平台和接口。
          </p>
          <div class="ct">
            楼宇自控系统的控制和管理内容包括：冷源系统的监测、空调通风系统的监控、给排水系统的监控、照明系统和电梯系统。为保障楼宇自控系统安全运行的可靠性，建筑设备监控系统所用的电源，必须全部取自同一相电源，如现场DDC全部取自A相的话，则中控室部分的设备也必须全部取自A相电源，且与系统集成有关的其它子系统也必须取自同一相电源。
          </div>
          <img src="./../assets/官网补充/官网补充/img_新闻04.png" alt="" />
        </div>
      </div>
    </div>
    <under></under>
  </div>
</template>

<script>
import under from "../components/under.vue";
import xwzxtop from "../components/xwzxtop.vue";
export default {
  components: { under, xwzxtop },
  data: function () {
    return {};
  },

  created() {},
  methods: {
    NewsInformation() {
      this.$router.push("/NewsInformation");
    },
  },
};
</script>

<style lang="less" scoped>
.body {
  width: 100%;
  .xwxq {
    width: 100vw;
    padding-bottom: 5vh;
    background: rgba(244, 245, 246, 1);
    border: 1px solid rgba(244, 245, 246, 1);
    .allzyywright {
      width: 60vw;
      margin-top: 5vh;
      margin-left: 20vw;
      .headtop {
        width: 44vw;
        height: 5vh;
        display: flex;
        line-height: 5vh;
        .green {
          margin-left: 1vw;
          color: rgba(61, 174, 109, 1);
          font-family: Microsoft YaHei;
          font-size: 1vw;
          cursor: pointer;
        }
        .middle {
          color: rgba(51, 51, 51, 1);
          font-family: Microsoft YaHei;
          font-size: 1vw;
          margin-left: 0.5vw;
        }
        .black {
          color: rgba(51, 51, 51, 1);
          font-family: Microsoft YaHei;
          font-size: 1vw;
          margin-left: 0.5vw;
        }
      }
      .xqcontent {
        margin-top: 3vh;
        width: 60vw;
        padding-bottom: 5vh;
        border-radius: 10px;
        background: rgba(255, 255, 255, 1);
        .title {
          padding-top: 1vh;
          margin-left: 1.5vw;
          color: rgba(51, 51, 51, 1);
          font-family: Microsoft YaHei;
          font-weight: bold;
          font-size: 1.5vw;
          line-height: 6vh;
        }
        .time {
          margin-left: 1.5vw;
          color: rgba(153, 153, 153, 1);
          font-family: Microsoft YaHei;
          font-size: 0.7vw;
        }
        .title1 {
          display: flex;
          height: 5vh;
           margin-top: 2vh;
          margin-bottom: 2vh;
          .left {
            margin-top: 1.45vh;
            margin-left: 2.5vw;
            width: 0.7vh;
            height: 2.1vh;
            border-radius: 5px;
            background: rgba(61, 174, 109, 1);
          }
          .titles {
            margin-left: 1vw;
            line-height: 5vh;
            color: rgba(51, 51, 51, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 2.5vh;
          }
          .big {
            margin-left: 0.5vw;
            margin-top: 2.05vh;
            width: 0.9vh;
            height: 0.9vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
          .middle {
            margin-left: 0.5vw;
            margin-top: 2.2vh;
            width: 0.6vh;
            height: 0.6vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
          .small {
            margin-left: 0.5vw;
            margin-top: 2.35vh;
            width: 0.3vh;
            height: 0.3vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
        }
        .litflex {
          height: 5vh;
          display: flex;
          font-size: 0.9vw;
          margin-left: 1.5vw;
          color: rgba(51, 51, 51, 1);
          font-family: Microsoft YaHei;
          line-height: 5vh;
          font-weight: bold;
          .yd {
            width: 0.5vh;
            height: 0.5vh;
            margin-top: 2.25vh;
            border-radius: 50%;
            margin-right: 0.5vw;
            background: rgba(61, 174, 109, 1);
          }
        }
        .content1 {
          margin-top: 1vh;
          width: 56vw;
          margin-left: 2.5vw;
          color: rgba(102, 102, 102, 1);
          font-family: Microsoft YaHei;
          font-size: 0.85vw;
          line-height: 3.4vh;
          margin-bottom: 2vh;
        }
        .ct {
          margin-top: 5vh;
          width: 57vw;
          // color: rgba(51, 51, 51, 1);
           color: rgba(102, 102, 102, 1);
          font-family: Microsoft YaHei;
          font-size: 0.85vw;
          margin-left: 1.5vw;
          line-height: 3.4vh;
          text-align: justify;
        }
        img {
          margin-top: 4vh;
          width: 57vw;
          margin-left: 1.5vw;
        }
      }
    }
  }
}
</style>
