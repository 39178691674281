<template>
  <div class="body">
    <xwzxtop></xwzxtop>
    <div class="xwxq">
      <div class="allzyywright">
        <div class="headtop">
          <div class="green" @click="NewsInformation">新闻资讯</div>
          <div class="middle">></div>
          <div class="black">新闻详情</div>
        </div>
        <div class="xqcontent">
          <div class="title">赋能建筑业实现低碳转型</div>
          <div class="time">时间：2022-02-04</div>
          <div class="ct">
            零碳建筑是以人、建筑和自然环境的协调发展为目标，在利用天然条件和人工手段创造良好、健康的居住环境的同时，尽可能地控制和减少对自然环境的使用和破坏，充分体现向大自然索取和回报之间的平衡。置身于零碳建筑内部，能够充分体会到生态环境与人的有机融合，一方面给人们创造了一个健康、高效和适用的空间，另一方面人们通过低碳生活方式的转变提升了内心的满足感。
          </div>
          <div class="ct">
            对于零碳建筑的建设离不开数字技术的支撑。当下数字技术促进建筑节能的着力点主要在于系统性节能规划与设计、运行阶段的能效提升和基于能源微网的柔性用能实现。在建筑运行阶段其核心是绿色建筑和智慧建筑融合，作为帮助建筑业低碳转型而生的行有嘉数字能效平台是基于传感器、计量设备的能源管理，在线监测、分析与计算各项能源指标，智能化控制的能源需求，通过对设备和环境的实时感知、智能决策和自我控制，实现建筑内部能源资源利用最优和经济绿色运行。
          </div>
          <div class="title1">
            <div class="left"></div>
            <div class="titles">建筑能效提升</div>
            <div class="big"></div>
            <div class="middle"></div>
            <div class="small"></div>
          </div>
          <div class="ct1">
            全面提升建筑能效，在实现以人为本的节能降耗和深度减排在低碳目标的约束下，通过科技手段实现低碳与体验的双赢。最终实现以人为本的建筑节能降耗和深度减排，在不降低用户体验的前提下实现整体能源使用需量和碳排放的控制。
          </div>
          <div class="litflex">
            <div class="yd"></div>
            建设“用能大脑”
          </div>
          <p class="content1">
            建筑就像活的有机体，多个系统必须实现互通及算法协同工作，才能达到舒适，节能的效果。行有嘉数字能效是监控和管理所有建筑机电设施的中央系统，可以集成智能管理楼控、智能照明、消防、低压等各种设备设施子系统，促进建筑用能效率提升。
          </p>
          <div class="litflex">
            <div class="yd"></div>
            AI节能
          </div>
          <p class="content1">
            虽然目前建筑电气部分有很多单独的节能措施，但是同时也需要一个系统实现统一调节和管理。在对单个用能系统进行能耗监测、能效评定以及远程控制的基础上，配合对设备和环境的实时感知，实现用能系统能源资源利用最优和经济绿色运行。
          </p>
          <div class="litflex">
            <div class="yd"></div>
            节能改造
          </div>
          <p class="content1">
            运用高效电制冷/热、高密度低成本蓄冷/热、储能等技术和装备，针对现有系统做节能改造，例如余能回收、冰/水蓄冷、地源/空气源调节等技术。
          </p>
          <div class="title1">
            <div class="left"></div>
            <div class="titles">智慧运维</div>
            <div class="big"></div>
            <div class="middle"></div>
            <div class="small"></div>
          </div>
          <div class="ct1">
            利用数字化技术，帮助建筑实现运营管理的智慧运维。通过基于全生命周期的数字化管理手段，帮助建筑用户实现运维管理过程的低碳和敏捷，助力建筑资产的保值增值。结合能源管理系统与楼控系统的通信交互，实现系统之间的配合与持续改进，让建筑运维低碳智能。
          </div>
          <div class="litflex">
            <div class="yd"></div>
            运营策略优化
          </div>
          <p class="content1">
            数字化平台为建筑运营阶段的物业工作提供数据支撑及验证手段。借助行有嘉数字能效平台的可视化呈现和能效评定系统，能够帮助运维人员快速了解面临的问题，在平台的辅助下制定科学的运营策略，从管理层、操作层以及社会公众层满足对建筑运营管理的需求。
          </p>
          <div class="litflex">
            <div class="yd"></div>
            大数据运维
          </div>
          <p class="content1">
            云计算、大数据、机器学习等新技术，对数据分析和积累，建立运维诊断专家数据库，实现预测性维护。通过故障自诊断，定位故障并发起处理流程，安排服务工单形成运维流程闭环，提供运维措施和预案指导运维人员；系统经过对既往故障与解决措施的存储分析与自学习，不断自我迭代，使运维专业知识与经验得以传承。
          </p>
          <div class="title1">
            <div class="left"></div>
            <div class="titles">能源微网</div>
            <div class="big"></div>
            <div class="middle"></div>
            <div class="small"></div>
          </div>
          <div class="ct1">
            建筑在全生命周期内，想要通过能效提升和使用低碳材料和设计是无法从根本上实现绝对的‘零碳’的，必须要通过建立建筑自身清洁能源的生产和消纳来实现。（对于建筑是否可以通过“碳税”“碳中和”等措施来补偿寿命周期内的排放目前业界尚存在较大争议。）这就要求零碳建筑要组建能源微网，优化能源结构和能源管理，实现多能互补。
          </div>
          <div class="litflex">
            <div class="yd"></div>
            分布式太阳能/风电
          </div>
          <p class="content1">
            随着新能源建设成本的降低和发电效率的提升，以及投资新能源的回收周期的缩短，现代建筑的分布式光伏普及率越来越高。随着相关材料技术的进步，新能源与建筑正从结合走向融合。针对建筑清洁能源生产，想象未来可以通过光伏建筑一体化的方式增加新能源接入，通过数字化管理的方式建立对分布式清洁能源的管理。
          </p>
          <div class="litflex">
            <div class="yd"></div>
            多能互补
          </div>
          <p class="content1">
            针对存在外部能源输入和多种清洁能源相结合的情况下，构建以分布式新能源为主供，主网为补充的新型能源系统将成为主流。对于新能源时间特征和区域特性造成的不平衡问题，可以通过能源微网的智能调整来优化。对于区域能源供应，可以实现清洁能源调剂和区域互济；在场内清洁能源供给不足的情况下可通过场外新能源补给，形成用能多元互补支撑结构。
          </p>
          <div class="litflex">
            <div class="yd"></div>
            源荷互动
          </div>
          <p class="content1">
            做好能源“源-荷-网”的动态调节，解决供给侧和需求侧的不同步的问题，使建筑用电由目前的刚性负载转变为柔性负载，行有嘉数字能效平台根据清洁能源发电量、环境因素、电费规律，负荷情况等调配清洁能源、储能和可调节负载，以释能和蓄能的形式实现建筑本体的“源荷互动”，提高能源使用效率，实现清洁能源的最大化就地消纳。
          </p>
          <img
            src="./../assets/官网切图汇总/官网切图补充/新闻资讯_08.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <under></under>
  </div>
</template>

<script>
import under from "../components/under.vue";
import xwzxtop from "../components/xwzxtop.vue";
export default {
  components: { under, xwzxtop },
  data: function () {
    return {};
  },

  created() {},
  methods: {
    NewsInformation() {
      this.$router.push("/NewsInformation");
    },
  },
};
</script>

<style lang="less" scoped>
.body {
  width: 100%;
  .xwxq {
    width: 100vw;
    padding-bottom: 5vh;
    background: rgba(244, 245, 246, 1);
    border: 1px solid rgba(244, 245, 246, 1);
    .allzyywright {
      width: 60vw;
      margin-top: 5vh;
      margin-left: 20vw;
      .headtop {
        width: 44vw;
        height: 5vh;
        display: flex;
        line-height: 5vh;
        .green {
          margin-left: 1vw;
          color: rgba(61, 174, 109, 1);
          font-family: Microsoft YaHei;
          font-size: 1vw;
          cursor: pointer;
        }
        .middle {
          color: rgba(51, 51, 51, 1);
          font-family: Microsoft YaHei;
          font-size: 1vw;
          margin-left: 0.5vw;
        }
        .black {
          color: rgba(51, 51, 51, 1);
          font-family: Microsoft YaHei;
          font-size: 1vw;
          margin-left: 0.5vw;
        }
      }
      .xqcontent {
        margin-top: 3vh;
        width: 60vw;
        padding-bottom: 5vh;
        border-radius: 10px;
        background: rgba(255, 255, 255, 1);
        .title {
          padding-top: 1vh;
          margin-left: 1.5vw;
          color: rgba(51, 51, 51, 1);
          font-family: Microsoft YaHei;
          font-weight: bold;
          font-size: 1.5vw;
          line-height: 6vh;
        }
        .time {
          margin-left: 1.5vw;
          color: rgba(153, 153, 153, 1);
          font-family: Microsoft YaHei;
          font-size: 0.7vw;
        }
        .title1 {
          display: flex;
          height: 5vh;
          margin-top: 2vh;
          margin-bottom: 2vh;
          .left {
            margin-top: 1.45vh;
            margin-left: 2.5vw;
            width: 0.7vh;
            height: 2.1vh;
            border-radius: 5px;
            background: rgba(61, 174, 109, 1);
          }
          .titles {
            margin-left: 1vw;
            line-height: 5vh;
            color: rgba(51, 51, 51, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 2.5vh;
          }
          .big {
            margin-left: 0.5vw;
            margin-top: 2.05vh;
            width: 0.9vh;
            height: 0.9vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
          .middle {
            margin-left: 0.5vw;
            margin-top: 2.2vh;
            width: 0.6vh;
            height: 0.6vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
          .small {
            margin-left: 0.5vw;
            margin-top: 2.35vh;
            width: 0.3vh;
            height: 0.3vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
        }
        .litflex {
          height: 5vh;
          display: flex;
          font-size: 0.9vw;
          margin-left: 1.5vw;
          color: rgba(51, 51, 51, 1);
          font-family: Microsoft YaHei;
          line-height: 5vh;
          font-weight: bold;
          .yd {
            width: 0.5vh;
            height: 0.5vh;
            margin-top: 2.25vh;
            border-radius: 50%;
            margin-right: 0.5vw;
            background: rgba(61, 174, 109, 1);
          }
        }
        .content1 {
          margin-top: 1vh;
          width: 56vw;
          margin-left: 2.5vw;
          color: rgba(102, 102, 102, 1);
          font-family: Microsoft YaHei;
          font-size: 0.85vw;
          line-height: 3.4vh;
          margin-bottom: 2vh;
        }
        .ct {
          margin-top: 5vh;
          width: 57vw;
          color: rgba(102, 102, 102, 1);
          font-family: Microsoft YaHei;
          font-size: 0.85vw;
          margin-left: 1.5vw;
          line-height: 3.4vh;
          text-align: justify;
        }
        .ct1 {
          margin-top: 5vh;
          margin-bottom: 5vh;
          width: 57vw;
          color: rgba(102, 102, 102, 1);
          font-family: Microsoft YaHei;
          font-size: 0.85vw;
          margin-left: 1.5vw;
          line-height: 3.4vh;
          text-align: justify;
        }
        img {
          margin-top: 4vh;
          width: 57vw;
          margin-left: 1.5vw;
        }
      }
    }
  }
}
</style>
