<template>
  <div class="underAll">
    <div class="gsjj">
      <div class="gsjja">
        <!-- <img
          src="./../assets/枢源管网改图/01 - 公司LOGO 纯绿.svg"
          alt=""
          class="gsjjaimga"
        /> -->
       <img
        src="./../assets/枢源LOGO01.svg"
        alt=""
          class="gsjjaimga"
      />
        <div class="gsjjaa">公司：上海枢源节能科技有限公司</div>
         <div >电话：159 2131 4888</div>
        <div>地址：上海市闵行区联航路1188号</div>
        <div style="margin-left: 3.45vw">10号楼2层F/G室</div>
         
        <!-- <img src="./../assets/官网切图/微信.svg" alt="" class="gsjjaimgb" /> -->
      </div>
      <div class="gsjjb">
        <div class="title" @click="AboutUs">关于枢源</div>
        <div class="content" @click="AboutUs">公司介绍</div>
        <div class="content" @click="AboutUs">成长经历</div>
        <div class="content" @click="AboutUs">荣誉资质</div>
        <div class="content" @click="ContactUs">联系我们</div>
      </div>
      <div class="gsjjc">
        <div class="title" @click="MainBusiness">主营业务</div>
        <div class="content" @click="MainBusiness">智控系统集成</div>
        <div class="content" @click="MainSoftware">软件平台产品</div>
        <div class="content" @click="MainSystem">系统节能改造</div>
        <div class="content" @click="MainEnergy">能源系统集成</div>
      </div>
      <div class="gsjjd">
        <div class="title" @click="InoustaySolutions">解决方案</div>
        <div class="content" @click="InoustaySolutions">生物制药</div>
        <div class="content" @click="AutomobileManufacturing">汽车制造</div>
        <div class="content" @click="UrbanDrainage">城市排水</div>
        <div class="content" @click="DataCenter">数据中心</div>
        <!-- <div class="content" @click="CommercialBuildings">商业建筑</div> -->
      </div>
      <div class="gsjje">
        <div class="title" @click="ServiceCase">服务案例</div>
        <div class="content" @click="ServiceCase">智控系统集成</div>
        <div class="content" @click="ServiceSoftware">软件平台产品</div>
        <div class="content" @click="ServiceSystem">系统节能改造</div>
        <div class="content" @click="ServiceEnergy">能源系统集成</div>
      </div>
      <div class="gsjjf">
        <div class="title" @click="NewsInformation">新闻资讯</div>
        <div class="content" @click="NewsInformation">公司要闻</div>
        <div class="content" @click="NewsInformation">行业资讯</div>
      </div>
    </div>
    <div class="line"></div>
    <div class="gsjjab">
      版权所有 © 上海枢源节能科技有限公司
      <span class="gsjjabc" @click="beian"> 沪ICP备17047602号-1 </span>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {};
  },
  methods: {
    AboutUs() {
      this.$router.push("/AboutUs");
    },
    ContactUs() {
      this.$router.push("/ContactUs");
    },
    MainBusiness() {
      this.$router.push("/MainBusiness");
    },
    nygl() {
      this.$router.push("/nygl");
    },
    MainSoftware() {
      this.$router.push("/MainSoftware");
    },
    MainSystem() {
      this.$router.push("/MainSystem");
    },
    MainEnergy() {
      this.$router.push("/MainEnergy");
    },
    InoustaySolutions() {
      this.$router.push("/InoustaySolutions");
    },
    AutomobileManufacturing() {
      this.$router.push("/AutomobileManufacturing");
    },
    UrbanDrainage() {
      this.$router.push("/UrbanDrainage");
    },
    DataCenter() {
      this.$router.push("/DataCenter");
    },
    beian() {
      window.open("https://beian.miit.gov.cn/", "_blank");
    },
    ServiceCase() {
      this.$router.push("/ServiceCase");
    },
    ServiceSoftware() {
      this.$router.push("/ServiceSoftware");
    },
    ServiceSystem() {
      this.$router.push("/ServiceSystem");
    },
    ServiceEnergy() {
      this.$router.push("/ServiceEnergy");
    },
    NewsInformation() {
      this.$router.push("/NewsInformation");
    },
  },
};
</script>

<style lang="less" scoped>
.underAll {
  width: 100vw;
  background: black;
  .gsjj {
    width: 100vw;
    padding-bottom: 2vh;
    display: flex;
    color: rgb(255, 255, 255);
    .gsjja {
      width: 15vw;
      margin-top: 10vh;
      margin-left: 15vw;
      .gsjjaimga {
        // margin-top: 1vh;
        margin-left: 1vw;
        height: 6vh;
      }
      .gsjjaa {
        margin-top: 3vh;
      }
      div {
        margin-top: 1.5vh;
        margin-left: 1vw;
        color: rgba(255, 255, 255, 0.6);
        line-height: 2.5vh;
        font-family: Microsoft YaHei;
        font-size: 0.85vw;
        // letter-spacing: 12%;
      }
    }
    .gsjjb {
      margin-top: 10vh;
      margin-left: 10vw;
      width: 7vw;
      .title {
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 1.1vw;
        margin-bottom: 1vh;
      }
      .content {
        height: 4vh;
        line-height: 4vh;
        color: rgba(255, 255, 255, 0.6);
        font-family: Microsoft YaHei;
        font-size: 0.75vw;
        cursor: pointer;
      }
      .content:hover {
        color: rgba(61, 174, 109, 1);
      }
    }
    .gsjjc {
      margin-top: 10vh;
      margin-left: 3vw;
      width: 7vw;
      .title {
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 1.1vw;
        margin-bottom: 1vh;
      }
      .content {
        height: 4vh;
        line-height: 4vh;
        color: rgba(255, 255, 255, 0.6);
        font-family: Microsoft YaHei;
        font-size: 0.75vw;
        cursor: pointer;
      }
      .content:hover {
        color: rgba(61, 174, 109, 1);
      }
    }
    .gsjjd {
      margin-top: 10vh;
      margin-left: 3vw;
      width: 7vw;
      .title {
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 1.1vw;
        margin-bottom: 1vh;
      }
      .content {
        height: 4vh;
        line-height: 4vh;
        color: rgba(255, 255, 255, 0.6);
        font-family: Microsoft YaHei;
        font-size: 0.75vw;
        cursor: pointer;
      }
      .content:hover {
        color: rgba(61, 174, 109, 1);
      }
    }
    .gsjje {
      margin-top: 10vh;
      margin-left: 3vw;
      width: 7vw;
      .title {
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 1.1vw;
        margin-bottom: 1vh;
      }
      .content {
        height: 4vh;
        line-height: 4vh;
        color: rgba(255, 255, 255, 0.6);
        font-family: Microsoft YaHei;
        font-size: 0.75vw;
        cursor: pointer;
      }
      .content:hover {
        color: rgba(61, 174, 109, 1);
      }
    }
    .gsjjf {
      margin-top: 10vh;
      margin-left: 3vw;
      width: vw;
      .title {
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 1.1vw;
        margin-bottom: 1vh;
      }
      .content {
        height: 4vh;
        line-height: 4vh;
        color: rgba(255, 255, 255, 0.6);
        font-family: Microsoft YaHei;
        font-size: 0.75vw;
        cursor: pointer;
      }
      .content:hover {
        color: rgba(61, 174, 109, 1);
      }
    }
  }
  .line {
    height: 1px;
    width: 70%;
    margin-left: 15%;
    background: rgb(255, 255, 255, 0.6);
    margin-bottom: 2vh;
  }
  .gsjjab {
    text-align: center;
    padding-bottom: 2vh;
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.75vw;
    cursor: pointer;
  }
  .gsjjabc:hover {
    color: rgba(61, 174, 109, 1);
  }
}
</style>
