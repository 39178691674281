<template>
  <div class="allzyywright">
    <div class="title1">
      <div class="left"></div>
      <div class="title">系统介绍</div>
      <div class="big"></div>
      <div class="middle"></div>
      <div class="small"></div>
    </div>
    <p class="content1">
      ICCEMS低碳智慧能源管理软件平台是以帮助工业生产企业在扩大生产的同时，合理计划和利用能源，降低单位产品能源消耗，提高经济效益，降低CO2排放量为目的信息化管控系统。
    </p>
    <div class="title3">
      <div class="left"></div>
      <div class="title">主要功能</div>
      <div class="big"></div>
      <div class="middle"></div>
      <div class="small"></div>
    </div>
    <div class="underimg">
      <div class="title">
        <div class="yuan"></div>
        <div class="label">能源监控</div>
      </div>
      <p class="content2">
        在线监测、分析能耗相关数据，帮助用能企业全面及时的掌握用能情况、挖掘提升空间，辅助企业完善能源管理体系，优化能管模式和机制。
      </p>
      <div class="title">
        <div class="yuan"></div>
        <div class="label">能耗分析</div>
      </div>
      <p class="content2">
        针对建筑能源数据进行数据分析，包括有趋势分析、偏差分析、计划与实际对比分析、能耗结构分析、同比/环比分析、时间轴分析、设备能耗占比分析。这些数据分析将
        采用折线图、数据图、柱状图等多种图表的形式进行展示。
      </p>
      <div class="title">
        <div class="yuan"></div>
        <div class="label">能耗计划</div>
      </div>
      <p class="content2">
        用户可以在能源管理系统内维护每个成本中心的月度、年度计划，能源计划可以和实际用能数据进行对比，对比采用柱状图的形式展现，展现数据可以看到部门、车间、工厂的不同维度。
      </p>
    </div>
    <div class="flextu">
      <div class="img">
        <img
          src="./../assets/官网切图0430/官网切图0430/能源管理系统_02.png"
          alt=""
        />
      </div>
      <div class="img">
        <img
          src="./../assets/官网切图0430/官网切图0430/能源管理系统_03.png"
          alt=""
        />
      </div>
      <div class="img">
        <img
          src="./../assets/官网切图0430/官网切图0430/能源管理系统_04.png"
          alt=""
        />
      </div>
    </div>
    <div class="title3">
      <div class="left"></div>
      <div class="title">枢源优势</div>
      <div class="big"></div>
      <div class="middle"></div>
      <div class="small"></div>
    </div>
    <p class="content1">
      枢源科技依托物联网技术、云技术、通过大数据机器学习技术，实现能源的智能精细管理、信息综合管控、自动优化决策和智能分析预测，从整体上提升能源管理水平，减少企业能源支出成本，帮助企业管理者通过平台实现能源信息化管理。
    </p>
  </div>
</template>
<script>
export default {
  data: function () {
    return {};
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.allzyywright {
  width: 48vw;
  height: 100%;
  .title1 {
    display: flex;
    height: 5vh;
    .left {
      margin-top: 1.45vh;
      margin-left: 2.5vw;
      width: 0.7vh;
      height: 2.1vh;
      border-radius: 5px;
      background: rgba(61, 174, 109, 1);
    }
    .title {
      margin-left: 1vw;
      line-height: 5vh;
      color: rgba(51, 51, 51, 1);
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 2.5vh;
    }
    .big {
      margin-left: 0.5vw;
      margin-top: 2.05vh;
      width: 0.9vh;
      height: 0.9vh;
      background: rgba(61, 174, 109, 1);
      border-radius: 50%;
    }
    .middle {
      margin-left: 0.5vw;
      margin-top: 2.2vh;
      width: 0.6vh;
      height: 0.6vh;
      background: rgba(61, 174, 109, 1);
      border-radius: 50%;
    }
    .small {
      margin-left: 0.5vw;
      margin-top: 2.35vh;
      width: 0.3vh;
      height: 0.3vh;
      background: rgba(61, 174, 109, 1);
      border-radius: 50%;
    }
  }
  .title3 {
    display: flex;
    height: 5vh;
    margin-top: 2vh;
    margin-bottom: 3vh;
    .left {
      margin-top: 1.45vh;
      margin-left: 2.5vw;
      width: 0.7vh;
      height: 2.1vh;
      border-radius: 5px;
      background: rgba(61, 174, 109, 1);
    }
    .title {
      margin-left: 1vw;
      line-height: 5vh;
      color: rgba(51, 51, 51, 1);
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 2.5vh;
    }
    .big {
      margin-left: 0.5vw;
      margin-top: 2.05vh;
      width: 0.9vh;
      height: 0.9vh;
      background: rgba(61, 174, 109, 1);
      border-radius: 50%;
    }
    .middle {
      margin-left: 0.5vw;
      margin-top: 2.2vh;
      width: 0.6vh;
      height: 0.6vh;
      background: rgba(61, 174, 109, 1);
      border-radius: 50%;
    }
    .small {
      margin-left: 0.5vw;
      margin-top: 2.35vh;
      width: 0.3vh;
      height: 0.3vh;
      background: rgba(61, 174, 109, 1);
      border-radius: 50%;
    }
  }
  .content1 {
    margin-top: 2vh;
    width: 42vw;
    margin-left: 3vw;
    color: rgba(102, 102, 102, 1);
    font-family: Microsoft YaHei;
    font-size: 1.7vh;
    line-height: 3.4vh;
    margin-bottom: 4vh;
  }
  .contentimg {
    width: 42vw;
    margin-top: 2vh;
    margin-left: 3vw;
    margin-bottom: 3vh;
  }
  .underimg {
    width: 45vw;
    margin-left: 1.5vw;

    .title {
      height: 5vh;
      line-height: 5vh;
      display: flex;
      margin-left: 1vw;
      .yuan {
        width: 0.4vh;
        height: 0.4vh;
        border-radius: 50%;
        margin-top: 2.3vh;
        background: rgba(153, 153, 153, 1);
      }
      .label {
        margin-left: 0.5vw;
        color: rgba(51, 51, 51, 1);
        font-family: Microsoft YaHei;
        font-size: 1vw;
        font-weight: bold;
      }
    }
    .content2 {
      margin-left: 1.5vw;
      width: 42vw;
      color: rgba(102, 102, 102, 1);
      font-family: Microsoft YaHei;
      font-size: 0.8vw;
      line-height: 2.8vh;
      margin-top: 1vh;
      margin-bottom: 2vh;
    }
  }
  .flextu {
    width: 45vw;
    margin-left: 1.5vw;
    display: flex;
    .img {
      width: 15vw;
      text-align: center;
      img {
        width: 90%;
      }
      div {
        margin-top: 1vh;
        text-align: center;
        line-height: 3vh;
        color: rgba(51, 51, 51, 1);
        font-family: Microsoft YaHei;
        font-size: 1.2vh;
        margin-bottom: 2vh;
      }
    }
  }
}
</style>
