import Vue from 'vue'
import VueRouter from 'vue-router'
//首页
import index from '../views/index.vue'
//关于枢源
import AboutUs from '../views/AboutUs.vue'
//主营业务
import MainBusiness from '../views/MainBusiness.vue'
//行业解决方案
import InoustaySolutions from '../views/InoustaySolutions.vue'
//服务案例
import ServiceCase from '../views/ServiceCase.vue'
//服务案例软件平台产品
import ServiceSoftware from '../views/ServiceSoftware.vue'
//服务案例系统节能改造
import ServiceSystem from '../views/ServiceSystem.vue'
//服务案例能源系统集成
import ServiceEnergy from '../views/ServiceEnergy.vue'
//新闻资讯
import NewsInformation from '../views/NewsInformation.vue'
//联系我们
import ContactUs from '../views/ContactUs.vue'
//主营业务系统节能改造
import MainSystem from '../views/MainSystem.vue'
//主营业务能源系统集成
import MainEnergy from '../views/MainEnergy.vue'
//主营业务软件平台产品
import MainSoftware from '../views/MainSoftware.vue'
//行业解决方案汽车制造
import AutomobileManufacturing from '../views/AutomobileManufacturing.vue'
//行业解决方案城市排水
import UrbanDrainage from '../views/UrbanDrainage.vue'
//行业解决方案数据中心
import DataCenter from '../views/DataCenter.vue'
//行业解决方案商业建筑
import CommercialBuildings from '../views/CommercialBuildings.vue'
NewsDetail1
import NewsDetail4 from '../views/NewsDetail4.vue'
import NewsDetail3 from '../views/NewsDetail3.vue'
import NewsDetail2 from '../views/NewsDetail2.vue'
import NewsDetail1 from '../views/NewsDetail1.vue'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/MainBusiness',
    name: 'MainBusiness',
    component: MainBusiness
  },
  {
    path: '/InoustaySolutions',
    name: 'InoustaySolutions',
    component: InoustaySolutions
  },
  {
    path: '/ServiceCase',
    name: 'ServiceCase',
    component: ServiceCase
  },
  {
    path: '/NewsInformation',
    name: 'NewsInformation',
    component: NewsInformation
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/MainSystem',
    name: 'MainSystem',
    component: MainSystem
  },
  {
    path: '/NewsDetail4',
    name: 'NewsDetail4',
    component: NewsDetail4
  },
  {
    path: '/MainEnergy',
    name: 'MainEnergy',
    component: MainEnergy
  },
  {
    path: '/MainSoftware',
    name: 'MainSoftware',
    component: MainSoftware
  },
  {
    path: '/AutomobileManufacturing',
    name: 'AutomobileManufacturing',
    component: AutomobileManufacturing
  },
  {
    path: '/UrbanDrainage',
    name: 'UrbanDrainage',
    component: UrbanDrainage
  },
  {
    path: '/DataCenter',
    name: 'DataCenter',
    component: DataCenter
  },
  {
    path: '/CommercialBuildings',
    name: 'CommercialBuildings',
    component: CommercialBuildings
  },
  {
    path: '/ServiceSoftware',
    name: 'ServiceSoftware',
    component: ServiceSoftware
  },
  {
    path: '/ServiceSystem',
    name: 'ServiceSystem',
    component: ServiceSystem
  },
  {
    path: '/ServiceEnergy',
    name: 'ServiceEnergy',
    component: ServiceEnergy
  },
  {
    path: '/NewsDetail3',
    name: 'NewsDetail3',
    component: NewsDetail3
  },
  {
    path: '/NewsDetail2',
    name: 'NewsDetail2',
    component: NewsDetail2
  },
  {
    path: '/NewsDetail1',
    name: 'NewsDetail1',
    component: NewsDetail1
  },
]

const router = new VueRouter({
  // mode:'history',
  routes
})

router.afterEach(()=>{
  window.scrollTo(0,0);
  })
export default router
