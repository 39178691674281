<template>
  <div class="body">
    <jjfatop></jjfatop>
    <div class="hyjifa">
      <div class="allzyyw">
        <div class="allzyywleft">
          <div class="label h f">
            <div class="a"></div>
            <div class="b" @click="InoustaySolutions">生物制药</div>
          </div>
          <div class="label h f">
            <div class="a"></div>
            <div class="b" @click="AutomobileManufacturing">汽车制造</div>
          </div>
          <div class="label h g">
            <div class="c"></div>
            <div class="d">城市排水</div>
          </div>
          <div class="label h f">
            <div class="a"></div>
            <div class="b" @click="DataCenter">数据中心</div>
          </div>
          <div class="label h f">
            <div class="a"></div>
            <div class="b" @click="CommercialBuildings">商业建筑</div>
          </div>
        </div>
        <div class="allzyywright">
          <div class="title1">
            <div class="left"></div>
            <div class="title">核心业务</div>
            <div class="big"></div>
            <div class="middle"></div>
            <div class="small"></div>
          </div>
          <div class="unimg">
            <div class="title">
              <div class="yuan"></div>
              <div class="label">城市排水智慧调度系统</div>
            </div>
            <p class="content2">
              汇聚排水各部门关键数据资源，实现人、设备、环境、排水相关资源的可视化管理，构建集“总体态势、监测预警、事件管理、应急指挥、决策分析”于一体的智慧排水运营管理中心，实现城市排水运行综合状况“一屏”全面感知、全域可览。
            </p>
            <div class="title">
              <div class="yuan"></div>
              <div class="label">智慧污水处理控制系统</div>
            </div>
            <p class="content2">
              助力污水处理运行单位对污水工艺生产流程进行集中监管，通过软件平台的搭设与底层控制系统的设计实施，实现污水工艺的污水处理工艺设备实时监控、污水排放指标监测及报警、区域污水站平台化管理三大功能。
            </p>

            <div class="unimgflex">
              <div class="img">
                <img
                  src="./../assets/官网切图0430/官网切图0430/城市给排水_.png"
                  alt=""
                />
                <div class="label">城市排水智慧调度系统</div>
              </div>
              <div class="img">
                <img
                  src="./../assets/官网切图0430/官网切图0430/城市给排水_-1.png"
                  alt=""
                />
                <div class="label">智慧污水处理控制系统</div>
              </div>
               <div class="img">
                <img
                  src="./../assets/官网切图汇总/行业解决方案_城市排水03.png"
                  alt=""
                />
                <div class="label">智慧污水处理控制系统</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <under></under>
  </div>
</template>

<script>
import under from "./../components/under.vue";
import jjfatop from "../components/jjfatop.vue";
export default {
  data: function () {
    return {
    };
  },
  components: {
    under,jjfatop
  },
  methods: {
   
    InoustaySolutions() {
      this.$router.push("/InoustaySolutions");
    },
    AutomobileManufacturing() {
      this.$router.push("/AutomobileManufacturing");
    },
    DataCenter() {
      this.$router.push("/DataCenter");
    },
    CommercialBuildings() {
      this.$router.push("/CommercialBuildings");
    },
   
  },
};
</script>

<style lang="less" scoped>
.body {
  width: 100%;
  .hyjifa {
    width: 100vw;
    background: rgba(244, 245, 246, 1);
    border: 1px solid rgba(244, 245, 246, 1);
    padding-bottom: 10vh;
    .allzyyw {
      width: 60vw;
      margin-top: 5vh;
      margin-left: 20vw;
      display: flex;
      .allzyywleft {
        width: 12vw;
        height: 100%;
        // border: 1px solid red;
        .label {
          width: 100%;
          height: 6vh;
          cursor: pointer;
          display: flex;
          .c {
            margin-left: 0.5vw;
            width: 4px;
            height: 12px;
            margin-top: calc((6vh - 12px) / 2);
            border-radius: 5px;
            background: rgba(61, 174, 109, 1);
          }
          .d {
            width: 70%;
            // border: 1px solid red;
            line-height: 6vh;
            margin-left: 0.5vw;
            align-items: center;
            color: rgba(61, 174, 109, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 1vw;
          }
          .b {
            width: 70%;
            // border: 1px solid red;
            line-height: 6vh;
            margin-left: 0.5vw;
            align-items: center;
            color: rgba(31, 31, 31, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 1vw;
          }
          .a {
            width: 4px;
            height: 4px;
            margin-top: calc((6vh - 4px) / 2);
            margin-left: 0.5vw;
            border-radius: 50%;
            opacity: 0.5;
            background: rgba(61, 174, 109, 1);
          }
        }
        .label:hover {
          background: rgba(248, 253, 250, 1);
        }
        .g {
          border-right: 2px solid rgba(61, 174, 109, 1);
          background: linear-gradient(
            90deg,
            rgba(244, 255, 249, 0) 0%,
            rgba(248, 253, 250, 1) 100%
          );
        }
        .h {
          border-bottom: 1px solid rgba(61, 174, 109, 0.1);
        }
        .f {
          border-right: 2px solid rgba(61, 174, 109, 0.1);
        }
      }
      .allzyywright {
        width: 48vw;
        .title1 {
          display: flex;
          height: 5vh;
          margin-bottom: 3vh;
          .left {
            margin-top: 1.45vh;
            margin-left: 2.5vw;
            width: 0.7vh;
            height: 2.1vh;
            border-radius: 5px;
            background: rgba(61, 174, 109, 1);
          }
          .title {
            margin-left: 1vw;
            line-height: 5vh;
            color: rgba(51, 51, 51, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 2.5vh;
          }
          .big {
            margin-left: 0.5vw;
            margin-top: 2.05vh;
            width: 0.9vh;
            height: 0.9vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
          .middle {
            margin-left: 0.5vw;
            margin-top: 2.2vh;
            width: 0.6vh;
            height: 0.6vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
          .small {
            margin-left: 0.5vw;
            margin-top: 2.35vh;
            width: 0.3vh;
            height: 0.3vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
        }
        .unimg {
          width: 45vw;
          margin-left: 1.5vw;
          margin-bottom: 3vh;
          .title {
            height: 5vh;
            line-height: 5vh;
            display: flex;
            margin-left: 1vw;
            .yuan {
              width: 0.4vh;
              height: 0.4vh;
              border-radius: 50%;
              margin-top: 2.3vh;
              background: rgba(61, 174, 109, 1);
            }
            .label {
              margin-left: 0.5vw;
              color: rgba(51, 51, 51, 1);
              font-family: Microsoft YaHei;
              font-size: 0.85vw;
              font-weight: bold;
            }
          }
          .content2 {
            margin-left: 1.5vw;
            width: 42vw;
            color: rgba(102, 102, 102, 1);
            font-family: Microsoft YaHei;
            font-size: 0.85vw;
            line-height: 3.4vh;
            margin-top: 1vh;
            margin-bottom: 2vh;
          }
          .unimgflex {
            width: 45vw;
            display: flex;
            .img {
              width: 15vw;
              text-align: center;
              img {
                width: 90%;
              }
              .label {
                margin-top: 1vh;
                margin-bottom: 2vh;
                color: rgba(51, 51, 51, 1);
                font-family: Microsoft YaHei;
                font-size: 0.6vw;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}
</style>
