<template>
  <div class="body">
    <zyywtop></zyywtop>
    <div class="zyyw">
      <div class="allzyyw">
        <div class="allzyywleft">
          <div class="label h f">
            <div class="a"></div>
            <div class="b" @click="MainBusiness">智控系统集成</div>
          </div>
          <div class="label h g">
            <div class="c"></div>
            <div class="d">软件平台产品</div>
          </div>
          <div class="label h f">
            <div class="a"></div>
            <div class="b" @click="MainSystem">系统节能改造</div>
          </div>
          <div class="label h f">
            <div class="a"></div>
            <div class="b" @click="MainEnergy">能源系统集成</div>
          </div>
        </div>
        <div class="allzyywright">
          <div class="bread">
            <div :class="bread1 == true ? 'b' : 'a'" @click="change1">
              <div class="aaa">
                <div>ICCEMS低碳智慧能源</div>
                <div>管理软件平台</div>
              </div>
            </div>
            <div :class="bread2 == true ? 'b' : 'a'" @click="change2">
              <div class="aaa">
                <div>IWecosys“水生态”</div>
                <div>数字化管控平台</div>
              </div>
            </div>
            <div :class="bread3 == true ? 'b' : 'a'" @click="change3">
              <div class="aaa">
                <div>IMES工业制造智慧工厂</div>
                <div>管理云平台</div>
              </div>
            </div>
          </div>
          <rjcpzx1 v-if="bread1 == true"></rjcpzx1>
          <rjcpzx2 v-if="bread2 == true"></rjcpzx2>
          <rjcpzx3 v-if="bread3 == true"></rjcpzx3>
        </div>
      </div>
    </div>
    <under></under>
  </div>
</template>
<script>
import under from "./../components/under.vue";
import zyywtop from "./../components/zyywtop.vue";
import rjcpzx1 from "./../components/rjcpzx1.vue";
import rjcpzx2 from "./../components/rjcpzx2.vue";
import rjcpzx3 from "./../components/rjcpzx3.vue";

export default {
  data: function () {
    return {
      bread1: true,
      bread2: false,
      bread3: false,
    };
  },
  components: {
    under,
    zyywtop,
    rjcpzx1,
    rjcpzx2,
    rjcpzx3,
  },
  created() {},
  methods: {
    MainSystem() {
      this.$router.push("/MainSystem");
    },
    MainEnergy() {
      this.$router.push("/MainEnergy");
    },
    MainBusiness() {
      this.$router.push("/MainBusiness");
    },
    change1() {
      this.bread1 = true;
      this.bread2 = false;
      this.bread3 = false;
    },
    change2() {
      this.bread1 = false;
      this.bread2 = true;
      this.bread3 = false;
    },
    change3() {
      this.bread1 = false;
      this.bread2 = false;
      this.bread3 = true;
    },
  },
};
</script>
<style lang="less" scoped>
.body {
  width: 100%;
  .zyyw {
    width: 100vw;
    margin-bottom: 10vh;
    .allzyyw {
      width: 60vw;
      margin-top: 5vh;
      margin-left: 20vw;
      display: flex;
      .allzyywleft {
        width: 12vw;
        height: 100%;
        .label {
          width: 100%;
          height: 6vh;
          display: flex;
          cursor: pointer;
          .c {
            margin-left: 0.5vw;
            width: 4px;
            height: 12px;
            margin-top: calc((6vh - 12px) / 2);
            border-radius: 5px;
            background: rgba(61, 174, 109, 1);
          }
          .d {
            width: 90%;
            // border: 1px solid red;
            line-height: 6vh;
            margin-left: 0.5vw;
            align-items: center;
            color: rgba(61, 174, 109, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 1vw;
          }
          .b {
            width: 90%;
            // border: 1px solid red;
            line-height: 6vh;
            margin-left: 0.5vw;
            align-items: center;
            color: rgba(31, 31, 31, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 1vw;
          }
          .a {
            width: 4px;
            height: 4px;
            margin-top: calc((6vh - 4px) / 2);
            margin-left: 0.5vw;
            border-radius: 50%;
            opacity: 0.5;
            background: rgba(61, 174, 109, 1);
          }
        }
        .label:hover {
          background: rgba(248, 253, 250, 1);
        }
        .g {
          border-right: 2px solid rgba(61, 174, 109, 1);
          background: linear-gradient(
            90deg,
            rgba(244, 255, 249, 0) 0%,
            rgba(248, 253, 250, 1) 100%
          );
        }
        .h {
          border-bottom: 1px solid rgba(61, 174, 109, 0.1);
        }
        .f {
          border-right: 2px solid rgba(61, 174, 109, 0.1);
        }
      }
      .allzyywright {
        width: 48vw;
        height: 100%;
        .bread {
          width: 43.5vw;
          margin-left: 2.5vw;
          height: 10vh;
          display: flex;
          text-align: center;
          border: 1px solid rgb(61, 174, 109);
          border-radius: 8px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          font-size: 1vw;
          line-height: 200%;
          letter-spacing: 12%;
          margin-bottom: 5vh;
          .a {
            width: 14.5vw;
            height: 10vh;
            border-radius: 8px;
            cursor: pointer;
            .aaa {
              width: 14.5vw;
              height: 8vh;
              margin-top: 1vh;
             
            }
          }
          .a:hover {
            background: rgba(61, 174, 109, 0.1);
          }
          .b {
            width: 14.5vw;
            height: 10vh;
            border-radius: 8px;
            background: rgba(61, 174, 109, 0.1);
            color: rgba(61, 174, 109, 1);
            border-bottom: 2px solid rgb(61, 174, 109);
             .aaa {
              width: 14.5vw;
              height: 8vh;
              margin-top: 1vh;
             
            }
          }
        }
      }
    }
  }
}
</style>
