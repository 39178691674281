<template>
  <div class="body">
    <fwaltop></fwaltop>
    <div class="fwal">
      <div class="fwalicons">
        <div class="icon changecolor">
          <div class="flex">
            <img
              class="fleximg"
              src="./../assets/官网补充切图2/官网补充切图/服务案例_BMS_白2.svg"
              alt=""
            />
            <div class="xiticolor">智控系统集成</div>
          </div>
          <img
            class="jt"
            src="./../assets/官网补充切图2/官网补充切图/箭头.svg"
            alt=""
          />
        </div>
        <div class="icon icons" @mouseenter="x1" @mouseleave="y" @click="ServiceSoftware">
          <div class="flex">
            <img
              v-if="l1 == true"
              class="fleximg"
              src="./../assets/官网切图0430/官网切图0430/解决方案_定制_绿.svg"
              alt=""
            />
            <img
              v-else
              class="fleximg"
              src="./../assets/官网切图0430/官网切图0430/解决方案_定制_灰.svg"
              alt=""
            />
            <div>软件平台产品</div>
          </div>
          <img
            v-if="l1 == true"
            class="jt"
            src="./../assets/520/方向.svg"
            alt=""
          />
        </div>
        <div class="icon icons" @mouseenter="x2" @mouseleave="y" @click="ServiceSystem">
          <div class="flex">
            <img
              v-if="l2 == true"
              class="fleximg"
              src="./../assets/官网切图2.0/官网切图2.0/解决方案_节能_绿.svg"
              alt=""
            />
            <img
              v-else
              class="fleximg"
              src="./../assets/官网切图2.0/官网切图2.0/解决方案_节能_灰.svg"
              alt=""
            />
            <div>系统节能改造</div>
          </div>
          <img
            v-if="l2 == true"
            class="jt"
            src="./../assets/520/方向.svg"
            alt=""
          />
        </div>
        <div class="icon icons" @mouseenter="x3" @mouseleave="y" @click="ServiceEnergy">
          <div class="flex">
            <img
              v-if="l3 == true"
              class="fleximg"
              src="./../assets/官网切图2.0/官网切图2.0/解决方案_动力_绿.svg"
              alt=""
            />
            <img
              v-else
              class="fleximg"
              src="./../assets/官网切图2.0/官网切图2.0/解决方案_动力_灰.svg"
              alt=""
            />
            <div>能源系统集成</div>
          </div>
          <img
            v-if="l3 == true"
            class="jt"
            src="./../assets/520/方向.svg"
            alt=""
          />
        </div>
      </div>
      <div class="three">
        <img
          src="./../assets/官网补充/官网补充/img_案例01.png"
          alt=""
          class="tworight"
        />
        <div class="twoleft">
          <div class="twoleftfirstdiv">
            <div class="divfirst"></div>
            <div class="title">杭州养生堂</div>
            <!-- <div class="time">2021年12月12日</div> -->
          </div>
          <div class="twoleftseconddiv">
            杭州养生堂生物医药有限公司病毒制剂生产厂房能源控制精度高要求的特点，针对冷热源、空调箱、末端变风量风阀等设备进行了精准控制，我司承建的BMS/EMS系统已顺利通过GMP认证验收。
          </div>
        </div>
      </div>
      <div class="two">
        <div class="twoleft">
          <div class="twoleftfirstdiv">
            <div class="divfirst"></div>
            <div class="title">杭州移动研发基地</div>
          </div>
          <div class="twoleftseconddiv">
            本项目为中移（杭州）信息技术有限公司生产基地一期二阶段工程数据机房IDC运营监控中心及技术提升建设项目，机房主体地上4层，油泵房地下1层。本项目主要通过西门子PLC控制器与WINCC组态软件相结合，实现对数据中心冷源系统的制冷主机、水泵、冷却塔、板换、蓄冷罐等实现自动控制。根据预设的控制逻辑，实现冷源系统夏季、过渡季等不同季节模式的自动切换。
          </div>
        </div>
        <img
          src="./../assets/官网补充/官网补充/img_案例03.png"
          alt=""
          class="tworight"
        />
      </div>
      <div class="three">
        <img
          src="./../assets/服务案例01/服务案例01.png"
          alt=""
          class="tworight"
        />
        <div class="twoleft">
          <div class="twoleftfirstdiv">
            <div class="divfirst"></div>
            <div class="title">宁波鲲鹏生物</div>
            <!-- <div class="time">2021年12月12日</div> -->
          </div>
          <div class="twoleftseconddiv">
            本项目为宁波鲲鹏生物慈溪基地胰岛素生物制药基地工厂自控系统，我司承建了工厂厂房洁净车间及能源站房的环境控制系统（温湿度、压差控制）、能源中心冷源群控系统，同时我司实施了BMS系统、EMS系统的验证工作，达到了设计目的。
          </div>
        </div>
      </div>
      <div class="more">- 更多案例请咨询 -</div>
    </div>
    <under></under>
  </div>
</template>

<script>
import under from "../components/under.vue";
import fwaltop from "../components/fwaltop.vue";
export default {
  components: { under, fwaltop },
  data: function () {
    return {
      l1: false,
      l2: false,
      l3: false,
    };
  },
  created() {},
  methods: {
    xq1() {
      this.$router.push("/fwalxq");
    },
    ServiceSoftware() {
      this.$router.push("/ServiceSoftware");
    },
    ServiceSystem() {
      this.$router.push("/ServiceSystem");
    },
    ServiceEnergy() {
      this.$router.push("/ServiceEnergy");
    },
    x1() {
      this.l1 = true;
      this.l2 = false;
      this.l3 = false;
    },
    x2() {
      this.l1 = false;
      this.l2 = true;
      this.l3 = false;
    },
    x3() {
      this.l1 = false;
      this.l2 = false;
      this.l3 = true;
    },
    y() {
      this.l1 = false;
      this.l2 = false;
      this.l3 = false;
    },
  },
};
</script>

<style lang="less" scoped>
.body {
  width: 100%;
  .fwal {
    width: 100vw;
    .fwalicons {
      width: 66vw;
      margin-left: 17vw;
      display: flex;
      justify-content: space-between;
      text-align: center;
      margin-top: 5vh;
      margin-bottom: 5vh;
      .icon {
        width: 14.5vw;
        cursor: pointer;
        .flex {
          width: 14.5vw;
          display: flex;
          .fleximg {
            margin-top: 3vh;
            margin-bottom: 3vh;
            width: 2vw;
            margin-left: 2vw;
          }
          div {
            margin-top: 3vh;
            margin-left: 1vw;
            color: rgba(51, 51, 51, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 1vw;
            line-height: 2vw;
          }
          .xiticolor {
            color: rgba(255, 255, 255, 1);
          }
        }
        .jt {
          margin-bottom: 3vh;
          width: 2vw;
        }
      }
      .icons:hover {
        border-radius: 6px;
        .flex {
          div {
            color: #3dae6d;
          }
        }
      }
      .changecolor {
        border-radius: 6px;
        background: rgba(61, 174, 109, 1);
      }
    }
    .two {
      width: 66.7vw;
      margin-left: 16.65vw;
      display: flex;
      justify-content: space-around;
      margin-top: 12vh;
      margin-bottom: 5vh;
      text-align: justify;
      .twoleft {
        width: 27vw;

        .twoleftfirstdiv {
          display: flex;
          height: 5vh;
          margin-top: 3vh;
          .divfirst {
            margin-top: 1.45vh;
            margin-left: 2.5vw;
            width: 0.7vh;
            height: 2.1vh;
            border-radius: 6px;
            background: rgba(61, 174, 109, 1);
          }
          .title {
            margin-left: 0.5vw;
            line-height: 5vh;
            margin-bottom: 2vh;
            color: rgba(51, 51, 51, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 2.5vh;
          }
        }
        .twoleftseconddiv {
          width: 25vw;
          margin-left: 1vw;
          margin-top: 5vh;
          color: rgba(102, 102, 102, 1);
          font-family: Microsoft YaHei;
          font-size: 1.6vh;
          line-height: 200%;
        }
      }
      .tworight {
        width: 37.7vw;
      }
    }
    .three {
      width: 66.7vw;
      margin-left: 16.65vw;
      display: flex;
      justify-content: space-around;
      margin-top: 12vh;
      margin-bottom: 5vh;
      text-align: justify;
      .twoleft {
        width: 27vw;
        .twoleftfirstdiv {
          display: flex;
          height: 5vh;
          margin-top: 3vh;
          .divfirst {
            margin-top: 1.45vh;
            margin-left: 2.5vw;
            width: 0.7vh;
            height: 2.1vh;
            border-radius: 6px;
            background: rgba(61, 174, 109, 1);
          }
          .title {
            margin-left: 0.5vw;
            line-height: 5vh;
            margin-bottom: 2vh;
            color: rgba(51, 51, 51, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 2.5vh;
          }
        }
        .twoleftseconddiv {
          width: 25vw;
          margin-left: 1vw;
          margin-top: 5vh;
          color: rgba(102, 102, 102, 1);
          font-family: Microsoft YaHei;
          font-size: 1.6vh;
          line-height: 200%;
        }
      }
      .tworight {
        width: 37.7vw;
      }
    }
    .more {
      margin-left: 44vw;
      width: 12vw;
      height: 5vh;
      line-height: 5vh;
      border-radius: 6px;
      font-size: 1vw;
      text-align: center;
      border: 1px solid rgba(61, 174, 109, 1);
      color: rgb(61, 174, 109);
      margin-top: 10vh;
      margin-bottom: 10vh;
    }
  }
}
</style>
