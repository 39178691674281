<template>
  <div class="body">
    <xwzxtop></xwzxtop>
    <div class="xwzx">
      <div class="allzyyw">
        <div class="allzyywright">
          <div class="third" @click="xq4">
            <div class="left">
              <div class="title">赋能建筑业实现低碳转型</div>
              <div class="content">
                零碳建筑是以人、建筑和自然环境的协调发展为目标，在利用天然条件和人工手段创造良好、健康的居住环境的同时，尽可能地控制和减少对自然环境的使用和破坏，充分体现向大自然索取和回报之间的平衡。置身于零碳建筑内部，能够充分体会到生态环境与人的有机融合，一方面给人们创造了一个健康、高效和适用的空间，另一方面人们通过低碳生活方式的转变提升了内心的满足感。
              </div>
            </div>
            <div class="right">
              <div class="img1">
                <img
                  src="./../assets/官网切图汇总/官网切图补充/新闻资讯_04.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="third " @click="xq3">
            <div class="left">
              <div class="title">合同能源管理模式详解</div>
              <div class="content1">
                合同能源管理是20世纪70年代中期以来，在美国、加拿大等市场经济国家中逐步发展起来的一种基于市场的、全新的节能新机制。合同能源管理机制的实质是一种以减少的能源费用来支付节能项目全部成本的节能投资方式，这样的节能投资方式使得客户可以用未来的节能收益为工厂和设备升级，同时降低当前的运行成本。
              </div>
            </div>
            <div class="right">
              <div class="img1">
                <img
                  src="./../assets/官网切图汇总/官网切图补充/新闻资讯_03.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="third" @click="xq2">
            <div class="left">
              <div class="title">
                为节能减排做出贡献，楼宇自控系统比例巨大！
              </div>
              <div class="content">
                楼宇自控系统的控制和管理内容包括：冷源系统的监测、空调通风系统的监控、给排水系统的监控、照明系统和电梯系统。为保障楼宇自控系统安全运行的可靠性，建筑设备监控系统所用的电源，必须全部取...
              </div>
            </div>
            <div class="right">
              <div class="img1">
                <img
                  src="./../assets/官网切图汇总/官网切图补充/新闻资讯_02.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="third " @click="xq">
            <div class="left">
              <div class="title">什么是楼宇自控系统？</div>
              <div class="content1">
                大家对楼宇自控系统了解吗，楼宇自控系统是什么呢，对于做工程的人来说，楼宇自控肯定非常的熟悉。其实楼宇自控系统是楼宇自动化系统的简称，是智能建筑中不可缺少的重要组成部分之一，它的特征是“集中管理分散控制”，主要是对整个楼宇建筑的所有公用机电设备，楼宇自控系统包括中央空...
              </div>
            </div>
            <div class="right">
              <div class="img1">
                <img
                  src="./../assets/官网切图汇总/官网切图补充/新闻资讯_01.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <under></under>
  </div>
</template>

<script>
import under from "../components/under.vue";
import xwzxtop from "../components/xwzxtop.vue";
export default {
  components: { under, xwzxtop },
  data: function () {
    return {};
  },
  methods: {
    xq() {
      this.$router.push("/NewsDetail4");
    },
    xq2() {
      this.$router.push("/NewsDetail3");
    },
    xq3() {
      this.$router.push("/NewsDetail2");
    },
    xq4() {
      this.$router.push("/NewsDetail1");
    },
  },
};
</script>

<style lang="less" scoped>
.body {
  width: 100%;
  .xwzx {
    width: 100vw;
    padding-bottom: 10vh;
    background: rgba(244, 245, 246, 1);
    border: 1px solid rgba(244, 245, 246, 1);
    .allzyyw {
      width: 60vw;
      margin-top: 10vh;
      margin-left: 20vw;
      .allzyywright {
        width: 48vw;
        .third {
          margin-top: 3vh;
          width: 60vw;
          padding-bottom: 2vh;
          display: flex;
          background: rgba(255, 255, 255, 1);
          border-radius: 10px;
          .left {
            margin-left: 0.5vw;
            width: 40vw;
            cursor: pointer;
            .title {
              margin-top: 1vh;
              margin-left: 1vw;
              color: rgba(51, 51, 51, 1);
              font-family: Microsoft YaHei;
              font-weight: bold;
              font-size: 1.1vw;
              line-height: 4vh;
            }
            .content {
              margin-left: 1vw;
              width: 38vw;
              margin-top: 1vh;
              color: rgba(102, 102, 102, 1);
              font-family: Microsoft YaHei;
              font-size: 0.9vw;
              line-height: 3.6vh;
              word-break: break-all;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2; /* 这里是超出几行省略 */
            }
            .content1 {
              margin-left: 1vw;
              width: 38vw;
              margin-top: 1vh;
              color: rgba(102, 102, 102, 1);
              font-family: Microsoft YaHei;
              font-size: 0.9vw;
              line-height: 3.6vh;
              word-break: break-all;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3; /* 这里是超出几行省略 */
            }
          }
          .right {
            margin-left: 1.5vw;
            width: 18vw;
            .img1 {
              margin-top: 2vh;
              width: 17vw;
              img {
                width: 100%;
                // height: 100%;
                border-radius: 8px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
