<template>
  <div class="body">
    <div class="head">
      <div class="top">
          <img src="./../assets/枢源LOGO02.svg" alt="" class="gslogo" />
        <div class="type_item type_item1" @click="index">首页</div>

        <div class="type_item typeActive">
          关于枢源
          <div class="typeActive1"></div>
        </div>

        <div class="type_item type_item1" @click="MainBusiness">主营业务</div>

        <div class="type_item type_item1" @click="InoustaySolutions">行业解决方案</div>

        <div class="type_item type_item1" @click="ServiceCase">服务案例</div>

        <div class="type_item type_item1" @click="NewsInformation">新闻资讯</div>

        <div class="type_item type_item1" @click="ContactUs">联系我们</div>
        <div class="a">
          <div class="ab">服务热线：021-64026001</div>
        </div>
      </div>
      <img
        src="./../assets/官网子页面切图/官网子页面切图/标题文字_关于枢源.svg"
        alt=""
        class="al"
      />
    </div>
    <div class="gysy">
      <div class="img"></div>
      <div class="background"></div>
      <div class="content">
        <div class="title">
          公司介绍
          <div class="bg"></div>
        </div>
        <div class="label">
          <p>
            上海枢源节能科技有限公司是一家成立于国家级孵化器同济大学科技园的高新技术企业。公司整合同济大学软件信息、人工智能及建筑规划领域优势学科资源，自主研发多项智能管控云平台，致力于成为制造和环保等领域内智能控制、智能管理及智慧运维的专业服务商，提升客户运营效率、降低能源消耗、提升环境品质，为客户创造更好的经济效益。
          </p>
          <div></div>
          <p>
            公司成立以来，完成百余项系统智能管控及信息化升级项目，公司已成长为具备提供智能化管控系统整体解决方案的高新技术企业。近年，荣获了全球创业周雏鹰奖、杨浦区“专精特新”企业，杨浦区科技小巨人科技企业及双软企业等称号。公司注重科技创新发展，授权软件著作权30余项、多项软件产品认定及高新技术成果转化。公司完成核心平台产品3项：“iCCEMS低碳智慧能源管理软件平
            台”、“iWEcosys“水生态”数字化管控平台”、“iMES工业制造智慧工厂管理云平台”。智能分析物联管控云平台融合了物联网技术与大数据分析技术，为客户提供设备运行管理、运行分析诊断、大数据分析、AI智能优化及智能决策等功能。
          </p>
          <div></div>
          <p>
            公司建立了完善的业务服务网络，产品及解决方案广泛应用于生物制药、汽车制造、烟草生产、数据中心、城市水环境、科研实验等领域，以“数据驱动，智享未来”的核心技术服务理念，力争成为一家能够为各行业提供全球先进的智能化管控系统解决方案的行业引领者。
          </p>
        </div>
      </div>
    </div>
    <div class="hyyy">
      <div class="title">
        <img
          src="./../assets/官网切图2.0/官网切图2.0/标题_左.svg"
          alt=""
          class="titleleft"
        />
        <span>成 长 历 程</span
        ><img
          src="./../assets/官网切图2.0/官网切图2.0/标题_右.svg"
          alt=""
          class="titleright"
        />
      </div>
      <div class="titlea">
        <img src="./../assets/官网英文/Growth.svg" alt="" />
      </div>
      <div class="allhyyy">
        <div class="allhyyy_top_label">
          <div class="allhyyy_top_div">
            <div class="title">2016年</div>
            <div class="content">核心团队组建公司成立</div>
          </div>
          <div class="allhyyy_top_sj"></div>
        </div>
        <div class="allhyyy_top_label">
          <div class="allhyyy_top_div">
            <div class="title">2018年</div>
            <div class="content">荣获上海市科创基金会全球创业周雏鹰奖</div>
          </div>
          <div class="allhyyy_top_sj"></div>
        </div>
        <div class="allhyyy_top_label">
          <div class="allhyyy_top_div">
            <div class="title">2020年</div>
            <div class="content">公司营业额突破2000W+</div>
          </div>
          <div class="allhyyy_top_sj"></div>
        </div>
      </div>
      <div class="zj">
        <div class="a"></div>
        <div class="b">
          <div class="c"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="c"></div>
        </div>
        <div class="a"><div></div></div>
        <div class="b">
          <div class="c"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="c"></div>
        </div>
        <div class="a"><div></div></div>
        <div class="b">
          <div class="c"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="d"></div>
          <div class="c"></div>
        </div>
      </div>
      <div class="allhyyy2">
        <div class="allhyyy_under_label">
          <div class="allhyyy_under_sj"></div>
          <div class="allhyyy_under_div">
            <div class="title">2017年</div>
            <div class="content">公司研发能源系统节能算法软件初步上线</div>
          </div>
        </div>
        <div class="allhyyy_under_label">
          <div class="allhyyy_under_sj"></div>
          <div class="allhyyy_under_div">
            <div class="title">2019年</div>
            <div class="content">荣获高效技术企业，杨浦区“专精特新”企业</div>
          </div>
        </div>
        <div class="allhyyy_under_label">
          <div class="allhyyy_under_sj"></div>
          <div class="allhyyy_under_div">
            <div class="title">2021年</div>
            <div class="content">荣获杨浦区科技小巨人企业</div>
          </div>
        </div>
      </div>
    </div>
    <div class="ryzz">
      <div class="title">
        <img
          src="./../assets/官网切图2.0/官网切图2.0/标题_左.svg"
          alt=""
          class="titleleft"
        />
        <span>荣 誉 资 质</span
        ><img
          src="./../assets/官网切图2.0/官网切图2.0/标题_右.svg"
          alt=""
          class="titleright"
        />
      </div>
      <div class="titlea">
        <img
          src="./../assets/官网切图2.0/官网切图2.0/标题英文_荣誉资质.svg"
          alt=""
        />
      </div>
      <div class="allryzz">
        <div class="a" v-for="item of recommendList" :key="item.id">
          <div class="b">
            <img :src="item.url" :alt="item.text" />
            <div class="label">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="ryzz">
      <div class="title">
        <img
          src="./../assets/官网切图2.0/官网切图2.0/标题_左.svg"
          alt=""
          class="titleleft"
        />
        <span>专 利 证 书</span
        ><img
          src="./../assets/官网切图2.0/官网切图2.0/标题_右.svg"
          alt=""
          class="titleright"
        />
      </div>
      <div class="titlea">
        <img src="./../assets/官网切图0430/官网切图0430/patent.svg" alt="" />
      </div>
      <div class="allryzz">
        <div class="a" v-for="item of List" :key="item.id">
          <div class="b">
            <img :src="item.url" :alt="item.text" />
            <div class="label">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <under></under>
  </div>
</template>

<script>
import under from "../components/under.vue";
export default {
  components: { under },
  data: function () {
    return {
      recommendList: [
        {
          url: require("./../assets/官网切图0430/官网切图0430/荣誉证书/荣誉证书06_杨浦区科技小巨人.png"),
          text: "杨浦区科技小巨人",
        },
        {
          url: require("./../assets/官网切图0430/官网切图0430/荣誉证书/荣誉证书07_高新技术企业证书.png"),
          text: "高新技术企业证书",
        },
        {
          url: require("./../assets/官网切图0430/官网切图0430/荣誉证书/荣誉证书04_杨浦区专精特新.png"),
          text: "杨浦区专精特新",
        },
        {
          url: require("./../assets/官网切图0430/官网切图0430/荣誉证书/荣誉证书08_软件产品证书.png"),
          text: "软件产品证书",
        },
        {
          url: require("./../assets/官网切图0430/官网切图0430/荣誉证书/荣誉证书05_杨浦区博士后创新实践基地.png"),
          text: "杨浦区博士后创新实践基地",
        },
        {
          url: require("./../assets/官网切图0430/官网切图0430/荣誉证书/荣誉证书09_科创基金会2019全球创业周雏鹰奖.png"),
          text: "科创基金会2019全球创业周雏鹰奖",
        },
        {
          url: require("./../assets/官网切图0430/官网切图0430/荣誉证书/荣誉证书01_企业信用等级证书.png"),
          text: "企业信用等级证书",
        },
        {
          url: require("./../assets/官网切图0430/官网切图0430/荣誉证书/荣誉证书02_企业资信等级证书.png"),
          text: "企业资信等级证书",
        },
        {
          url: require("./../assets/官网切图0430/官网切图0430/荣誉证书/荣誉证书03_第六届互联网大赛全国铜奖.png"),
          text: "第六届互联网+大赛全国铜奖",
        },
        {
          url: require("./../assets/官网切图0430/官网切图0430/荣誉证书/荣誉证书10_质量管理体系认证证书.png"),
          text: "质量管理体系认证证书",
        },
        {
          url: require("./../assets/官网切图0430/官网切图0430/荣誉证书/荣誉证书11_职业健康安全管理体系认证证书.png"),
          text: "职业健康安全管理体系认证证书",
        },
        {
          url: require("./../assets/官网切图0430/官网切图0430/荣誉证书/荣誉证书12_环境管理体系认证证书.png"),
          text: "环境管理体系认证证书",
        },
      ],
      List: [
        {
          url: require("./../assets/官网切图0430/官网切图0430/专利证书/专利证书01_IDC数据机房空调系统能源管理平台v1.0.png"),
          text: "  IDC数据机房空调系统能源管理平台V1.0",
        },
        {
          url: require("./../assets/官网切图0430/官网切图0430/专利证书/专利证书02_Smart-CMS数字城市智慧决策平台v1.0.png"),
          text: "Smart-CMS数字城市智慧决策平台V1.0",
        },
        {
          url: require("./../assets/官网切图0430/官网切图0430/专利证书/专利证书03_Smart-EMS高效能源智能决策管控平台v1.0.png"),
          text: "Smart-EMS高效能源智能决策管控平台V1.0",
        },
        {
          url: require("./../assets/官网切图0430/官网切图0430/专利证书/专利证书04_厂房分项能耗数据采集与传输软件v1.0.png"),
          text: " 厂房分项能耗数据采集与传输软件V1.0",
        },
        {
          url: require("./../assets/官网切图0430/官网切图0430/专利证书/专利证书05_分布式能源系统智能优化设计软件v1.0.png"),
          text: " 分布式能源系统智能优化设计软件V1.0",
        },
        {
          url: require("./../assets/官网切图0430/官网切图0430/专利证书/专利证书06_环境监测商业智能算法软件v1.0.png"),
          text: "  环境监测商业智能算法软件V1.0",
        },
        {
          url: require("./../assets/官网切图0430/官网切图0430/专利证书/专利证书07_基于负荷预测的能源系统优化运营平台v1.0.png"),
          text: "基于负荷预测的能源系统优化运营平台V1.0",
        },
        {
          url: require("./../assets/官网切图0430/官网切图0430/专利证书/专利证书08_基于能源系统能效评价的智慧管理平台v1.0.png"),
          text: "基于能源系统能效评价的智慧管理平台V1.0",
        },
        {
          url: require("./../assets/官网切图0430/官网切图0430/专利证书/专利证书09_跨区域集团级建筑能源管理平台v1.0.png"),
          text: "跨区域集团级建筑能源管理平台V1.0",
        },
        {
          url: require("./../assets/官网切图0430/官网切图0430/专利证书/专利证书10_绿色建筑在线智能设计及评估软件v1.0.png"),
          text: "绿色建筑在线智能设计及评估软件V1.0",
        },
        {
          url: require("./../assets/官网切图0430/官网切图0430/专利证书/专利证书11_能效在线评估系统后台配置软件v1.0.png"),
          text: "能效在线评估系统后台配置软件V1.0",
        },
        {
          url: require("./../assets/官网切图0430/官网切图0430/专利证书/专利证书12_能效在线评估云平台v1.0.png"),
          text: "能效在线评估云平台V1.0",
        },
      ],
    };
  },
  methods: {
    index() {
      this.$router.push("/");
    },
    MainBusiness() {
      this.$router.push("/MainBusiness");
    },
    InoustaySolutions() {
      this.$router.push("/InoustaySolutions");
    },
    ServiceCase() {
      this.$router.push("/ServiceCase");
    },
    NewsInformation() {
      this.$router.push("/NewsInformation");
    },
    ContactUs() {
      this.$router.push("/ContactUs");
    },
  },
};
</script>

<style lang="less" scoped>
.body {
  width: 100%;
  .head {
    height: 17.1875vw;
    width: 100vw;
    background-image: url("./../assets/官网压缩图片/top_关于枢源.png");
    background-size: cover;
    background-position: center center;
    .top {
      height: 9vh;
      background: rgba(31, 31, 31, 0.2);
      display: flex;
    .gslogo {
      margin-top: 1.5vh;
      margin-left: 15vw;
      height: 6vh;
      margin-right: 2vw;
    }
      .type_item {
        position: relative;
        margin-left: 2.2vw;
        color: rgb(255, 255, 255);
        font-size: 1vw;
        margin-top: 2.9vh;
        height: 3.2vh;
        cursor: pointer;
        line-height: 3.2vh;
      }
      .type_item1:hover {
        color: rgb(61, 174, 109);
      }
      .typeActive {
      
        color: #87ffba;
        font-weight: bold;
      }
      .typeActive1 {
        width: 50%;
        margin-left: 25%;
        margin-top: 0.5vh;
        height: 2px;
        background: rgb(61, 174, 109);
      }
      .a {
        position: relative;
        margin-left: 3vw;
        color: rgb(255, 255, 255);
        .ab {
          background: rgb(61, 174, 109);
          width: 12vw;
          border-radius: 50px;
          text-align: center;
          font-size: 0.9vw;
          color: rgb(255, 255, 255);
          font-family: Microsoft YaHei;
          letter-spacing: 12%;
          margin-top: 2.5vh;
          height: 4vh;
          line-height: 4vh;
        }
      }
    }
    .al {
      height: 6vh;
      margin-top: 12vh;
      margin-left: 23vw;
    }
  }
  .gysy {
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 1);
    position: relative;

    .img {
      position: absolute;
      top: 9.5vh;
      left: 19vw;
      width: 23vw;
      height: 70vh;
      z-index: 2;
      background-image: url("./../assets/官网子页面切图/官网子页面切图/IMG_关于枢源05.png");
      background-size: cover;
      background-position: center center;
    }
    .background {
      top: 10.5vh;
      left: 19.5vw;
      width: 23vw;
      height: 70vh;
      background: rgba(61, 174, 109, 1);
      position: absolute;
      z-index: 1;
    }
    .content {
      top: 12vh;
      left: 20vw;
      width: 60vw;
      height: 75vh;
      background: rgba(246, 246, 246, 1);
      position: absolute;
      .title {
        margin-left: 25vw;
        margin-top: 2.5vh;
        width: 7vw;
        color: rgba(51, 51, 51, 1);
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 2vh;
        position: relative;
        .bg {
          position: absolute;
          width: 8vh;
          height: 50%;
          opacity: 0.6;
          background: rgba(61, 174, 109, 1);
          opacity: 0.6;
          left: 0vw;
          top: 50%;
        }
      }
      .label {
        margin-left: 25vw;
        margin-top: 2.5vh;
        width: 30vw;
        color: rgba(102, 102, 102, 1);
        font-family: Microsoft YaHei;
        font-size: 0.82vw;
        line-height: 3vh;
        text-align: justify;
        div {
          margin-top: 1vh;
        }
      }
    }
  }
  .hyyy {
    width: 100vw;
    padding-bottom: 10vh;
    background-image: url("./../assets/官网背景/bg_成长历程.png");
    background-size: cover;
    background-position: center center;
    .title {
      text-align: center;

      padding-top: 2vh;
      color: rgba(51, 51, 51, 1);
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 28px;
      letter-spacing: 30%;
      .titleleft {
        margin-right: 1vw;
        margin-bottom: 1vh;
      }
      .titleright {
        margin-left: 1vw;
        margin-bottom: 1vh;
      }
    }
    .titlea {
      text-align: center;
    }
    .allhyyy {
      margin-left: 15vw;
      margin-top: 4vh;
      width: 70vw;
      height: 20vh;
      // border: 1px solid red;
      display: flex;
      .allhyyy_top_label {
        margin-left: 1.4vw;
        width: 20vw;
        height: 20vh;
        border-radius: 8px;
        .allhyyy_top_div {
          width: 100%;
          height: 90%;
          border-radius: 8px 8px 8px 0px;
          background: rgba(255, 255, 255, 1);
          .title {
            // margin-left: 0.5vw;
            width: 5vw;
            height: 2vh;
            color: rgba(61, 174, 109, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 1.1vw;
            // border: 1px solid red;
          }
          .content {
            margin-left: 4.5%;
            margin-top: 2.2vh;
            width: 91%;
            height: 10vh;
            color: rgba(102, 102, 102, 1);
            font-family: Microsoft YaHei;
            font-size: 0.9vw;
          }
        }
        .allhyyy_top_sj {
          width: 0; /*1.设置宽高为0*/
          height: 0;
          /*2.除右边都变透明色，实边*/
          border-color: transparent transparent transparent
            rgba(255, 255, 255, 1);
          border-style: solid;
          //  border-width: 100px 50px 0 0; /*3.上边宽100，右边宽50，下左边宽0*/
          border-width: 0 0 1.5vh 1.6vw; /*3.上边宽100，右边宽50，下左边宽0*/
        }
      }
    }
    .allhyyy2 {
      margin-left: 15vw;
      width: 70vw;
      height: 20vh;
      display: flex;
      .allhyyy_under_label {
        margin-left: 1.4vw;
        width: 20vw;
        height: 20vh;
        border-radius: 8px;
        .allhyyy_under_div {
          width: 100%;
          height: 90%;
          border-radius: 8px 8px 8px 0px;
          background: rgba(255, 255, 255, 1);
          .title {
            // margin-left: 0.5vw;
            width: 5vw;
            height: 2vh;
            color: rgba(61, 174, 109, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 1.1vw;
            // border: 1px solid red;
          }
          .content {
            margin-left: 4.5%;
            margin-top: 2.2vh;
            width: 91%;
            height: 10vh;
            color: rgba(102, 102, 102, 1);
            font-family: Microsoft YaHei;
            font-size: 0.9vw;
          }
        }
        .allhyyy_under_sj {
          margin-left: 18.4vw;
          width: 0; /*1.设置宽高为0*/
          height: 0;
          /*2.除右边都变透明色，实边*/
          border-color: transparent rgba(255, 255, 255, 1) transparent
            transparent;
          border-style: solid;
          //  border-width: 100px 50px 0 0; /*3.上边宽100，右边宽50，下左边宽0*/
          border-width: 1.5vh 1.6vw 0 0; /*3.上边宽100，右边宽50，下左边宽0*/
        }
      }
    }
    .zj {
      margin-left: 15vw;
      width: 70vw;
      height: 5vh;
      display: flex;
      .a {
        width: 1.4vw;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        div {
          width: 0.25vw;
          height: 0.25vw;
          border-radius: 50%;
          background: rgba(61, 174, 109, 1);
        }
      }
      .b {
        width: 20vw;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .c {
          width: 0.5vw;
          height: 0.5vw;
          border-radius: 50%;
          background: rgba(61, 174, 109, 1);
        }
        .d {
          width: 0.25vw;
          height: 0.25vw;
          border-radius: 50%;
          background: rgba(61, 174, 109, 1);
        }
      }
    }
  }
  .ryzz {
    margin-bottom: 15vh;
    background: rgba(255, 255, 255, 1);
    .title {
      text-align: center;
      padding-top: 8vh;
      color: rgba(51, 51, 51, 1);
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 28px;
      letter-spacing: 30%;
      .titleleft {
        margin-right: 1vw;
        margin-bottom: 1vh;
      }
      .titleright {
        margin-left: 1vw;
        margin-bottom: 1vh;
      }
    }
    .titlea {
      text-align: center;
    }
    .allryzz {
      width: 60vw;
      margin-left: 20vw;
      margin-top: 5vh;
      justify-content: space-around;
      align-content: space-between;
      display: flex;
      flex-wrap: wrap;
      .a {
        width: 30%;
        height: 22%;
        margin-top: 3vh;
        .b {
          width: 90%;
          height: 90%;
          margin-top: 5%;
          margin-left: 5%;
          border-radius: 8px;
        }
        img {
          width: 100%;
          border-radius: 8px;
        }
        .label {
          margin-top: 2vh;
          text-align: center;
          color: rgba(102, 102, 102, 1);
          font-family: Microsoft YaHei;
          font-weight: bold;
          font-size: 0.8vw;
          margin-bottom: 2vh;
        }
      }
      .a:hover {
        border-radius: 10px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.12),
          0px 4px 8px rgba(0, 0, 0, 0.04);
        transition: all 1.2s;
      }
    }
  }
}
</style>
