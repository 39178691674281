<template>
  <div class="body">
    <fwaltop></fwaltop>
    <div class="fwal">
      <div class="fwalicons">
        <div class="icon icons" @mouseenter="x1" @mouseleave="y" @click="ServiceCase">
          <div class="flex">
            <img
              v-if="l1 == true"
              class="fleximg"
              src="./../assets/官网切图2.0/官网切图2.0/解决方案_BMS_绿.svg"
              alt=""
            />
            <img
              v-else
              class="fleximg"
              src="./../assets/官网切图2.0/官网切图2.0/解决方案_BMS_灰.svg"
              alt=""
            />
            <div>智控系统集成</div>
          </div>
          <img
            v-if="l1 == true"
            class="jt"
            src="./../assets/520/方向.svg"
            alt=""
          />
        </div>
        <div class="icon changecolor">
          <div class="flex">
            <img
              class="fleximg"
              src="./../assets/官网切图汇总/SVG/解决方案_定制_白.svg"
              alt=""
            />

            <div class="xiticolor">软件平台产品</div>
          </div>

          <img
            class="jt"
            src="./../assets/官网补充切图2/官网补充切图/箭头.svg"
            alt=""
          />
        </div>
        <div class="icon icons" @mouseenter="x2" @mouseleave="y" @click="ServiceSystem">
          <div class="flex">
            <img
              v-if="l2 == true"
              class="fleximg"
              src="./../assets/官网切图2.0/官网切图2.0/解决方案_节能_绿.svg"
              alt=""
            />
            <img
              v-else
              class="fleximg"
              src="./../assets/官网切图2.0/官网切图2.0/解决方案_节能_灰.svg"
              alt=""
            />
            <div>系统节能改造</div>
          </div>
          <img
            v-if="l2 == true"
            class="jt"
            src="./../assets/520/方向.svg"
            alt=""
          />
        </div>
        <div class="icon icons" @mouseenter="x3" @mouseleave="y" @click="ServiceEnergy">
          <div class="flex">
            <img
              v-if="l3 == true"
              class="fleximg"
              src="./../assets/官网切图2.0/官网切图2.0/解决方案_动力_绿.svg"
              alt=""
            />
            <img
              v-else
              class="fleximg"
              src="./../assets/官网切图2.0/官网切图2.0/解决方案_动力_灰.svg"
              alt=""
            />
            <div>能源系统集成</div>
          </div>
          <img
            v-if="l3 == true"
            class="jt"
            src="./../assets/520/方向.svg"
            alt=""
          />
        </div>
      </div>
      <div class="three">
        <img
          src="./../assets/官网补充/官网补充/img_案例07.png"
          alt=""
          class="tworight"
        />
        <div class="twoleft">
          <div class="twoleftfirstdiv">
            <div class="divfirst"></div>
            <div class="title">上海蒙自泵站城市排水调度系统</div>
            <!-- <div class="time">2021年12月12日</div> -->
          </div>
          <div class="twoleftseconddiv">
            城市排水官网数据信息化监控管理平台，能够监测整个泵站排水管网的运行状态，包含泵组设备运行状态、流量液位工艺参数状态等；通过以往运行经验设定各泵站排水流量上下线指标，指导泵站泵组运行。本项目最终实现了基于大数据、云计算技术结合人工智能算法，对数据进行清洗以及深度分析挖掘，建立城市排水管网智能优化调度体系。
          </div>
        </div>
      </div>
      <div class="two">
        <div class="twoleft">
          <div class="twoleftfirstdiv">
            <div class="divfirst"></div>
            <div class="title">上海纳铁福传动有限公司</div>
            <!-- <div class="time">2021年12月12日</div> -->
          </div>
          <div class="twoleftseconddiv">
            上海纳铁福集团通过能源管理系统的建设，从生产、能源数据的手工抄表到能管实时采集、监控、汇总分析，实现能源及生产数据的可视化，使粗放式的能源管理模式转变为信息化、精细化管理模式，节约了传统管理模式所产生的人力及时间成本，保证了获取信息的及时性，为生产及能源的指挥调度提供决策依据。
          </div>
        </div>
        <img
          src="./../assets/官网补充/官网补充/img_案例05.png"
          alt=""
          class="tworight"
        />
      </div>
      <div class="three">
        <img
          src="./../assets/官网补充/官网补充/img_案例09.png"
          alt=""
          class="tworight"
        />
        <div class="twoleft">
          <div class="twoleftfirstdiv">
            <div class="divfirst"></div>
            <div class="title">东风本田生产安全及质量监管平台</div>
            <!-- <div class="time">2021年12月12日</div> -->
          </div>
          <div class="twoleftseconddiv">
            本项目为东风本田工厂生产安全监控系统。该系统通过底层PLC及其他硬件设备的通讯，可以实现产线安灯系统、设备监控系统、质量监控系统的远程在线监测与控制。
          </div>
        </div>
      </div>
      <div class="two">
        <div class="twoleft">
          <div class="twoleftfirstdiv">
            <div class="divfirst"></div>
            <div class="title">上海电气智慧园区管理平台</div>
            <!-- <div class="time">2021年12月12日</div> -->
          </div>
          <div class="twoleftseconddiv">
            本项目为上海电气集团定制的智能楼宇集成管理系统，通过软件平台的搭建，管理方可以实现整个建筑办公园区的门禁系统、智能照明系统、楼宇自控系统、能源管理系统、会议预约系统、视频监控系统等各子系统的远程监控，实现了物业管理的便捷化。
          </div>
        </div>
        <img
          src="./../assets/官网补充/官网补充/img_案例08.png"
          alt=""
          class="tworight"
        />
      </div>
      <div class="three">
        <img
          src="./../assets/官网补充/官网补充/img_案例02.png"
          alt=""
          class="tworight"
        />
        <div class="twoleft">
          <div class="twoleftfirstdiv">
            <div class="divfirst"></div>
            <div class="title">上汽郑州基地工厂</div>
            <!-- <div class="time">2021年12月12日</div> -->
          </div>
          <div class="twoleftseconddiv">
            本项目位于郑州上汽工厂基地，我司在本项目承建了冲压车间、车身车间、涂装车间、总装车间、能源站房、行政楼、食堂等十三个建筑单体的BA自控系统。控制系统设计冷热源系统、车间空调系统、空压系统、污水处理系统等。
          </div>
        </div>
      </div>
      <div class="two">
        <div class="twoleft">
          <div class="twoleftfirstdiv">
            <div class="divfirst"></div>
            <div class="title">临港智芯能源管理系统</div>
            <!-- <div class="time">2021年12月12日</div> -->
          </div>
          <div class="twoleftseconddiv">
            临港智芯产业园能源管理系统通过园区用能设备实时数据采集，通过数据分析展示等手段助力企业节能减排。
            平台主要功能包括：能源监控、能源展示、能源分析、能源报表；
          </div>
          <div class="twoleftseconddiv">
            借助于BS开放式软件平台架构，能够帮助用户分析能效水平、了解能源结构、制定节能优化方案，有效提升能源使用效率。
          </div>
        </div>
        <img
          src="./../assets/官网补充/官网补充/img_案例06.png"
          alt=""
          class="tworight"
        />
      </div>
      <div class="more">- 更多案例请咨询 -</div>
    </div>
    <under></under>
  </div>
</template>

<script>
import under from "../components/under.vue";
import fwaltop from "../components/fwaltop.vue";
export default {
  components: { under, fwaltop },
  data: function () {
    return {
      l1: false,
      l2: false,
      l3: false,
    };
  },
  created() {},
  methods: {
    xq1() {
      this.$router.push("/fwalxq");
    },

    ServiceCase() {
      this.$router.push("/ServiceCase");
    },

    ServiceSystem() {
      this.$router.push("/ServiceSystem");
    },
    ServiceEnergy() {
      this.$router.push("/ServiceEnergy");
    },
    x1() {
      this.l1 = true;
      this.l2 = false;
      this.l3 = false;
    },
    x2() {
      this.l1 = false;
      this.l2 = true;
      this.l3 = false;
    },
    x3() {
      this.l1 = false;
      this.l2 = false;
      this.l3 = true;
    },
    y() {
      this.l1 = false;
      this.l2 = false;
      this.l3 = false;
    },
  },
};
</script>

<style lang="less" scoped>
.body {
  width: 100%;

  .fwal {
    width: 100vw;
    .fwalicons {
      width: 66vw;
      margin-left: 17vw;
      display: flex;
      justify-content: space-between;
      text-align: center;
      margin-top: 5vh;
      margin-bottom: 5vh;
      .icon {
        width: 14.5vw;
        cursor: pointer;
        .flex {
          width: 14.5vw;
          display: flex;
          .fleximg {
            margin-top: 3vh;
            margin-bottom: 3vh;
            width: 2vw;
            margin-left: 2vw;
          }
          div {
            margin-top: 3vh;
            margin-left: 1vw;
            color: rgba(51, 51, 51, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 1vw;
            line-height: 2vw;
          }
          .xiticolor {
            color: rgba(255, 255, 255, 1);
          }
        }
        .jt {
          margin-bottom: 3vh;
          width: 2vw;
        }
      }
      .icons:hover {
        border-radius: 6px;
        .flex {
          div {
            color: #3dae6d;
          }
        }
      }
      .changecolor {
        border-radius: 6px;
        background: rgba(61, 174, 109, 1);
      }
    }
    .two {
      width: 66.7vw;
      margin-left: 16.65vw;
      display: flex;
      justify-content: space-around;
      margin-top: 12vh;
      margin-bottom: 5vh;
      text-align: justify;
      .twoleft {
        width: 27vw;

        .twoleftfirstdiv {
          display: flex;
          height: 5vh;
          margin-top: 3vh;
          .divfirst {
            margin-top: 1.45vh;
            margin-left: 2.5vw;
            width: 0.7vh;
            height: 2.1vh;
            border-radius: 6px;
            background: rgba(61, 174, 109, 1);
          }
          .title {
            margin-left: 0.5vw;
            line-height: 5vh;
            margin-bottom: 2vh;
            color: rgba(51, 51, 51, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 2.5vh;
          }
        }
        .twoleftseconddiv {
          width: 25vw;
          margin-left: 1vw;
          margin-top: 5vh;
          color: rgba(102, 102, 102, 1);
          font-family: Microsoft YaHei;
          font-size: 1.6vh;
          line-height: 200%;
        }
      }
      .tworight {
        width: 37.7vw;
      }
    }
    .three {
      width: 66.7vw;
      margin-left: 16.65vw;
      display: flex;
      justify-content: space-around;
      margin-top: 12vh;
      margin-bottom: 5vh;
      text-align: justify;
      .twoleft {
        width: 27vw;

        .twoleftfirstdiv {
          display: flex;
          height: 5vh;
          margin-top: 3vh;
          .divfirst {
            margin-top: 1.45vh;
            margin-left: 2.5vw;
            width: 0.7vh;
            height: 2.1vh;
            border-radius: 6px;
            background: rgba(61, 174, 109, 1);
          }
          .title {
            // width: 15vw;
            margin-left: 0.5vw;
            line-height: 5vh;
            margin-bottom: 2vh;
            color: rgba(51, 51, 51, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 2.5vh;
          }
        }
        .twoleftseconddiv {
          width: 25vw;
          margin-left: 1vw;
          margin-top: 5vh;
          color: rgba(102, 102, 102, 1);
          font-family: Microsoft YaHei;
          font-size: 1.6vh;
          line-height: 200%;
        }
      }
      .tworight {
        width: 37.7vw;
      }
    }
    .more {
      margin-left: 44vw;
      width: 12vw;
      height: 5vh;
      line-height: 5vh;
      border-radius: 6px;
      font-size: 1vw;
      text-align: center;
      border: 1px solid rgba(61, 174, 109, 1);
      color: rgb(61, 174, 109);
      margin-top: 10vh;
      margin-bottom: 10vh;
    }
  }
}
</style>
