<template>
  <div class="allzyywright">
    <div class="title1">
      <div class="left"></div>
      <div class="title">系统介绍</div>
      <div class="big"></div>
      <div class="middle"></div>
      <div class="small"></div>
    </div>
    <p class="content1">
      IMES工业制造智慧工厂管理云平台集成了设备监控、能源监控、生产安灯、制造物流多系统，最终实现对车间生产、安全、设备等多维度、全方位的监控和管理。
    </p>
    <div class="title3">
      <div class="left"></div>
      <div class="title">主要功能</div>
      <div class="big"></div>
      <div class="middle"></div>
      <div class="small"></div>
    </div>
    <div class="underimg">
      <div class="title">
        <div class="yuan"></div>
        <div class="label">Andon系统</div>
      </div>
      <p class="content2">
        建立全面的安灯系统，生产问题能够及时反馈和处理，在系统的辅助下保障生产顺利进行。
      </p>
      <div class="title">
        <div class="yuan"></div>
        <div class="label">能源监控系统</div>
      </div>
      <p class="content2">
        实现完善的能源监控和能耗分析体系，对车间用电设备进行管控，异常情况及时预警。
      </p>
      <div class="title">
        <div class="yuan"></div>
        <div class="label">设备监控系统</div>
      </div>
      <p class="content2">
        对车间的核心关键设备进行实时监控，一站式观察设备运行状态信息。
      </p>
      <div class="title">
        <div class="yuan"></div>
        <div class="label">视频监控系统</div>
      </div>
      <p class="content2">
        可实时监视区域的所有图像信息,完成远程监控图像的实时平台显示
      </p>
    </div>
    <div class="flextu">
      <div class="img">
        <img
          src="./../assets/官网切图汇总/主营业务_软件产品中心09.png"
          alt=""
        />
      </div>
      <div class="img">
        <img
          src="./../assets/官网切图汇总/主营业务_软件产品中心08.png"
          alt=""
        />
      </div>
      <div class="img">
        <img
          src="./../assets/官网切图汇总/主营业务_软件产品中心07.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {};
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.allzyywright {
  width: 48vw;
  height: 100%;
  .title1 {
    display: flex;
    height: 5vh;
    .left {
      margin-top: 1.45vh;
      margin-left: 2.5vw;
      width: 0.7vh;
      height: 2.1vh;
      border-radius: 5px;
      background: rgba(61, 174, 109, 1);
    }
    .title {
      margin-left: 1vw;
      line-height: 5vh;
      color: rgba(51, 51, 51, 1);
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 2.5vh;
    }
    .big {
      margin-left: 0.5vw;
      margin-top: 2.05vh;
      width: 0.9vh;
      height: 0.9vh;
      background: rgba(61, 174, 109, 1);
      border-radius: 50%;
    }
    .middle {
      margin-left: 0.5vw;
      margin-top: 2.2vh;
      width: 0.6vh;
      height: 0.6vh;
      background: rgba(61, 174, 109, 1);
      border-radius: 50%;
    }
    .small {
      margin-left: 0.5vw;
      margin-top: 2.35vh;
      width: 0.3vh;
      height: 0.3vh;
      background: rgba(61, 174, 109, 1);
      border-radius: 50%;
    }
  }
  .title3 {
    display: flex;
    height: 5vh;
    margin-top: 2vh;
    margin-bottom: 3vh;
    .left {
      margin-top: 1.45vh;
      margin-left: 2.5vw;
      width: 0.7vh;
      height: 2.1vh;
      border-radius: 5px;
      background: rgba(61, 174, 109, 1);
    }
    .title {
      margin-left: 1vw;
      line-height: 5vh;
      color: rgba(51, 51, 51, 1);
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 2.5vh;
    }
    .big {
      margin-left: 0.5vw;
      margin-top: 2.05vh;
      width: 0.9vh;
      height: 0.9vh;
      background: rgba(61, 174, 109, 1);
      border-radius: 50%;
    }
    .middle {
      margin-left: 0.5vw;
      margin-top: 2.2vh;
      width: 0.6vh;
      height: 0.6vh;
      background: rgba(61, 174, 109, 1);
      border-radius: 50%;
    }
    .small {
      margin-left: 0.5vw;
      margin-top: 2.35vh;
      width: 0.3vh;
      height: 0.3vh;
      background: rgba(61, 174, 109, 1);
      border-radius: 50%;
    }
  }
  .content1 {
    margin-top: 2vh;
    width: 42vw;
    margin-left: 3vw;
    color: rgba(102, 102, 102, 1);
    font-family: Microsoft YaHei;
    font-size: 1.7vh;
    line-height: 3.4vh;
    margin-bottom: 4vh;
  }
  .contentimg {
    width: 42vw;
    margin-top: 2vh;
    margin-left: 3vw;
    margin-bottom: 3vh;
  }
  .underimg {
    width: 45vw;
    margin-left: 1.5vw;

    .title {
      height: 5vh;
      line-height: 5vh;
      display: flex;
      margin-left: 1vw;
      .yuan {
        width: 0.4vh;
        height: 0.4vh;
        border-radius: 50%;
        margin-top: 2.3vh;
        background: rgba(153, 153, 153, 1);
      }
      .label {
        margin-left: 0.5vw;
        color: rgba(51, 51, 51, 1);
        font-family: Microsoft YaHei;
        font-size: 1vw;
        font-weight: bold;
      }
    }
    .content2 {
      margin-left: 1.5vw;
      width: 42vw;
      color: rgba(102, 102, 102, 1);
      font-family: Microsoft YaHei;
      font-size: 0.8vw;
      line-height: 2.8vh;
      margin-top: 1vh;
      margin-bottom: 2vh;
    }
  }
  .flextu {
    width: 45vw;
    margin-left: 1.5vw;
    display: flex;
    .img {
      width: 15vw;
      text-align: center;
      img {
        width: 90%;
      }
      div {
        margin-top: 1vh;
        text-align: center;
        line-height: 3vh;
        color: rgba(51, 51, 51, 1);
        font-family: Microsoft YaHei;
        font-size: 1.2vh;
        margin-bottom: 2vh;
      }
    }
  }
}
</style>
