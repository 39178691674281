<template>
  <div class="body">
    <fwaltop></fwaltop>
    <div class="fwal">
      <div class="fwalicons">
        <div class="icon icons" @mouseenter="x1" @mouseleave="y" @click="ServiceCase">
          <div class="flex">
            <img
              v-if="l1 == true"
              class="fleximg"
              src="./../assets/官网切图2.0/官网切图2.0/解决方案_BMS_绿.svg"
              alt=""
            />
            <img
              v-else
              class="fleximg"
              src="./../assets/官网切图2.0/官网切图2.0/解决方案_BMS_灰.svg"
              alt=""
            />
            <div>智控系统集成</div>
          </div>
          <img
            v-if="l1 == true"
            class="jt"
            src="./../assets/520/方向.svg"
            alt=""
          />
        </div>
        <div class="icon icons" @mouseenter="x2" @mouseleave="y" @click="ServiceSoftware">
          <div class="flex">
            <img
              v-if="l2 == true"
              class="fleximg"
              src="./../assets/官网切图0430/官网切图0430/解决方案_定制_绿.svg"
            />
            <img
              v-else
              class="fleximg"
              src="./../assets/官网切图0430/官网切图0430/解决方案_定制_灰.svg"
            />
            <div>软件平台产品</div>
          </div>
          <img
            v-if="l2 == true"
            class="jt"
            src="./../assets/520/方向.svg"
            alt=""
          />
        </div>
        <div class="icon changecolor">
          <div class="flex">
            <img
              class="fleximg"
              src="./../assets/官网补充切图2/官网补充切图/服务案例_节能_白2.svg"
              alt=""
            />
            <div class="xiticolor">系统节能改造</div>
          </div>
          <img
            class="jt"
            src="./../assets/官网补充切图2/官网补充切图/箭头.svg"
            alt=""
          />
        </div>
        <div class="icon icons" @mouseenter="x3" @mouseleave="y" @click="ServiceEnergy">
          <div class="flex">
             <img
             v-if="l3 == true"
              class="fleximg"
              src="./../assets/官网切图2.0/官网切图2.0/解决方案_动力_绿.svg"
              alt=""
            />
            <img
            v-else
              class="fleximg"
              src="./../assets/官网切图2.0/官网切图2.0/解决方案_动力_灰.svg"
              alt=""
            />
            <div>能源系统集成</div>
          </div>
          <img
            v-if="l3 == true"
            class="jt"
            src="./../assets/520/方向.svg"
            alt=""
          />
        </div>
      </div>
      <div class="three">
        <img
          src="./../assets/官网补充/官网补充/img_案例10.png"
          alt=""
          class="tworight"
        />
        <div class="twoleft">
          <div class="twoleftfirstdiv">
            <div class="divfirst"></div>
            <div class="title">上海第十人民医院</div>
            <!-- <div class="time">2021年12月12日</div> -->
          </div>
          <div class="twoleftseconddiv">
            上海第十人民医院院区采用集中式供冷（热），针对医院空调能耗较大的特点，我司开发了一套用于节能分析的能源管理软件，平台数据通过底层设备采集后进行分析，仿真运算后得出的开机优化策略反馈至底层PLC控制系统用于指导系统运行。
          </div>
        </div>
      </div>
      <div class="two">
        <div class="twoleft">
          <div class="twoleftfirstdiv">
            <div class="divfirst"></div>
            <div class="title">华为上海研发基地</div>
            <!-- <div class="time">2021年12月12日</div> -->
          </div>
          <div class="twoleftseconddiv">
            华为上海研发基地冷源系统采用了冰蓄冷方式为整个研发基地大楼及数据机房进行供冷。原系统采用特灵DDC系统及西门子WINCC系统分别对冷机、蓄冷罐板换系统进行控制。为方便运营方便及节能考虑，我司对原系统进行了改造，将两种不同的控制系统进行整合到一套WINCC系统中，对冰蓄冷系统进行控制策略优化，达到运营便捷化、节能化的目的。
          </div>
        </div>
        <img
          src="./../assets/官网补充/官网补充/img_案例04.png"
          alt=""
          class="tworight"
        />
      </div>
      <div class="three">
        <img
          src="./../assets/服务案例01/服务案例04.png"
          alt=""
          class="tworight"
        />
        <div class="twoleft">
          <div class="twoleftfirstdiv">
            <div class="divfirst"></div>
            <div class="title">东风本田工厂能源中心节能系统</div>
            <!-- <div class="time">2021年12月12日</div> -->
          </div>
          <div class="twoleftseconddiv">
            东风本田工厂能源中心负责整个整车制造工厂的空调及工艺供冷，能源中心全年能耗巨大，如何在安全稳定运行前提下实现节能降耗是东风本田长期重点关注的领域。我司为东风本田工厂能源中心设计建造的AI智慧供能节能控制系统，经过半年多的实际运行数据统计分析，达到了很好的节能效果。
          </div>
        </div>
      </div>
      <div class="more">- 更多案例请咨询 -</div>
    </div>
    <under></under>
  </div>
</template>

<script>
import under from "../components/under.vue";
import fwaltop from "../components/fwaltop.vue";
export default {
  components: { under, fwaltop },
  data: function () {
    return {
      l1: false,
      l2: false,
      l3: false,
    };
  },
  created() {},
  methods: {
    xq1() {
      this.$router.push("/fwalxq");
    },
    ServiceCase() {
      this.$router.push("/ServiceCase");
    },
    ServiceSoftware() {
      this.$router.push("/ServiceSoftware");
    },
    ServiceEnergy() {
      this.$router.push("/ServiceEnergy");
    },
    x1() {
      this.l1 = true;
      this.l2 = false;
      this.l3 = false;
    },
    x2() {
      this.l1 = false;
      this.l2 = true;
      this.l3 = false;
    },
    x3() {
      this.l1 = false;
      this.l2 = false;
      this.l3 = true;
    },
    y() {
      this.l1 = false;
      this.l2 = false;
      this.l3 = false;
    },
  },
};
</script>

<style lang="less" scoped>
.body {
  width: 100%;
  .fwal {
    width: 100vw;
    .fwalicons {
      width: 66vw;
      margin-left: 17vw;
      display: flex;
      justify-content: space-between;
      text-align: center;
      margin-top: 5vh;
      margin-bottom: 5vh;
      .icon {
        width: 14.5vw;
        cursor: pointer;
        .flex {
          width: 14.5vw;
          display: flex;
          .fleximg {
            margin-top: 3vh;
            margin-bottom: 3vh;
            width: 2vw;
            margin-left: 2vw;
          }
          div {
            margin-top: 3vh;
            margin-left: 1vw;
            color: rgba(51, 51, 51, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 1vw;
            line-height: 2vw;
          }
          .xiticolor {
            color: rgba(255, 255, 255, 1);
          }
        }
        .jt {
          margin-bottom: 3vh;
          width: 2vw;
        }
      }
      .icons:hover {
        border-radius: 6px;
        .flex {
          div {
            color: #3dae6d;
          }
        }
      }
      .changecolor {
        border-radius: 6px;
        background: rgba(61, 174, 109, 1);
      }
    }
    .two {
      width: 66.7vw;
      margin-left: 16.65vw;
      display: flex;
      justify-content: space-around;
      margin-top: 12vh;
      margin-bottom: 5vh;
      text-align: justify;
      .twoleft {
        width: 27vw;

        .twoleftfirstdiv {
          display: flex;
          height: 5vh;
          margin-top: 3vh;
          .divfirst {
            margin-top: 1.45vh;
            margin-left: 2.5vw;
            width: 0.7vh;
            height: 2.1vh;
            border-radius: 6px;
            background: rgba(61, 174, 109, 1);
          }
          .title {
            margin-left: 0.5vw;
            line-height: 5vh;
            margin-bottom: 2vh;
            color: rgba(51, 51, 51, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 2.5vh;
          }
        }
        .twoleftseconddiv {
          width: 25vw;
          margin-left: 1vw;
          margin-top: 5vh;
          color: rgba(102, 102, 102, 1);
          font-family: Microsoft YaHei;
          font-size: 1.6vh;
          line-height: 200%;
        }
      }
      .tworight {
        width: 37.7vw;
      }
    }
    .three {
      width: 66.7vw;
      margin-left: 16.65vw;
      display: flex;
      justify-content: space-around;
      margin-top: 12vh;
      margin-bottom: 5vh;
      text-align: justify;
      .twoleft {
        width: 27vw;

        .twoleftfirstdiv {
          display: flex;
          height: 5vh;
          margin-top: 3vh;
          .divfirst {
            margin-top: 1.45vh;
            margin-left: 2.5vw;
            width: 0.7vh;
            height: 2.1vh;
            border-radius: 6px;
            background: rgba(61, 174, 109, 1);
          }
          .title {
            // width: 15vw;
            margin-left: 0.5vw;
            line-height: 5vh;
            margin-bottom: 2vh;
            color: rgba(51, 51, 51, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 2.5vh;
          }
        }
        .twoleftseconddiv {
          width: 25vw;
          margin-left: 1vw;
          margin-top: 5vh;
          color: rgba(102, 102, 102, 1);
          font-family: Microsoft YaHei;
          font-size: 1.6vh;
          line-height: 200%;
        }
      }
      .tworight {
        width: 37.7vw;
      }
    }
    .more {
      margin-left: 44vw;
      width: 12vw;
      height: 5vh;
      line-height: 5vh;
      border-radius: 6px;
      font-size: 1vw;
      text-align: center;
      border: 1px solid rgba(61, 174, 109, 1);
      color: rgb(61, 174, 109);
      margin-top: 10vh;
      margin-bottom: 10vh;
    }
  }
}
</style>
