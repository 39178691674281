<template>
  <div class="body">
    <div class="head">
      <div class="top">
        <img
         src="./../assets/枢源LOGO02.svg"
          alt=""
          class="gslogo"
        />
        <div class="type_item type_item1" @click="index">首页</div>

        <div class="type_item type_item1" @click="AboutUs">关于枢源</div>

        <div class="type_item type_item1" @click="MainBusiness">主营业务</div>

        <div class="type_item type_item1" @click="InoustaySolutions">行业解决方案</div>

        <div class="type_item type_item1" @click="ServiceCase">服务案例</div>

        <div class="type_item type_item1" @click="NewsInformation">新闻资讯</div>

        <div class="type_item typeActive">
          联系我们
          <div class="typeActive1"></div>
        </div>
        <div class="a">
          <div class="ab">服务热线：021-64026001</div>
        </div>
      </div>
      <img
        src="./../assets/官网子页面切图/官网子页面切图/标题文字_联系我们.svg"
        alt=""
        class="al"
      />
    </div>
    <div class="lxwm">
      <div class="first">
        <div class="left">
          <img src="./../assets/联系我们切图/定位-枢源总部.svg" alt="" />
        </div>
        <div class="right">
          <div class="title">枢源科技总部</div>
          <div class="middle">
            <div class="middleleft">
              <img
                src="./../assets/联系我们切图/联系我们_总部地址.svg"
                alt=""
              />
            </div>
            <div class="middleright">
              上海市闵行区联航路1188号10号楼2层F/G室（浦江智谷）
            </div>
          </div>
          <div class="under">
            <div class="underleft">
              <img src="./../assets/联系我们切图/联系我们_电话.svg" alt="" />
            </div>
            <div class="underright">座机：021-64026001</div>
          </div>
        </div>
      </div>
      <div class="second">
        <div class="left">
          <img src="./../assets/联系我们切图/定位-研发基地.svg" alt="" />
        </div>
        <div class="right">
          <div class="title">同济大学产学研究基地</div>
          <div class="littletitle">上海区块链技术研究中心</div>
          <div class="middle">
            <div class="middleleft">
              <img
                src="./../assets/联系我们切图/联系我们_总部地址.svg"
                alt=""
              />
            </div>
            <div class="middleright">
              上海市杨浦区国康路100号2201室（上海国际设计中心）
            </div>
          </div>
          <div class="under">
            <div class="underleft">
              <img src="./../assets/联系我们切图/联系我们_电话.svg" alt="" />
            </div>
            <div class="underright">座机：021-64026001</div>
          </div>
        </div>
      </div>
      <div class="third">
        <div class="left">
          <img src="./../assets/联系我们切图/定位-生产基地.svg" alt="" />
        </div>
        <div class="right">
          <div class="title">智控设备生产基地</div>
          <div class="littletitle">桐乡智创园▪乌镇数字经济产业园区</div>
          <div class="middle">
            <div class="middleleft">
              <img
                src="./../assets/联系我们切图/联系我们_总部地址.svg"
                alt=""
              />
            </div>
            <div class="middleright">浙江省桐乡市梧桐街道稻乐路925号2栋4楼</div>
          </div>
          <div class="under">
            <div class="underleft">
              <img src="./../assets/联系我们切图/联系我们_电话.svg" alt="" />
            </div>
            <div class="underright">座机：0573- 8264 0558</div>
          </div>
        </div>
      </div>
    </div>
    <under></under>
  </div>
</template>

<script>
import under from "../components/under.vue";
export default {
  components: { under },
  data: function () {
    return {};
  },
  created() {},
  methods: {
    index() {
      this.$router.push("/");
    },
    AboutUs() {
      this.$router.push("/AboutUs");
    },
    MainBusiness() {
      this.$router.push("/MainBusiness");
    },
    InoustaySolutions() {
      this.$router.push("/InoustaySolutions");
    },
    ServiceCase() {
      this.$router.push("/ServiceCase");
    },
    NewsInformation() {
      this.$router.push("/NewsInformation");
    },
  },
};
</script>

<style lang="less" scoped>
.body {
  width: 100%;
  .head {
   height: 17.1875vw;
    width: 100vw;
    background-image: url("./../assets/官网压缩图片/top_联系我们.png");
    background-size: cover;
    background-position: center center;
    .top {
      height: 9vh;
      background: rgba(31, 31, 31, 0.2);
      display: flex;
      .gslogo {
        margin-top: 1.5vh;
        margin-left: 15vw;
        margin-right: 2vw;
        height: 6vh;
      }
      .type_item {
        position: relative;
        margin-left: 2.2vw;
        color: rgb(255, 255, 255);
        font-size: 1vw;
        margin-top: 2.9vh;
        height: 3.2vh;
        line-height: 3.2vh;
        cursor: pointer;
      }
      .type_item1:hover {
        color: rgb(61, 174, 109);
      }
      .typeActive {
        // color: rgb(61, 174, 109);
        color: #87FFBA;
        font-weight: bold;
      }
      .typeActive1 {
        width: 50%;
        margin-left: 25%;
        margin-top: 0.5vh;
        height: 2px;
        background: rgb(61, 174, 109);
      }

      .a {
        position: relative;
        margin-left: 3vw;
        color: rgb(255, 255, 255);
        .ab {
          background: rgb(61, 174, 109);
          width: 12vw;
          border-radius: 50px;
          text-align: center;
          font-size: 0.9vw;
          color: rgb(255, 255, 255);
          font-family: Microsoft YaHei;
          letter-spacing: 12%;
          margin-top: 2.5vh;
          height: 4vh;
          line-height: 4vh;
        }
      }
    }
    .al {
      height: 6vh;
      margin-top: 12vh;
      margin-left: 23vw;
    }
  }
  .lxwm {
    width: 100vw;
    padding-bottom: 10vh;
    position: relative;
    background: rgba(249, 249, 249, 1);
    .first {
      width: 70vw;
      margin-left: 15vw;
      display: flex;
      padding-top: 10vh;

      .left {
        position: relative;
        width: 47vw;
        height: 40vh;
        background-image: url("./../assets/联系我们切图/枢源科技总部.png");
        background-size: cover;
        background-position: center center;
        border-radius: 10px;
        img {
          position: absolute;
          width: 5vh;
          top: 14.8vh;
          left: 25.1vw;
        }
      }
      .right {
        width: 22vw;
        margin-left: 1vw;
        .title {
          align-items: center;
          color: rgba(51, 51, 51, 1);
          font-family: Microsoft YaHei;
          font-weight: bold;
          font-size: 2vh;
          margin-top: 4vh;
          margin-left: 1vw;
          margin-bottom: 3vh;
        }
        .middle {
          display: flex;
          height: 7vh;
          // border: 1px solid red;
          display: flex;
          margin-top: 2vh;
          .middleleft {
            border-radius: 50%;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06),
              0px 1px 4px rgba(0, 0, 0, 0.04);
            margin-top: 0.5vh;
            width: 6vh;
            height: 6vh;
            margin-left: 1vw;
            img {
              width: 4vh;
              margin-left: 1vh;
              margin-top: 1vh;
            }
          }
          .middleright {
            margin-left: 0.5vw;
            color: rgba(102, 102, 102, 1);
            font-family: Microsoft YaHei;
            font-size: 0.7vw;
            line-height: 3vh;
            width: 11vw;
          }
        }
        .under {
          display: flex;
          height: 7vh;

          display: flex;
          margin-top: 2vh;
          .underleft {
            border-radius: 50%;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06),
              0px 1px 4px rgba(0, 0, 0, 0.04);
            margin-top: 0.5vh;
            width: 6vh;
            height: 6vh;
            margin-left: 1vw;
            img {
              width: 4vh;
              margin-left: 1vh;
              margin-top: 1vh;
            }
          }
          .underright {
            margin-left: 1vw;
            color: rgba(102, 102, 102, 1);
            font-family: Microsoft YaHei;
            font-size: 0.7vw;
            line-height: 7vh;
          }
        }
      }
    }
    .second {
      width: 70vw;
      margin-left: 15vw;
      margin-top: 10vh;
      display: flex;
      .left {
        position: relative;
        width: 47vw;
        height: 40vh;
        background-image: url("./../assets/联系我们切图/同济大学产学研究基地.png");
        background-size: cover;
        background-position: center center;
        border-radius: 10px;
        img {
          position: absolute;
          width: 5vh;
          top: 13vh;
          left: 24.3vw;
        }
      }
      .right {
        width: 22vw;
        margin-left: 1vw;
        .title {
          align-items: center;
          color: rgba(51, 51, 51, 1);
          font-family: Microsoft YaHei;
          font-weight: bold;
          font-size: 2vh;
          margin-top: 4vh;
          margin-left: 1vw;
        }
        .littletitle {
          color: rgba(102, 102, 102, 1);
          font-family: Microsoft YaHei;
          font-size: 1.4vh;
          margin-left: 1vw;
          line-height: 2.4vh;
        }
        .middle {
          display: flex;
          height: 7vh;
          // border: 1px solid red;
          display: flex;
          margin-top: 2vh;
          .middleleft {
            border-radius: 50%;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06),
              0px 1px 4px rgba(0, 0, 0, 0.04);
            margin-top: 0.5vh;
            width: 6vh;
            height: 6vh;
            margin-left: 1vw;
            img {
              width: 4vh;
              margin-left: 1vh;
              margin-top: 1vh;
            }
          }
          .middleright {
            margin-left: 0.5vw;
            color: rgba(102, 102, 102, 1);
            font-family: Microsoft YaHei;
            font-size: 0.7vw;
            line-height: 3vh;
            width: 11vw;
          }
        }
        .under {
          display: flex;
          height: 7vh;
          display: flex;
          margin-top: 2vh;
          .underleft {
            border-radius: 50%;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06),
              0px 1px 4px rgba(0, 0, 0, 0.04);
            margin-top: 0.5vh;
            width: 6vh;
            height: 6vh;
            margin-left: 1vw;
            img {
              width: 4vh;
              margin-left: 1vh;
              margin-top: 1vh;
            }
          }
          .underright {
            margin-left: 1vw;
            color: rgba(102, 102, 102, 1);
            font-family: Microsoft YaHei;
            font-size: 0.7vw;
            line-height: 7vh;
          }
        }
      }
    }
    .third {
      width: 70vw;
      margin-left: 15vw;
      display: flex;
      margin-top: 10vh;
      .left {
        position: relative;
        width: 47vw;
        height: 40vh;
        background-image: url("./../assets/联系我们切图/智控设备生产基地.png");
        background-size: cover;
        background-position: center center;
        border-radius: 10px;
        img {
          width: 5vh;
          position: absolute;
          top: 13vh;
          left: 24.3vw;
        }
      }
      .right {
        width: 22vw;
        margin-left: 1vw;
        .title {
          align-items: center;
          color: rgba(51, 51, 51, 1);
          font-family: Microsoft YaHei;
          font-weight: bold;
          font-size: 2vh;
          margin-top: 4vh;
          margin-left: 1vw;
        }
        .littletitle {
          color: rgba(102, 102, 102, 1);
          font-family: Microsoft YaHei;
          font-size: 1.4vh;
          margin-left: 1vw;
          line-height: 2.4vh;
        }
        .middle {
          display: flex;
          height: 7vh;
          // border: 1px solid red;
          display: flex;
          margin-top: 2vh;
          .middleleft {
            border-radius: 50%;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06),
              0px 1px 4px rgba(0, 0, 0, 0.04);
            margin-top: 0.5vh;
            width: 6vh;
            height: 6vh;
            margin-left: 1vw;
            img {
              width: 4vh;
              margin-left: 1vh;
              margin-top: 1vh;
            }
          }
          .middleright {
            margin-left: 0.5vw;
            color: rgba(102, 102, 102, 1);
            font-family: Microsoft YaHei;
            font-size: 0.7vw;
            line-height: 3vh;
            width: 11vw;
          }
        }
        .under {
          display: flex;
          height: 7vh;
          display: flex;
          margin-top: 2vh;
          .underleft {
            border-radius: 50%;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06),
              0px 1px 4px rgba(0, 0, 0, 0.04);
            margin-top: 0.5vh;
            width: 6vh;
            height: 6vh;
            margin-left: 1vw;
            img {
              width: 4vh;
              margin-left: 1vh;
              margin-top: 1vh;
            }
          }
          .underright {
            margin-left: 1vw;
            color: rgba(102, 102, 102, 1);
            font-family: Microsoft YaHei;
            font-size: 0.7vw;
            line-height: 7vh;
          }
        }
      }
    }
  }
}
</style>
