<template>
  <div class="body">
    <zyywtop></zyywtop>
    <div class="zyyw">
      <div class="allzyyw">
        <div class="allzyywleft">
          <div class="label h g">
            <div class="c"></div>
            <div class="d">智控系统集成</div>
          </div>
          <div class="label h f">
            <div class="a"></div>
            <div class="b" @click="MainSoftware">软件平台产品</div>
          </div>
          <div class="label h f">
            <div class="a"></div>
            <div class="b" @click="MainSystem">系统节能改造</div>
          </div>
          <div class="label h f">
            <div class="a"></div>
            <div class="b" @click="MainEnergy">能源系统集成</div>
          </div>
        </div>
        <div class="allzyywright">
          <div class="title2">
            <div class="left"></div>
            <div class="title">应用场景</div>
            <div class="big"></div>
            <div class="middle"></div>
            <div class="small"></div>
          </div>
          <img
            src="./../assets/官网切图0430/官网切图0430/BMS&EMS系统_01.png"
            alt=""
            class="contentimg"
          />
          <div class="title3">
            <div class="left"></div>
            <div class="title">枢源优势</div>
            <div class="big"></div>
            <div class="middle"></div>
            <div class="small"></div>
          </div>
          <div class="underimg">
            <div class="left">
              <p class="content2">
                枢源科技利用同济大学建筑能源系统学科优势，结合公司十多年的自控系统项目经验，开发了具有自主知识产权的上位监控软件，并广泛应用于工厂、商业建筑等各类应用场景。同时枢源科技可根据客户需求及行业特点，采用西门子WINCC、施耐德INTOUCH、江森DDC
                ADS监控、北京亚控组态王等一系列市场主流行业软件对建筑厂房能源系统进行实时监控。
              </p>
            </div>
            <img
              src="./../assets/官网切图0430/官网切图0430/BMS&EMS系统_02.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <under></under>
  </div>
</template>
<script>
import under from "./../components/under.vue";
import zyywtop from "./../components/zyywtop.vue";
export default {
  components: {
    under,
    zyywtop,
  },
  data: function () {
    return {};
  },

  created() {},
  methods: {
    MainSystem() {
      this.$router.push("/MainSystem");
    },
    MainSoftware() {
      this.$router.push("/MainSoftware");
    },
    MainEnergy() {
      this.$router.push("/MainEnergy");
    },
  },
};
</script>
<style lang="less" scoped>
.body {
  width: 100vw;

  .zyyw {
    width: 100%;
    margin-bottom: 10vh;
    .allzyyw {
      width: 60vw;
      margin-top: 5vh;
      margin-left: 20vw;
      display: flex;
      .allzyywleft {
        width: 12vw;
        height: 100%;
        .label {
          width: 100%;
          height: 6vh;
          display: flex;
          cursor: pointer;
          .c {
            margin-left: 0.5vw;
            width: 4px;
            height: 12px;
            margin-top: calc((6vh - 12px) / 2);
            border-radius: 5px;
            background: rgba(61, 174, 109, 1);
          }
          .d {
            width: 90%;
            // border: 1px solid red;
            line-height: 6vh;
            margin-left: 0.5vw;
            align-items: center;
            color: rgba(61, 174, 109, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 1vw;
          }
          .b {
            width: 90%;
            // border: 1px solid red;
            line-height: 6vh;
            margin-left: 0.5vw;
            align-items: center;
            color: rgba(31, 31, 31, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 1vw;
          }
          .a {
            width: 4px;
            height: 4px;
            margin-top: calc((6vh - 4px) / 2);
            margin-left: 0.5vw;
            border-radius: 50%;
            opacity: 0.5;
            background: rgba(61, 174, 109, 1);
          }
        }
        .label:hover {
          background: rgba(248, 253, 250, 1);
        }
        .g {
          border-right: 2px solid rgba(61, 174, 109, 1);
          background: linear-gradient(
            90deg,
            rgba(244, 255, 249, 0) 0%,
            rgba(248, 253, 250, 1) 100%
          );
        }
        .h {
          border-bottom: 1px solid rgba(61, 174, 109, 0.1);
        }
        .f {
          border-right: 2px solid rgba(61, 174, 109, 0.1);
        }
      }
      .allzyywright {
        width: 48vw;
        height: 100%;
        .title2 {
          display: flex;
          height: 5vh;
          .left {
            margin-top: 1.45vh;
            margin-left: 2.5vw;
            width: 0.7vh;
            height: 2.1vh;
            border-radius: 5px;
            background: rgba(61, 174, 109, 1);
          }
          .title {
            margin-left: 1vw;
            line-height: 5vh;
            color: rgba(51, 51, 51, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 2.5vh;
          }
          .big {
            margin-left: 0.5vw;
            margin-top: 2.05vh;
            width: 0.9vh;
            height: 0.9vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
          .middle {
            margin-left: 0.5vw;
            margin-top: 2.2vh;
            width: 0.6vh;
            height: 0.6vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
          .small {
            margin-left: 0.5vw;
            margin-top: 2.35vh;
            width: 0.3vh;
            height: 0.3vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
        }
        .title3 {
          display: flex;
          height: 5vh;
          .left {
            margin-top: 1.45vh;
            margin-left: 2.5vw;
            width: 0.7vh;
            height: 2.1vh;
            border-radius: 5px;
            background: rgba(61, 174, 109, 1);
          }
          .title {
            margin-left: 1vw;
            line-height: 5vh;
            color: rgba(51, 51, 51, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 2.5vh;
          }
          .big {
            margin-left: 0.5vw;
            margin-top: 2.05vh;
            width: 0.9vh;
            height: 0.9vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
          .middle {
            margin-left: 0.5vw;
            margin-top: 2.2vh;
            width: 0.6vh;
            height: 0.6vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
          .small {
            margin-left: 0.5vw;
            margin-top: 2.35vh;
            width: 0.3vh;
            height: 0.3vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
        }
        .contentimg {
          width: 42vw;
          margin-top: 2vh;
          margin-left: 3vw;
          margin-bottom: 3vh;
        }
        .underimg {
          display: flex;
          width: 45vw;
          margin-left: 1.5vw;
          justify-content: space-around;
          .left {
            width: 21vw;
            margin-left: 0.5vw;
            .content2 {
              margin-top: 2vh;
              margin-bottom: 3vh;
              color: rgba(102, 102, 102, 1);
              font-family: Microsoft YaHei;
              font-size: 0.8vw;
              line-height: 3.2vh;
              text-align: justify;
            }
          }
          img {
            width: 21.5vw;
            margin-left: 1vw;
          }
        }
      }
    }
  }
}
</style>
