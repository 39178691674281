<template>
  <div class="body">
    <jjfatop></jjfatop>
    <div class="hyjifa">
      <div class="allzyyw">
        <div class="allzyywleft">
          <div class="label h f">
            <div class="a"></div>
            <div class="b" @click="InoustaySolutions">生物制药</div>
          </div>
          <div class="label h f">
            <div class="a"></div>
            <div class="b" @click="AutomobileManufacturing">汽车制造</div>
          </div>
          <div class="label h f">
            <div class="a"></div>
            <div class="b" @click="UrbanDrainage">城市排水</div>
          </div>
          <div class="label h g">
            <div class="c"></div>
            <div class="d">数据中心</div>
          </div>
          <div class="label h f">
            <div class="a"></div>
            <div class="b" @click="CommercialBuildings">商业建筑</div>
          </div>
        </div>
        <div class="allzyywright">
          <div class="title1">
            <div class="left"></div>
            <div class="title">核心业务</div>
            <div class="big"></div>
            <div class="middle"></div>
            <div class="small"></div>
          </div>
          <div class="unimg">
            <div class="title">
              <div class="yuan"></div>
              <div class="label">冷源群控系统</div>
            </div>
            <p class="content2">
              采用先进、安全、可靠、节能的自控系统和设备，实现对数据中心冷源系统制冷主机、冷却塔、水泵等设备的自动运行。同时冷源群控系统可根据室外环境情况，自动选择最合理、稳定、节能的控制模式，并且根据末端负荷情况，自动分配系统各设备的运行状态及加减载运行。
            </p>
            <div class="title">
              <div class="yuan"></div>
              <div class="label">动环监控系统</div>
            </div>
            <p class="content2">
              数据中心独特的运行特点，要求动环监控系统可实现对分布在各机房的电源柜、UPS、空调、蓄电池等多种动力设备及门磁、红外、窗破、水浸、温湿度、烟感等机房环境的各种参数进行遥测、遥信、遥调和遥控,实时监测其运行参数，诊断和处理故障，记录和分析相关数据，并对设备进行集中监控和集中维护。
            </p>
            <div class="title">
              <div class="yuan"></div>
              <div class="label">一体化监控系统</div>
            </div>
            <p class="content2">
              通过现场传感器、PLC数据采集等设备等设备，将数据中心的末端空调监控系统、机房及环境监控系统、电气设备监控系统、柴发监控系统、无线AP系统、电池监控系统等各子系统集成至中央一体化监控系统平台，实现了各系统的数据展示、设备监控、状态报警等各项运行必备功能，达到数据中心安全便捷管理的目的。
            </p>
            <div class="unimgflex">
              <div class="img">
                <img src="./../assets/官网补充/官网补充/数据中心.png" alt="" />
                <div class="label">冷藏群控制系统</div>
              </div>
              <div class="img">
                <img
                  src="./../assets/官网补充/官网补充/数据中心-1.png"
                  alt=""
                />
                <div class="label">动环监控系统</div>
              </div>
              <div class="img">
                <img
                  src="./../assets/官网补充/官网补充/数据中心-2.png"
                  alt=""
                />
                <div class="label">一体化监控系统</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <under></under>
  </div>
</template>

<script>
import under from "../components/under.vue";
import jjfatop from "../components/jjfatop.vue";

export default {
  components: { under, jjfatop },
  data: function () {
    return {};
  },
  created() {},
  methods: {
    InoustaySolutions() {
      this.$router.push("/InoustaySolutions");
    },
    UrbanDrainage() {
      this.$router.push("/UrbanDrainage");
    },
    AutomobileManufacturing() {
      this.$router.push("/AutomobileManufacturing");
    },
    CommercialBuildings() {
      this.$router.push("/CommercialBuildings");
    },
  },
};
</script>

<style lang="less" scoped>
.body {
  width: 100%;
  .hyjifa {
    width: 100vw;
    background: rgba(244, 245, 246, 1);
    border: 1px solid rgba(244, 245, 246, 1);
    padding-bottom: 10vh;
    .allzyyw {
      width: 60vw;
      margin-top: 5vh;
      margin-left: 20vw;
      display: flex;
      .allzyywleft {
        width: 12vw;
        height: 100%;
        // border: 1px solid red;
        .label {
          width: 100%;
          height: 6vh;
          cursor: pointer;
          display: flex;
          .c {
            margin-left: 0.5vw;
            width: 4px;
            height: 12px;
            margin-top: calc((6vh - 12px) / 2);
            border-radius: 5px;
            background: rgba(61, 174, 109, 1);
          }
          .d {
            width: 70%;
            // border: 1px solid red;
            line-height: 6vh;
            margin-left: 0.5vw;
            align-items: center;
            color: rgba(61, 174, 109, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 1vw;
          }
          .b {
            width: 70%;
            // border: 1px solid red;
            line-height: 6vh;
            margin-left: 0.5vw;
            align-items: center;
            color: rgba(31, 31, 31, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 1vw;
          }
          .a {
            width: 4px;
            height: 4px;
            margin-top: calc((6vh - 4px) / 2);
            margin-left: 0.5vw;
            border-radius: 50%;
            opacity: 0.5;
            background: rgba(61, 174, 109, 1);
          }
        }
        .label:hover {
          background: rgba(248, 253, 250, 1);
        }
        .g {
          border-right: 2px solid rgba(61, 174, 109, 1);
          background: linear-gradient(
            90deg,
            rgba(244, 255, 249, 0) 0%,
            rgba(248, 253, 250, 1) 100%
          );
        }
        .h {
          border-bottom: 1px solid rgba(61, 174, 109, 0.1);
        }
        .f {
          border-right: 2px solid rgba(61, 174, 109, 0.1);
        }
      }
      .allzyywright {
        width: 48vw;
         .title1 {
          display: flex;
          height: 5vh;
          margin-bottom: 3vh;
          .left {
            margin-top: 1.45vh;
            margin-left: 2.5vw;
            width: 0.7vh;
            height: 2.1vh;
            border-radius: 5px;
            background: rgba(61, 174, 109, 1);
          }
          .title {
            margin-left: 1vw;
            line-height: 5vh;
            color: rgba(51, 51, 51, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 2.5vh;
          }
          .big {
            margin-left: 0.5vw;
            margin-top: 2.05vh;
            width: 0.9vh;
            height: 0.9vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
          .middle {
            margin-left: 0.5vw;
            margin-top: 2.2vh;
            width: 0.6vh;
            height: 0.6vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
          .small {
            margin-left: 0.5vw;
            margin-top: 2.35vh;
            width: 0.3vh;
            height: 0.3vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
        }
        .unimg {
          width: 45vw;
          margin-left: 1.5vw;
          margin-bottom: 3vh;
          .title {
            height: 5vh;
            line-height: 5vh;
            display: flex;
            margin-left: 1vw;
            .yuan {
              width: 0.4vh;
              height: 0.4vh;
              border-radius: 50%;
              margin-top: 2.3vh;
              background: rgba(61, 174, 109, 1);
            }
            .label {
              margin-left: 0.5vw;
              color: rgba(51, 51, 51, 1);
              font-family: Microsoft YaHei;
              font-size: 0.85vw;
              font-weight: bold;
            }
          }
          .content2 {
            margin-left: 1.5vw;
            width: 42vw;
            color: rgba(102, 102, 102, 1);
            font-family: Microsoft YaHei;
            font-size: 0.85vw;
            line-height: 3.4vh;
            margin-top: 1vh;
            margin-bottom: 2vh;
          }
          .unimgflex {
            width: 45vw;
            display: flex;
            background: rgba(249, 249, 249, 1);
            .img {
              width: 15vw;
              text-align: center;
              img {
                width: 90%;
              }
              .label {
                margin-top: 1vh;
                margin-bottom: 2vh;
                color: rgba(51, 51, 51, 1);
                font-family: Microsoft YaHei;
                font-size: 1.2vh;
              }
            }
          }
        }
      }
    }
  }
}
</style>
