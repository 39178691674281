<template>
  <div class="body">
    <zyywtop></zyywtop>
    <div class="zyyw">
      <div class="allzyyw">
        <div class="allzyywleft">
          <div class="label h f">
            <div class="a"></div>
            <div class="b" @click="MainBusiness">智控集成系统</div>
          </div>
          <div class="label h f">
            <div class="a"></div>
            <div class="b" @click="MainSoftware">软件平台产品</div>
          </div>
          <div class="label h g">
            <div class="c"></div>
            <div class="d">系统节能改造</div>
          </div>
          <div class="label h f">
            <div class="a"></div>
            <div class="b" @click="MainEnergy">能源系统集成</div>
          </div>
        </div>
        <div class="allzyywright">
          <div class="title1">
            <div class="left"></div>
            <div class="title">业务介绍</div>
            <div class="big"></div>
            <div class="middle"></div>
            <div class="small"></div>
          </div>
          <p class="content1">
            枢源科技长期致力于能源环保行业，利用高校交叉学科优势及校企合作的方式，枢源科技可对新能源汽车工业厂房、建筑办公园区、给排水工厂、商业综合楼宇等提供系统行业软件解决方案，旨在利用大数据分析、数字孪生、三维建模、人工智能等技术帮助企业实现企业运营方式的便捷化、可视化、节能化。
          </p>
          <div class="title2">
            <div class="left"></div>
            <div class="title">项目流程</div>
            <div class="big"></div>
            <div class="middle"></div>
            <div class="small"></div>
          </div>
          <img
            src="./../assets/519/519/主营业务_系统节能改造05.png"
            alt=""
            class="contentimg"
          />
          <div class="title2">
            <div class="left"></div>
            <div class="title">枢源优势</div>
            <div class="big"></div>
            <div class="middle"></div>
            <div class="small"></div>
          </div>
          <p class="content1">
            能源系统设计、施工、运行控制全流程的丰富经验，助力企业打造高效节能的能源系统
          </p>
           <img
            src="./../assets/519/519/主营业务_系统节能改造04.png"
            alt=""
            class="contentimg"
          />
          <div class="flex">
            <img
              src="./../assets/519/519/主营业务_系统节能改造01.png"
              alt=""
            />
            <img
              src="./../assets/519/519/主营业务_系统节能改造02.png"
              alt=""
            />
            <img
               src="./../assets/519/519/主营业务_系统节能改造03.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <under></under>
  </div>
</template>
<script>
import under from "../components/under.vue";
import zyywtop from "../components/zyywtop.vue";

export default {
  components: { under, zyywtop },
  data: function () {
    return {};
  },

  created() {},
  methods: {
    MainBusiness() {
      this.$router.push("/MainBusiness");
    },
    MainSoftware() {
      this.$router.push("/MainSoftware");
    },
    MainEnergy() {
      this.$router.push("/MainEnergy");
    },
  },
};
</script>
<style lang="less" scoped>
.body {
 width: 100%;
  .zyyw {
    width: 100vw;
    margin-bottom: 10vh;
    background: rgba(255, 255, 255, 1);
    .allzyyw {
      width: 60vw;
      margin-top: 5vh;
      margin-left: 20vw;
      display: flex;
      .allzyywleft {
        width: 12vw;
        height: 100%;
        .label {
          width: 100%;
          height: 6vh;
          display: flex;
          cursor: pointer;
          .c {
            margin-left: 0.5vw;
            width: 4px;
            height: 12px;
            margin-top: calc((6vh - 12px) / 2);
            border-radius: 5px;
            background: rgba(61, 174, 109, 1);
          }
          .d {
            width: 90%;
            // border: 1px solid red;
            line-height: 6vh;
            margin-left: 0.5vw;
            align-items: center;
            color: rgba(61, 174, 109, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 1vw;
          }
          .b {
            width: 90%;
            // border: 1px solid red;
            line-height: 6vh;
            margin-left: 0.5vw;
            align-items: center;
            color: rgba(31, 31, 31, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 1vw;
          }
          .a {
            width: 4px;
            height: 4px;
            margin-top: calc((6vh - 4px) / 2);
            margin-left: 0.5vw;
            border-radius: 50%;
            opacity: 0.5;
            background: rgba(61, 174, 109, 1);
          }
        }
        .label:hover {
          background: rgba(248, 253, 250, 1);
        }
        .g {
          border-right: 2px solid rgba(61, 174, 109, 1);
          background: linear-gradient(
            90deg,
            rgba(244, 255, 249, 0) 0%,
            rgba(248, 253, 250, 1) 100%
          );
        }
        .h {
          border-bottom: 1px solid rgba(61, 174, 109, 0.1);
        }
        .f {
          border-right: 2px solid rgba(61, 174, 109, 0.1);
        }
      }
      .allzyywright {
        width: 48vw;
        height: 100%;
        .title1 {
          display: flex;
          height: 5vh;
          .left {
            margin-top: 1.45vh;
            margin-left: 2.5vw;
            width: 0.7vh;
            height: 2.1vh;
            border-radius: 5px;
            background: rgba(61, 174, 109, 1);
          }
          .title {
            margin-left: 1vw;
            line-height: 5vh;
            color: rgba(51, 51, 51, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 2.5vh;
          }
          .big {
            margin-left: 0.5vw;
            margin-top: 2.05vh;
            width: 0.9vh;
            height: 0.9vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
          .middle {
            margin-left: 0.5vw;
            margin-top: 2.2vh;
            width: 0.6vh;
            height: 0.6vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
          .small {
            margin-left: 0.5vw;
            margin-top: 2.35vh;
            width: 0.3vh;
            height: 0.3vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
        }
        .title2 {
          display: flex;
          height: 5vh;
          .left {
            margin-top: 1.45vh;
            margin-left: 2.5vw;
            width: 0.7vh;
            height: 2.1vh;
            border-radius: 5px;
            background: rgba(61, 174, 109, 1);
          }
          .title {
            margin-left: 1vw;
            line-height: 5vh;
            color: rgba(51, 51, 51, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 2.5vh;
          }
          .big {
            margin-left: 0.5vw;
            margin-top: 2.05vh;
            width: 0.9vh;
            height: 0.9vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
          .middle {
            margin-left: 0.5vw;
            margin-top: 2.2vh;
            width: 0.6vh;
            height: 0.6vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
          .small {
            margin-left: 0.5vw;
            margin-top: 2.35vh;
            width: 0.3vh;
            height: 0.3vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
        }
        .content1 {
          margin-top: 2vh;
          width: 42vw;
          margin-left: 3vw;
          color: rgba(102, 102, 102, 1);
          font-family: Microsoft YaHei;
          font-size: 1.7vh;
          line-height: 3.4vh;
          margin-bottom: 3vh;
        }
        .contentimg {
          width: 42vw;
          margin-top: 2vh;
          margin-left: 3vw;
          margin-bottom: 3vh;
        }
        .flex {
          display: flex;
          width: 42vw;
          margin-left: 3vw;
          padding-top: 2vh;
          padding-bottom: 2vh;
          border-radius: 10px;
          // background: rgba(246, 246, 246, 1);

          img {
            width: 13vw;
            margin-left: 0.75vw;
          }
        }
      }
    }
  }
}
</style>
