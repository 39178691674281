<template>
  <div class="body">
    <xwzxtop></xwzxtop>
    <div class="xwxq">
      <div class="allzyywright">
        <div class="headtop">
          <div class="green" @click="NewsInformation">新闻资讯</div>
          <div class="middle">></div>
          <div class="black">新闻详情</div>
        </div>
        <div class="xqcontent" id="xm1" name="xm1">
          <div class="title">什么是楼宇自控系统?</div>
          <div class="time">时间：2021-09-12</div>
          <div class="p1">
            大家对楼宇自控系统了解吗，楼宇自控系统是什么呢，对于做工程的人来说，楼宇自控肯定非常的熟悉。其实楼宇自控系统是楼宇自动化系统的简称，是智能建筑中不可缺少的重要组成部分之一，它的特征是“集中管理分散控制”，主要是对整个楼宇建筑的所有公用机电设备，楼宇自控系统包括中央空调系统、给排水系统、供配电系统、照明系统、电梯系统等，进行优化及自动化控制管理，从而降低设备故障率，减少维护及营运的成本。楼宇自控最终目标是为了给建筑使用者提供一个更高效、安全、快捷、舒适、经济的生活环境。下面就具体说说什么是国产楼宇自控系统：
          </div>
          <div class="title1">
            <div class="left"></div>
            <div class="titles">楼宇自控系统</div>
            <div class="big"></div>
            <div class="middle"></div>
            <div class="small"></div>
          </div>
          <div class="litflex">
            <div class="yd"></div>
            中央空调系统
          </div>
          <p class="content1">
            供热、通风及空调系统为建筑物内提供了一个舒适的环境，是BAS中的一个重要子系统。系统为建筑物内的机电设备（如：冷却塔、冷水机组、空气处理机、气控设备等）提供一个最优化的控制。其基本控制功能包括：设备控制、循环控制、最佳起/停控制、数学功能、逻辑功能、趋势运行记录、报警管理等。
          </p>
          <div class="litflex">
            <div class="yd"></div>
            给排水系统
          </div>
          <p class="content1">
            给排水系统主要是对于饮用水的提供，以及对于污水的排放。
          </p>
          <div class="litflex">
            <div class="yd"></div>
            供配电系统
          </div>
          <p class="content1">
            供配电系统是通过BAS的管理中心提供对于建筑物内的高低配电房及所有变配电设备的监视报警和管理及程序控制，提供对于重要电气设备的控制程序、时间程序和相应的联动程序。
          </p>
          <div class="litflex">
            <div class="yd"></div>
            照明系统
          </div>
          <p class="content1">
            照明系统主要是对照明实施监控，更好地节约能源。利用预先安排好的时间程序对照明进行自动控制。
          </p>
          <div class="litflex">
            <div class="yd"></div>
            电梯系统
          </div>
          <p class="content1">
            电梯控制系统是通过BAS系统对于建筑物内的多台电梯，实行集中的控制和管理程序，同时配合BAS系统的部分子系统，执行联动程序。
          </p>
          <img src="./../assets/官网补充/官网补充/img_新闻03.png" alt="" />
        </div>
      </div>
    </div>
    <under></under>
  </div>
</template>

<script>
import under from "../components/under.vue";
import xwzxtop from "../components/xwzxtop.vue";
export default {
  components: { under, xwzxtop },
  data: function () {
    return {};
  },
  created() {},
  methods: {
    NewsInformation() {
      this.$router.push("/NewsInformation");
    },
  },
};
</script>
<style lang="less" scoped>
.body {
  width: 100%;
  .xwxq {
    width: 100vw;
    padding-bottom: 5vh;
    background: rgba(244, 245, 246, 1);
    border: 1px solid rgba(244, 245, 246, 1);
    .allzyywright {
      width: 60vw;
      margin-top: 5vh;
      margin-left: 20vw;

      .headtop {
        width: 44vw;
        height: 5vh;
        display: flex;
        line-height: 5vh;
        .green {
          margin-left: 1vw;
          color: rgba(61, 174, 109, 1);
          font-family: Microsoft YaHei;
          font-size: 1vw;
           cursor: pointer;
        }
        .middle {
          color: rgba(51, 51, 51, 1);
          font-family: Microsoft YaHei;
          font-size: 1vw;
          margin-left: 0.5vw;
        }
        .black {
          color: rgba(51, 51, 51, 1);
          font-family: Microsoft YaHei;
          font-size: 1vw;
          margin-left: 0.5vw;
        }
      }
      .xqcontent {
        margin-top: 3vh;
        width: 60vw;
        padding-bottom: 5vh;
        border-radius: 10px;
        background: rgba(255, 255, 255, 1);
        .title {
          padding-top: 1vh;
          margin-left: 1.5vw;
          color: rgba(51, 51, 51, 1);
          font-family: Microsoft YaHei;
          font-weight: bold;
          font-size: 1.5vw;
          line-height: 6vh;
        }
        .time {
          margin-left: 1.5vw;
          color: rgba(153, 153, 153, 1);
          font-family: Microsoft YaHei;
          font-size: 0.7vw;
        }
        .p1 {
          margin-top: 5vh;
          width: 57vw;
          margin-left: 1.5vw;
          // color: rgba(51, 51, 51, 1);
           color: rgba(102, 102, 102, 1);
          font-family: Microsoft YaHei;
          font-size: 0.85vw;
          line-height: 3.4vh;
        }
         .title1 {
          display: flex;
          height: 5vh;
           margin-top: 2vh;
          margin-bottom: 2vh;
          .left {
            margin-top: 1.45vh;
            margin-left: 2.5vw;
            width: 0.7vh;
            height: 2.1vh;
            border-radius: 5px;
            background: rgba(61, 174, 109, 1);
          }
          .titles {
            margin-left: 1vw;
            line-height: 5vh;
            color: rgba(51, 51, 51, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 2.5vh;
          }
          .big {
            margin-left: 0.5vw;
            margin-top: 2.05vh;
            width: 0.9vh;
            height: 0.9vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
          .middle {
            margin-left: 0.5vw;
            margin-top: 2.2vh;
            width: 0.6vh;
            height: 0.6vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
          .small {
            margin-left: 0.5vw;
            margin-top: 2.35vh;
            width: 0.3vh;
            height: 0.3vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
        }
        .litflex {
          height: 5vh;
          display: flex;
          font-size: 0.9vw;
          margin-left: 1.5vw;
          color: rgba(51, 51, 51, 1);
          font-family: Microsoft YaHei;
          line-height: 5vh;
          font-weight: bold;
          .yd {
            width: 0.5vh;
            height: 0.5vh;
            margin-top: 2.25vh;
            border-radius: 50%;
            margin-right: 0.5vw;
            background: rgba(61, 174, 109, 1);
          }
        }
        .content1 {
          margin-top: 1vh;
          width: 56vw;
          margin-left: 2.5vw;
          color: rgba(102, 102, 102, 1);
          font-family: Microsoft YaHei;
          font-size: 0.85vw;
          line-height: 3.4vh;
          margin-bottom: 2vh;
        }
        img {
          margin-top: 3vh;
          width: 57vw;
          margin-left: 1.5vw;
        }
      }
    }
  }
}
</style>
