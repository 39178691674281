<template>
  <div class="body">
    <xwzxtop></xwzxtop>
    <div class="xwxq">
      <div class="allzyywright">
        <div class="headtop">
          <div class="green" @click="NewsInformation">新闻资讯</div>
          <div class="middle">></div>
          <div class="black">新闻详情</div>
        </div>
        <div class="xqcontent">
          <div class="title">合同能源管理模式详解</div>
          <div class="time">时间：2022-01-25</div>
          <div class="ct">
            合同能源管理是20世纪70年代中期以来，在美国、加拿大等市场经济国家中逐步发展起来的一种基于市场的、全新的节能新机制。合同能源管理机制的实质是一种以减少的能源费用来支付节能项目全部成本的节能投资方式，这样的节能投资方式使得客户可以用未来的节能收益为工厂和设备升级，同时降低当前的运行成本。
          </div>
          <div class="ct">
            基于合同能源管理机制运作、以盈利为直接目的的专业化公司，称之为节能服务公司（EnergyServiceCompany），国外简称为ESCO，国内通常简称为EMC或EMCo。ESCO与愿意进行节能改造的客户签订节能服务合同，向客户提供能效诊断、可行性研究、项目设计、项目融资、设备和材料采购、工程施工、人员培训、节能量监测、改造系统的运行、维护和管理等服务，并通过与客户分享项目实施后产生的节能效益、或承诺节能项目的节能效益、或承包整体能源费用的方式为客户提供节能服务，并获得利润，滚动发展。
          </div>
          <div class="ct">
            根据客户与ESCO各自承担的责任和义务、ESCO提供的服务内容以及客户向ESCO付款方式的不同，合同能源管理融资模式可以划分为节能效益分享型、节能量保证型和能源费用托管型三种
          </div>
          <div class="title1">
            <div class="left"></div>
            <div class="titles">按ESCO融资模式分：</div>
            <div class="big"></div>
            <div class="middle"></div>
            <div class="small"></div>
          </div>
          <div class="litflex">
            <div class="yd"></div>
            节能量保证型
          </div>
          <p class="content1">
            节能量保证支付型是在企业项目合同期间,ESCO对企业节能量的承诺,主要是指工程成本如果达不到所承诺的节能量部分,其成本由ESCO全部承担,但是如果工程成本超过所承诺的节能量部分,其超过部分由两方共同享有,直至项目合同结束后,ESCO将所有的节能设备全部移交给企业进行使用,此种情况下企业将享受劝募的节能效益。由于节能量保证支付型需要企业诚信度高,因此此种模式只适用于诚信度高、节能意识差的企业中。
          </p>
          <div class="litflex">
            <div class="yd"></div>
            节能效益分享型
          </div>
          <p class="content1">
            节能效益分享型主要是用于企业在进行节能改造过程中,在经过ESCO与企业双方一同保证效率后,企业和ESCO需要严格按照双方的比例进行分享节能效益。一般情况下,企业在进行节能效益分享过程中,需要根据合同期内双方分享节能效益将节能设备交给企业进行使用,此种模式下,企业享受全部的经济效益。
          </p>
          <div class="litflex">
            <div class="yd"></div>
            能源费用托管型
          </div>
          <p class="content1">
            能源费用托管型是指由ESCO负责企业内部设备改造,提高企业设备运行模式,降低设备在运行过程中所出现的能源高消耗情况。在能源费用托管中,ESCO会向客户提供一个具体的改造服务,并承担在改造过程中的所有运行费用,为用户实施节能改造过程中提高节能效果。与此同时,在进行能源费用托管过程中,双方的经济利益主要来自能源管理水平和节能改造过程中所出现的节能效益。在改在过程中,如果ESCO没有实现签订合同之前所承诺的事情,就需要对客户进行赔偿。
          </p>
          <div class="title1">
            <div class="left"></div>
            <div class="titles">按EMCO公司资源依托划分：</div>
            <div class="big"></div>
            <div class="middle"></div>
            <div class="small"></div>
          </div>
          <div class="ct1">
            针对采用合同能源管理模式实施节能项目的企业在整体上保持着相似特征，但是在公司细化经营方面又存在着巨大的差异，根据其依托的关键资源差异可以做以下三种划分。
          </div>
          <div class="litflex">
            <div class="yd"></div>
            资金依托型
          </div>
          <p class="content1">
            资金依托型公司经营的特征是以市场需求为导向，利用资金优势整合需要的节能技术和节能产品，实施节能项目。这些公司不拘泥于专一的节能技术和产品，具有较大的灵活性，市场跨度大，辐射能力强，能够在不同行业实施多种技术类型的节能项目。
          </p>
          <div class="litflex">
            <div class="yd"></div>
            技术依托型
          </div>
          <p class="content1">
            技术依托型公司以某种节能技术或者节能产品为基础发展起来的节能服务公司，节能技术或者节能产品是公司的核心竞争力。利用公司在技术和产品方面的优势，开拓市场，逐步完成资本原始积累，并不断寻求新的融资渠道，获得更大的市场份额。这类公司大多拥有自主知识产权，技术风险可控，项目收益较高；目标市场定位明确，有利于在某一特定行业形成竞争力。
          </p>
          <div class="litflex">
            <div class="yd"></div>
            市场依托型
          </div>
          <p class="content1">
            市场依托型企业拥有特定行业的客户资源优势。通过整合相应的节能技术和节能产品，实施节能项目。这类EMCo开发市场的成本较低，由于与客户的深度认知，来自客户端的风险较小，有利于建立长期合作关系，并获得客户对节能项目的直接融资。
          </p>
          <div class="title1">
            <div class="left"></div>
            <div class="titles">按EMCO公司主营方向划分：</div>
            <div class="big"></div>
            <div class="middle"></div>
            <div class="small"></div>
          </div>
          <div class="ct1">可以分为技术产品推广型和综合服务型。</div>
          <div class="litflex">
            <div class="yd"></div>
            技术（产品）推广型
          </div>
          <p class="content1">
            技术（产品）推广型企业技术特长各不一样，主要以从事建筑采暖、空调、照明、电梯、蓄冷蓄热、地源热泵以及建筑物外围护结构等各项技术和产品开发或服务为主，企业间相互间具有极强的互补性。
          </p>
          <div class="litflex">
            <div class="yd"></div>
            综合服务型
          </div>
          <p class="content1">
            综合服务型企业是指企业不仅向其客户提供节能技术或产品，而且从日常系统运行维护、用能行为管理、设备保养等方面为客户提供咨询、方案设计，帮助客户实现减少能源消耗目标的服务模式。
          </p>
          <div class="ct">
            合同能源管理模式为缓解业主资金压力、确保最终节能效果提供了不错的解决方案。然而，该机制在我国建筑领域的实践效果并不理想，呈现出实施效果差、纠纷多等问题。这些问题集中体现于合同上所约定的节能量计算上。许多合同文本中关于节能的表述存在边界模糊、计算标准不明确、能耗因素考虑不全面等缺陷，导致最后核验是否实现节能效果时的业主与节能公司各执一词。
          </div>
          <div class="ct">
            合同能源管理项目之所以乱象丛生，一方面在于一些甲、乙方的诚信缺失，例如节能服务公司在合同中刻意将节能效果模糊表述，以便获取商业价值。另一方面则可归因于节能量的测量验证难度。作为项目结算的重要依据，节能量并不是直接测量得到的，而是通过间接计算得到的。在计算节能量时，边界条件、工况条件的微小偏差就容易带来节能效果的巨大变化。2012年，国家标准《节能量测量和验证技术通则》（GB/T
            28750-2012）颁布并实施，各种典型节能改造场景对应的国家标准随后相继颁布。但是，这些标准仅对节能量的确定方法做了一般性、概括性的指导，缺乏足够细致的表述，而实际的节能项目存在多种多样的具体情况，仅靠国家标准很难杜绝节能量计算方法偏差带来的节能收益结算纠纷。
          </div>
          <img
            src="./../assets/官网切图汇总/官网切图补充/新闻资讯_07.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <under></under>
  </div>
</template>

<script>
import under from "../components/under.vue";
import xwzxtop from "../components/xwzxtop.vue";
export default {
  components: { under, xwzxtop },
  data: function () {
    return {};
  },

  created() {},
  methods: {
    NewsInformation() {
      this.$router.push("/NewsInformation");
    },
  },
};
</script>

<style lang="less" scoped>
.body {
  width: 100%;
  .xwxq {
    width: 100vw;
    padding-bottom: 5vh;
    background: rgba(244, 245, 246, 1);
    border: 1px solid rgba(244, 245, 246, 1);
    .allzyywright {
      width: 60vw;
      margin-top: 5vh;
      margin-left: 20vw;
      .headtop {
        width: 44vw;
        height: 5vh;
        display: flex;
        line-height: 5vh;
        .green {
          margin-left: 1vw;
          color: rgba(61, 174, 109, 1);
          font-family: Microsoft YaHei;
          font-size: 1vw;
          cursor: pointer;
        }
        .middle {
          color: rgba(51, 51, 51, 1);
          font-family: Microsoft YaHei;
          font-size: 1vw;
          margin-left: 0.5vw;
        }
        .black {
          color: rgba(51, 51, 51, 1);
          font-family: Microsoft YaHei;
          font-size: 1vw;
          margin-left: 0.5vw;
        }
      }
      .xqcontent {
        margin-top: 3vh;
        width: 60vw;
        padding-bottom: 5vh;
        border-radius: 10px;
        background: rgba(255, 255, 255, 1);
        .title {
          padding-top: 1vh;
          margin-left: 1.5vw;
          color: rgba(51, 51, 51, 1);
          font-family: Microsoft YaHei;
          font-weight: bold;
          font-size: 1.5vw;
          line-height: 6vh;
        }
        .time {
          margin-left: 1.5vw;
          color: rgba(153, 153, 153, 1);
          font-family: Microsoft YaHei;
          font-size: 0.7vw;
        }
        .title1 {
          display: flex;
          height: 5vh;
           margin-top: 2vh;
          margin-bottom: 2vh;
          .left {
            margin-top: 1.45vh;
            margin-left: 2.5vw;
            width: 0.7vh;
            height: 2.1vh;
            border-radius: 5px;
            background: rgba(61, 174, 109, 1);
          }
          .titles {
            margin-left: 1vw;
            line-height: 5vh;
            color: rgba(51, 51, 51, 1);
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 2.5vh;
          }
          .big {
            margin-left: 0.5vw;
            margin-top: 2.05vh;
            width: 0.9vh;
            height: 0.9vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
          .middle {
            margin-left: 0.5vw;
            margin-top: 2.2vh;
            width: 0.6vh;
            height: 0.6vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
          .small {
            margin-left: 0.5vw;
            margin-top: 2.35vh;
            width: 0.3vh;
            height: 0.3vh;
            background: rgba(61, 174, 109, 1);
            border-radius: 50%;
          }
        }
        .litflex {
          height: 5vh;
          display: flex;
          font-size: 0.9vw;
          margin-left: 1.5vw;
          color: rgba(51, 51, 51, 1);
          font-family: Microsoft YaHei;
          line-height: 5vh;
          font-weight: bold;
          .yd {
            width: 0.5vh;
            height: 0.5vh;
            margin-top: 2.25vh;
            border-radius: 50%;
            margin-right: 0.5vw;
            background: rgba(61, 174, 109, 1);
          }
        }
        .content1 {
          margin-top: 1vh;
          width: 56vw;
          margin-left: 2.5vw;
          color: rgba(102, 102, 102, 1);
          font-family: Microsoft YaHei;
          font-size: 0.85vw;
          line-height: 3.4vh;
          margin-bottom: 2vh;
        }
        .ct {
          margin-top: 5vh;
          width: 57vw;
          color: rgba(102, 102, 102, 1);
          font-family: Microsoft YaHei;
          font-size: 0.85vw;
          margin-left: 1.5vw;
          line-height: 3.4vh;
          text-align: justify;
        }
        .ct1 {
          margin-top: 5vh;
          margin-bottom: 5vh;
          width: 57vw;
          color: rgba(102, 102, 102, 1);
          font-family: Microsoft YaHei;
          font-size: 0.85vw;
          margin-left: 1.5vw;
          line-height: 3.4vh;
          text-align: justify;
        }
        img {
          margin-top: 4vh;
          width: 57vw;
          margin-left: 1.5vw;
        }
      }
    }
  }
}
</style>
