<template>
  <div class="body">
    <div class="top">
      <img src="./../assets/枢源LOGO02.svg" alt="" class="gslogo" />

      <div class="type_item typeActive">
        首页
        <div class="typeActive1"></div>
      </div>
      <div class="type_item type_item1" @click="AboutUs">
        关于枢源
        <div></div>
      </div>
      <div class="type_item type_item1" @click="MainBusiness">
        主营业务
        <div></div>
      </div>

      <div class="type_item type_item1" @click="InoustaySolutions">
        行业解决方案
        <div></div>
      </div>

      <div class="type_item type_item1" @click="ServiceCase">
        服务案例
        <div></div>
      </div>

      <div class="type_item type_item1" @click="NewsInformation">
        新闻资讯
        <div></div>
      </div>

      <div class="type_item type_item1" @click="ContactUs">
        联系我们
        <div></div>
      </div>
      <div class="a">
        <div class="ab">服务热线：021-64026001</div>
      </div>
    </div>

    <el-carousel trigger="click" height="41.67vw" class="lub">
      <el-carousel-item v-for="item of imgList" :key="item.id" class="lubevery">
        <img :src="item.src" alt="" class="lubeveryimg" />
      </el-carousel-item>
    </el-carousel>
    <div class="jzny">
      <div class="title">
        <img
          src="./../assets/官网切图2.0/官网切图2.0/标题_左.svg"
          alt=""
          class="titleleft"
        />
        <span>智 控 系 统 解 决 方 案 服 务 商</span
        ><img
          src="./../assets/官网切图2.0/官网切图2.0/标题_右.svg"
          alt=""
          class="titleright"
        />
      </div>
      <!-- <div class="titlea">
        <img src="./../assets/520/标题英文_Total soulution.svg" alt="" />
      </div> -->
      <div class="alltitle">
        <div class="a" @mouseenter="aaa">
          <img
            v-if="title1"
            src="./../assets/官网切图2.0/官网切图2.0/解决方案_BMS_绿.svg"
            alt=""
          />
          <img
            v-else
            src="./../assets/官网切图2.0/官网切图2.0/解决方案_BMS_灰.svg"
            alt=""
          />
          <div :class="title1 == true ? 'green' : 'black'">BMS系统</div>
          <div class="c" v-if="title1"></div>
        </div>
        <div class="b"></div>
        <div class="a" @mouseenter="bbb">
          <img
            v-if="title2"
            src="./../assets/官网切图2.0/官网切图2.0/解决方案_EMS_绿.svg"
            alt=""
          />
          <img
            v-else
            src="./../assets/官网切图2.0/官网切图2.0/解决方案_EMS_灰.svg"
            alt=""
          />
          <div :class="title2 == true ? 'green' : 'black'">EMS系统</div>
          <div class="c" v-if="title2"></div>
        </div>
        <div class="b"></div>
        <div class="a" @mouseenter="ccc">
          <img
            v-if="title3"
            src="./../assets/官网切图2.0/官网切图2.0/解决方案_能源_绿.svg"
            alt=""
          />
          <img
            v-else
            src="./../assets/官网切图2.0/官网切图2.0/解决方案_能源_灰.svg"
            alt=""
          />
          <div :class="title3 == true ? 'green' : 'black'">能源管理系统</div>
          <div class="c" v-if="title3"></div>
        </div>
        <div class="b"></div>
        <div class="a" @mouseenter="ddd">
          <img
            v-if="title4"
            src="./../assets/官网切图0430/官网切图0430/解决方案_定制_绿.svg"
            alt=""
          />
          <img
            v-else
            src="./../assets/官网切图0430/官网切图0430/解决方案_定制_灰.svg"
            alt=""
          />
          <div :class="title4 == true ? 'green' : 'black'">软件平台产品</div>
          <div class="c" v-if="title4"></div>
        </div>
        <div class="b"></div>
        <div class="a" @mouseenter="eee">
          <img
            v-if="title5"
            src="./../assets/官网切图2.0/官网切图2.0/解决方案_节能_绿.svg"
            alt=""
          />
          <img
            v-else
            src="./../assets/官网切图2.0/官网切图2.0/解决方案_节能_灰.svg"
            alt=""
          />
          <div :class="title5 == true ? 'green' : 'black'">系统节能改造</div>
          <div class="c" v-if="title5"></div>
        </div>
        <div class="b"></div>
        <div class="a" @mouseenter="fff">
          <img
            v-if="title6"
            src="./../assets/官网切图2.0/官网切图2.0/解决方案_动力_绿.svg"
            alt=""
          />
          <img
            v-else
            src="./../assets/官网切图2.0/官网切图2.0/解决方案_动力_灰.svg"
            alt=""
          />
          <div :class="title6 == true ? 'green' : 'black'">能源系统集成</div>
          <div class="c" v-if="title6"></div>
        </div>
      </div>
      <div class="content1" v-if="title1" @click="MainBusiness">
        <div class="contenta">
          <div class="contenta1">
            <img
              src="./../assets/官网icon补充/官网icon补充/解决方案_BMS_白.svg"
              alt=""
            />
            <span>BMS系统</span>
          </div>
          <p class="contenta2">
            Building Management System
            即建筑管理系统，利用先进的物联网络及控制器技术，实现工厂等各类型建筑的暖通、动力、电力、工艺等子系统的集中管理监控以及数据的集中存储应用。
          </p>
        </div>
      </div>
      <div class="content2" v-if="title2" @click="MainBusiness">
        <div class="contenta">
          <div class="contenta1">
            <img
              src="./../assets/官网icon补充/官网icon补充/解决方案_EMS_白.svg"
              alt=""
            />
            <span>EMS系统</span>
          </div>
          <p class="contenta2">
            Environment Management System
            即环境管理系统，高性能、高精度的室内环境传感器设备能够快速准确的采集生产区域温湿度、压差等关键环境数据。并能在环境管理平台上进行数据的存储、展示、分析和查询追溯。
          </p>
        </div>
      </div>
      <div class="content3" v-if="title3" @click="MainBusiness">
        <div class="contenta">
          <div class="contenta1">
            <img
              src="./../assets/官网icon补充/官网icon补充/解决方案_能源_白.svg"
              alt=""
            />
            <span>能源管理系统</span>
          </div>
          <p class="contenta2">
            功能主要包含：能源数据采集、能源监控、能源预警、能源分析、能效分析、能源计划、能源调度等功能，建立能源管理系统、考核指标，利用物联网、大数据等技术手段，持续帮助企业实现绿色、环保、节能的业务目标。
          </p>
        </div>
      </div>
      <div class="content4" v-if="title4" @click="MainSoftware">
        <div class="contenta">
          <div class="contenta1">
            <img
              src="./../assets/官网切图0430/官网切图0430/解决方案_定制_白.svg"
              alt=""
            />
            <span>软件平台产品</span>
          </div>
          <p class="contenta2">
            采集排水系统全感知数据，结合大数据分析以及人工智能算法，建立多维度数据分析预测模型，通过运行方案动态仿真以及自动策略寻优，快速提前制定科学、安全、环保的运行管理方案，实现泵站调度管理数字化。
          </p>
        </div>
      </div>
      <div class="content5" v-if="title5" @click="MainSystem">
        <div class="contenta">
          <div class="contenta1">
            <img
              src="./../assets/官网icon补充/官网icon补充/解决方案_节能_白.svg"
              alt=""
            />
            <span>系统节能改造</span>
          </div>
          <p class="contenta2">
            全面提升能源的利用效率和智能化水平，构建智慧节能系统数据采集、边缘计算，反向控制、数据分析、策略优化、策略下发和能源预测等功能，通过节能策略的执行和控制，大数据挖掘建模，专家团队远程分析指导，实现能源控制、管理、运维一体化平台。
          </p>
        </div>
      </div>
      <div class="content6" v-if="title6" @click="MainEnergy">
        <div class="contenta">
          <div class="contenta1">
            <img
              src="./../assets/官网icon补充/官网icon补充/解决方案_动力_白.svg"
              alt=""
            />
            <span>能源系统集成</span>
          </div>
          <p class="contenta2">
            暖通、动力系统的设计、选型、供货、施工安装及调试验收，提供BIM正向设计+施工整体服务，通过完善的全生命周期施工管理体系，能够帮助企业精准、快速、高质量的完成工程建设项目。
          </p>
        </div>
      </div>
    </div>
    <div class="hyyy">
      <div class="title">
        <img
          src="./../assets/官网切图2.0/官网切图2.0/标题_左.svg"
          alt=""
          class="titleleft"
        />
        <span>行 业 运 用 及 解 决 方 案</span
        ><img
          src="./../assets/官网切图2.0/官网切图2.0/标题_右.svg"
          alt=""
          class="titleright"
        />
      </div>
      <div class="titlea">
        <img
          src="./../assets/官网切图2.0/官网切图2.0/标题英文_行业运用及解决方案.svg"
          alt=""
        />
      </div>
      <div class="allhyyy">
        <div class="n" @click="InoustaySolutions">
          <div class="a">
            <div class="aa"></div>
            <div class="b">
              <div class="c">
                <img
                  src="./../assets/官网切图2.0/官网切图2.0/行业应用_生物制药.svg"
                  alt=""
                />
                <div>生物制药</div>
              </div>
              <div class="d">
                <div class="first">
                  <div class="dian"></div>
                  <div class="content">设备运行监控（BMS）</div>
                </div>
                <div class="second">
                  <div class="dian"></div>
                  <div class="content">生产环境监控（EMS）</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="n" @click="AutomobileManufacturing">
          <div class="a">
            <div class="ba"></div>
            <div class="b">
              <div class="c">
                <img
                  src="./../assets/官网切图2.0/官网切图2.0/行业应用_新能源汽车.svg"
                  alt=""
                />
                <div>汽车制造</div>
              </div>
              <div class="d">
                <div class="first">
                  <div class="dian"></div>
                  <div class="content">设备智能监管平台</div>
                </div>
                <div class="second">
                  <div class="dian"></div>
                  <div class="content">能源智慧管理系统</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="n" @click="CommercialBuildings">
          <div class="a">
            <div class="ea"></div>
            <div class="b">
              <div class="c">
                <img
                  src="./../assets/官网切图2.0/官网切图2.0/行业应用_数据中心.svg"
                  alt=""
                />
                <div>半导体制造</div>
              </div>
              <div class="d">
                <div class="first">
                  <div class="dian"></div>
                  <div class="content">BMS系统集成</div>
                </div>
                <div class="second">
                  <div class="dian"></div>
                  <div class="content">设备运行监控</div>
                </div>
                <div class="second">
                  <div class="dian"></div>
                  <div class="content">FMCS厂务系统</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="n" @click="DataCenter">
          <div class="a">
            <div class="da"></div>
            <div class="b">
              <div class="c">
                <img src="./../assets/519/数据中心.svg" alt="" />
                <div>数据中心</div>
              </div>
              <div class="d">
                <div class="first">
                  <div class="dian"></div>
                  <div class="content">智能环控系统</div>
                </div>
                <div class="second">
                  <div class="dian"></div>
                  <div class="content">智能配电系统</div>
                </div>
                <div class="second">
                  <div class="dian"></div>
                  <div class="content">智慧运维管理</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="n" @click="UrbanDrainage">
          <div class="a">
            <div class="ca"></div>
            <div class="b">
              <div class="c">
                <img
                  src="./../assets/官网切图2.0/官网切图2.0/行业应用_城市排水.svg"
                  alt=""
                />
                <div>城市排水</div>
              </div>
              <div class="d">
                <div class="first">
                  <div class="dian"></div>
                  <div class="content">智慧污水管控平台</div>
                </div>
                <div class="second">
                  <div class="dian"></div>
                  <div class="content">排水智慧调度平台</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="n" @click="CommercialBuildings">
          <div class="a">
            <div class="fa"></div>
            <div class="b">
              <div class="c">
                <img
                  src="./../assets/官网切图2.0/官网切图2.0/行业应用_商业办公.svg"
                  alt=""
                />
                <div>商业办公</div>
              </div>
              <div class="d">
                <div class="first">
                  <div class="dian"></div>
                  <div class="content">楼宇控制系统</div>
                </div>
                <div class="second">
                  <div class="dian"></div>
                  <div class="content">能源管理系统</div>
                </div>
                <div class="second">
                  <div class="dian"></div>
                  <div class="content">运维管控平台</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ryzz">
      <div class="title">
        <img
          src="./../assets/官网切图2.0/官网切图2.0/标题_左.svg"
          alt=""
          class="titleleft"
        />
        <span>荣 誉 资 质</span
        ><img
          src="./../assets/官网切图2.0/官网切图2.0/标题_右.svg"
          alt=""
          class="titleright"
        />
      </div>
      <div class="titlea">
        <img
          src="./../assets/官网切图2.0/官网切图2.0/标题英文_荣誉资质.svg"
          alt=""
        />
      </div>
      <el-carousel type="card" trigger="click" height="40vh" class="ryzzlub">
        <el-carousel-item
          v-for="item of recommendList"
          :key="item.id"
          class="lubimg"
        >
          <img :src="item.url" alt="" class="img" />
          <div class="label">{{ item.text }}</div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="xwzx">
      <div class="title">
        <img
          src="./../assets/官网切图2.0/官网切图2.0/标题_左.svg"
          alt=""
          class="titleleft"
        />
        <span>新 闻 资 讯</span
        ><img
          src="./../assets/官网切图2.0/官网切图2.0/标题_右.svg"
          alt=""
          class="titleright"
        />
      </div>
      <div class="titlea">
        <img
          src="./../assets/官网切图2.0/官网切图2.0/标题英文_新闻资讯.svg"
          alt=""
        />
      </div>
      <div class="allxwzx">
        <div class="xwzximg"></div>
        <div class="xwzxlabel">
          <div class="label" @click="NewsDetail3">
            <div class="a">
              <div class="c">13</div>
              <div class="d">2021.12</div>
            </div>
            <div class="e"></div>
            <div class="b">
              <div class="h">为节能减排做出贡献，楼宇自控系统比例巨大！</div>
            </div>
          </div>
          <div class="label" @click="NewsDetail2">
            <div class="a">
              <div class="c">25</div>
              <div class="d">2022.01</div>
            </div>
            <div class="e"></div>
            <div class="b">
              <div class="h">合同能源管理模式详解</div>
              <div class="i">
                合同能源管理是20世纪70年代中期以来，在美国、加拿大等市场经济国家中逐步发展起来的一种基于市场的、全新的节能新机制。
              </div>
            </div>
          </div>
          <div class="label" @click="NewsDetail1">
            <div class="a">
              <div class="c">04</div>
              <div class="d">2022.02</div>
            </div>
            <div class="e"></div>
            <div class="b">
              <div class="h">赋能建筑业实现低碳转型</div>
              <div class="i">
                零碳建筑是以人、建筑和自然环境的协调发展为目标，在利用天然条件
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hzqy">
      <div class="title">
        <img
          src="./../assets/官网切图2.0/官网切图2.0/标题_左.svg"
          alt=""
          class="titleleft"
        />
        <span>合 作 企 业</span
        ><img
          src="./../assets/官网切图2.0/官网切图2.0/标题_右.svg"
          alt=""
          class="titleright"
        />
      </div>
      <div class="titlea">
        <img
          src="./../assets/官网补充切图2/官网补充切图/首页_enterprise.svg"
          alt=""
        />
      </div>
      <img
        src="./../assets/官网补充切图/官网补充切图/合作企业.png"
        alt=""
        class="gslg"
      />
    </div>
    <under></under>
  </div>
</template>

<script>
import under from "./../components/under.vue";
export default {
  components: {
    under,
  },
  data: function () {
    return {
      recommendList: [
        {
          id: 1,
          url: require("./../assets/轮播/荣誉证书07_高新技术企业证书.png"),
          text: "高新技术企业证书",
        },
        {
          id: 2,
          url: require("./../assets/轮播/荣誉证书04_杨浦区专精特新.png"),
          text: "杨浦区专精特新",
        },
        {
          id: 3,
          url: require("./../assets/轮播/荣誉证书08_软件产品证书.png"),
          text: "软件产品证书",
        },
        {
          id: 4,
          url: require("./../assets/轮播/荣誉证书05_杨浦区博士后创新实践基地.png"),
          text: "杨浦区博士后创新实践基地",
        },
        {
          id: 5,
          url: require("./../assets/轮播/荣誉证书09_科创基金会2019全球创业周雏鹰奖.png"),
          text: "科创基金会2019全球创业周雏鹰奖",
        },
        {
          id: 6,
          url: require("./../assets/轮播/荣誉证书01_企业信用等级证书.png"),
          text: "企业信用等级证书",
        },
        {
          id: 7,
          url: require("./../assets/轮播/荣誉证书02_企业资信等级证书.png"),
          text: "企业资信等级证书",
        },
        {
          id: 8,
          url: require("./../assets/轮播/荣誉证书03_第六届互联网大赛全国铜奖.png"),
          text: "第六届互联网+大赛全国铜奖",
        },
        {
          id: 9,
          url: require("./../assets/轮播/荣誉证书10_质量管理体系认证证书.png"),
          text: "质量管理体系认证证书",
        },
        {
          id: 10,
          url: require("./../assets/轮播/荣誉证书11_职业健康安全管理体系认证证书.png"),
          text: "职业健康安全管理体系认证证书",
        },
        {
          id: 11,
          url: require("./../assets/轮播/荣誉证书12_环境管理体系认证证书.png"),
          text: "环境管理体系认证证书",
        },
        {
          id: 12,
          url: require("./../assets/轮播/荣誉证书06_杨浦区科技小巨人.png"),
          text: "杨浦区科技小巨人",
        },
      ],
      a: 0,
      weiyi: 0,
      imgList: [
        {
          id: 1,
          src: require("./../assets/520/top_banner01.png"),
        },
        {
          id: 2,
          src: require("./../assets/520/top_banner02.png"),
        },
        {
          id: 3,
          src: require("./../assets/官网压缩图片/top_banner03.png"),
        },
      ],
      title1: false,
      title2: true,
      title3: false,
      title4: false,
      title5: false,
      title6: false,
    };
  },
  methods: {
    AboutUs() {
      this.$router.push("/AboutUs");
    },
    MainBusiness() {
      this.$router.push("/MainBusiness");
    },
    InoustaySolutions() {
      this.$router.push("/InoustaySolutions");
    },
    ServiceCase() {
      this.$router.push("/ServiceCase");
    },
    NewsInformation() {
      this.$router.push("/NewsInformation");
    },
    NewsDetail3() {
      this.$router.push("/NewsDetail3");
    },
    NewsDetail2() {
      this.$router.push("/NewsDetail2");
    },
    NewsDetail1() {
      this.$router.push("/NewsDetail1");
    },
    ContactUs() {
      this.$router.push("/ContactUs");
    },
    MainSystem() {
      this.$router.push("/MainSystem");
    },
    MainSoftware() {
      this.$router.push("/MainSoftware");
    },
    MainEnergy() {
      this.$router.push("/MainEnergy");
    },
    AutomobileManufacturing() {
      this.$router.push("/AutomobileManufacturing");
    },
    UrbanDrainage() {
      this.$router.push("/UrbanDrainage");
    },
    DataCenter() {
      this.$router.push("/DataCenter");
    },
    CommercialBuildings() {
      this.$router.push("/CommercialBuildings");
    },
    aaa() {
      this.title1 = true;
      this.title2 = false;
      this.title3 = false;
      this.title4 = false;
      this.title5 = false;
      this.title6 = false;
    },
    bbb() {
      this.title1 = false;
      this.title2 = true;
      this.title3 = false;
      this.title4 = false;
      this.title5 = false;
      this.title6 = false;
    },
    ccc() {
      this.title1 = false;
      this.title2 = false;
      this.title3 = true;
      this.title4 = false;
      this.title5 = false;
      this.title6 = false;
    },
    ddd() {
      this.title1 = false;
      this.title2 = false;
      this.title3 = false;
      this.title4 = true;
      this.title5 = false;
      this.title6 = false;
    },
    eee() {
      this.title1 = false;
      this.title2 = false;
      this.title3 = false;
      this.title4 = false;
      this.title5 = true;
      this.title6 = false;
    },
    fff() {
      this.title1 = false;
      this.title2 = false;
      this.title3 = false;
      this.title4 = false;
      this.title5 = false;
      this.title6 = true;
    },
  },
};
</script>

<style lang="less" scoped>
.body {
  width: 100%;
  position: relative;
  .top {
    position: absolute;
    top: 0;
    z-index: 3;
    width: 100vw;
    height: 9vh;
    background: rgba(31, 31, 31, 0.2);
    display: flex;
    .gslogo {
      margin-top: 1.5vh;
      margin-left: 15vw;
      height: 6vh;
      margin-right: 2vw;
    }
    .type_item {
      position: relative;
      margin-left: 2.2vw;
      color: rgb(255, 255, 255);
      font-size: 1vw;
      margin-top: 2.9vh;
      height: 3.2vh;
      cursor: pointer;
      line-height: 3.2vh;
    }
    .type_item1:hover {
      color: rgb(61, 174, 109);
    }
    .typeActive {
      // color: rgb(61, 174, 109);
      color: #87ffba;
      font-weight: bold;
    }
    .typeActive1 {
      width: 50%;
      margin-left: 25%;
      margin-top: 0.5vh;
      height: 2px;
      background: rgb(61, 174, 109);
    }
    .a {
      margin-left: 3vw;
      color: rgb(255, 255, 255);
      .ab {
        background: rgb(61, 174, 109);
        width: 12vw;
        border-radius: 50px;
        text-align: center;
        font-size: 0.9vw;
        color: rgb(255, 255, 255);
        font-family: Microsoft YaHei;
        letter-spacing: 12%;
        margin-top: 2.5vh;
        height: 4vh;
        line-height: 4vh;
      }
    }
  }
  /deep/.el-carousel__indicators--horizontal {
    .el-carousel__indicator--horizontal button {
      width: 5vw;
      height: 0.5vh;
      background: rgba(255, 255, 255, 1);
    }

    .el-carousel__indicator--horizontal.is-active button {
      width: 5vw;
      height: 0.5vh;
      background: rgba(255, 255, 255, 1);
    }
  }
  .lub {
    width: 100vw;
    .lubevery {
      width: 100%;
      .lubeveryimg {
        width: 100%;
      }
    }
  }
  .jzny {
    width: 100vw;
    height: 80vh;
    .title {
      text-align: center;
      margin-top: 5vh;
      margin-bottom: 2vh;
      color: rgba(51, 51, 51, 1);
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 1.5vw;
      letter-spacing: 30%;
      .titleleft {
        margin-right: 1vw;
        margin-bottom: 1vh;
      }
      .titleright {
        margin-left: 1vw;
        margin-bottom: 1vh;
      }
    }
    .titlea {
      text-align: center;
    }
    .alltitle {
      width: 60vw;
      height: 14vh;
      margin-top: 5vh;
      margin-left: 20vw;
      display: flex;
      .a {
        flex: 1;
        text-align: center;
        img {
          width: 2vw;
        }
        .black {
          margin-top: 2vh;
          color: rgba(51, 51, 51, 1);
          font-family: Microsoft YaHei;
          font-weight: bold;
          font-size: 2.2vh;
          letter-spacing: 4%;
        }
        .green {
          margin-top: 2vh;
          font-family: Microsoft YaHei;
          font-weight: bold;
          font-size: 2.2vh;
          letter-spacing: 4%;
          color: rgba(61, 174, 109, 1);
        }
        .c {
          margin-top: 4vh;
          width: 100%;
          height: 0.3vh;
          background: rgba(61, 174, 109, 1);
        }
      }
      .b {
        margin-top: 1vh;
        width: 1px;
        height: 74px;
        background: rgba(153, 153, 153, 0.3);
      }
    }
    .content1 {
      margin-left: 20vw;
      margin-top: 4vh;
      width: 60vw;
      height: 40vh;
      background-image: url("./../assets/官网压缩图片/首页_解决方案_BMS.png");
      background-size: cover;
      background-position: center center;
      .contenta {
        height: 100%;
        width: 25vw;
        color: rgb(255, 255, 255);
        background: rgba(28, 40, 52, 0.7);
        backdrop-filter: blur(50px);
        position: relative;
        cursor: pointer;
        .contenta1 {
          position: absolute;
          width: 15vw;
          height: 10vh;
          top: 4vh;
          left: 4vw;
          img {
            width: 2.5vw;
          }
          span {
            position: absolute;
            top: 1.5vh;
            left: 3.1vw;
            font-size: 1.1vw;
          }
        }
        .contenta2 {
          width: 17vw;
          position: absolute;
          top: 10vh;
          left: 4vw;
          font-size: 0.95vw;
          font-family: Microsoft YaHei;
          line-height: 200%;
          letter-spacing: 12%;
          text-align: justify;
        }
      }
    }
    .content2 {
      margin-left: 20vw;
      margin-top: 4vh;
      width: 60vw;
      height: 40vh;
      background-image: url("./../assets/官网压缩图片/首页_解决方案_EMS.png");
      background-size: cover;
      background-position: center center;
      .contenta {
        height: 100%;
        width: 25vw;
        color: rgb(255, 255, 255);
        background: rgba(28, 40, 52, 0.7);
        backdrop-filter: blur(50px);
        position: relative;
        cursor: pointer;
        .contenta1 {
          position: absolute;
          width: 15vw;
          height: 10vh;
          top: 4vh;
          left: 4vw;
          img {
            width: 2.5vw;
          }
          span {
            position: absolute;
            top: 1.5vh;
            left: 3.1vw;
            font-size: 1.1vw;
          }
        }
        .contenta2 {
          width: 17vw;
          position: absolute;
          top: 10vh;
          left: 4vw;
          font-size: 0.95vw;
          font-family: Microsoft YaHei;
          line-height: 200%;
          letter-spacing: 12%;
          text-align: justify;
        }
      }
    }
    .content3 {
      margin-left: 20vw;
      margin-top: 4vh;
      width: 60vw;
      height: 40vh;
      background-image: url("./../assets/官网压缩图片/首页_解决方案_能源管理.png");
      background-size: cover;
      background-position: center center;
      .contenta {
        height: 100%;
        width: 25vw;
        color: rgb(255, 255, 255);
        background: rgba(28, 40, 52, 0.7);
        backdrop-filter: blur(50px);
        position: relative;
        cursor: pointer;
        .contenta1 {
          position: absolute;
          width: 15vw;
          height: 10vh;
          top: 4vh;
          left: 4vw;
          img {
            width: 2.5vw;
          }
          span {
            position: absolute;
            top: 1.5vh;
            left: 3.1vw;
            font-size: 1.1vw;
          }
        }
        .contenta2 {
          width: 17vw;
          position: absolute;
          top: 10vh;
          left: 4vw;
          font-size: 0.95vw;
          font-family: Microsoft YaHei;
          line-height: 200%;
          letter-spacing: 12%;
          text-align: justify;
        }
      }
    }
    .content4 {
      margin-left: 20vw;
      margin-top: 4vh;
      width: 60vw;
      height: 40vh;
      background-image: url("./../assets/官网压缩图片/首页_解决方案_环保软件.png");
      background-size: cover;
      background-position: center center;
      .contenta {
        height: 100%;
        width: 25vw;
        color: rgb(255, 255, 255);
        background: rgba(28, 40, 52, 0.7);
        backdrop-filter: blur(50px);
        position: relative;
        cursor: pointer;
        .contenta1 {
          position: absolute;
          width: 15vw;
          height: 10vh;
          top: 4vh;
          left: 4vw;
          img {
            width: 2.5vw;
          }
          span {
            position: absolute;
            top: 1.5vh;
            left: 3.1vw;
            font-size: 1.1vw;
          }
        }
        .contenta2 {
          width: 17vw;
          position: absolute;
          top: 10vh;
          left: 4vw;
          font-size: 0.95vw;
          font-family: Microsoft YaHei;
          line-height: 200%;
          letter-spacing: 12%;
          text-align: justify;
        }
      }
    }
    .content5 {
      margin-left: 20vw;
      margin-top: 4vh;
      width: 60vw;
      height: 40vh;
      background-image: url("./../assets/官网压缩图片/首页_解决方案_节能系统.png");
      background-size: cover;
      background-position: center center;
      .contenta {
        height: 100%;
        width: 25vw;
        color: rgb(255, 255, 255);
        background: rgba(28, 40, 52, 0.7);
        backdrop-filter: blur(50px);
        position: relative;
        cursor: pointer;
        .contenta1 {
          position: absolute;
          width: 15vw;
          height: 10vh;
          top: 4vh;
          left: 4vw;
          img {
            width: 2.5vw;
          }
          span {
            position: absolute;
            top: 1.5vh;
            left: 3.1vw;
            font-size: 1.1vw;
          }
        }
        .contenta2 {
          width: 17vw;
          position: absolute;
          top: 10vh;
          left: 4vw;
          font-size: 0.95vw;
          font-family: Microsoft YaHei;
          line-height: 200%;
          letter-spacing: 12%;
          text-align: justify;
        }
      }
    }
    .content6 {
      margin-left: 20vw;
      margin-top: 4vh;
      width: 60vw;
      height: 40vh;
      background-image: url("./../assets/官网压缩图片/首页_解决方案_暖通动力.png");
      background-size: cover;
      background-position: center center;
      .contenta {
        height: 100%;
        width: 25vw;
        color: rgb(255, 255, 255);
        background: rgba(28, 40, 52, 0.7);
        backdrop-filter: blur(50px);
        position: relative;
        cursor: pointer;
        .contenta1 {
          position: absolute;
          width: 15vw;
          height: 10vh;
          top: 4vh;
          left: 4vw;
          img {
            width: 2.5vw;
          }
          span {
            position: absolute;
            top: 1.5vh;
            left: 3.1vw;
            font-size: 1.1vw;
          }
        }
        .contenta2 {
          width: 17vw;
          position: absolute;
          top: 10vh;
          left: 4vw;
          font-size: 0.95vw;
          font-family: Microsoft YaHei;
          line-height: 200%;
          letter-spacing: 12%;
          text-align: justify;
        }
      }
    }
  }
  .hyyy {
    width: 100vw;
    height: 95vh;
    background-image: url("./../assets/官网背景/bg_行业应用.png");
    background-size: cover;
    background-position: center center;
    .title {
      text-align: center;
      margin-top: 1vh;
      color: rgba(51, 51, 51, 1);
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 1.5vw;
      letter-spacing: 30%;
      .titleleft {
        margin-right: 1vw;
        margin-bottom: 1vh;
      }
      .titleright {
        margin-left: 1vw;
        margin-bottom: 1vh;
      }
    }
    .titlea {
      text-align: center;
    }
    .allhyyy {
      margin-left: 15vw;
      margin-top: 4vh;
      margin-bottom: 5vh;
      width: 70vw;
      height: 80vh;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .n {
        width: 22.4vw;
        height: 38.4vh;
        border-radius: 8px;
        .a {
          margin-left: 0.56vw;
          margin-top: 0.96vh;
          width: 21.28vw;
          height: 36.48vh;
          background: rgba(255, 255, 255, 0.8);
          background: rgba(255, 255, 255, 0.8);
          box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.02),
            0px 8px 8px rgba(0, 0, 0, 0.02);
          border-radius: 8px;
          transition: all 1.2s;
          cursor: pointer;
          .aa {
            width: 21.28vw;
            height: 21.888vh;
            border-radius: 8px;
            background-image: url("./../assets/官网切图2.0/官网切图2.0/IMG_行业_生物制药.png");
            background-size: cover;
            background-position: center center;
          }
          .ba {
            width: 21.28vw;
            height: 21.888vh;
            border-radius: 8px;
            background-image: url("./../assets/官网切图2.0/官网切图2.0/IMG_行业_汽车新能源.png");
            background-size: cover;
            background-position: center center;
          }
          .ca {
            width: 21.28vw;
            height: 21.888vh;
            border-radius: 8px;
            background-image: url("./../assets/官网切图2.0/官网切图2.0/IMG_行业_城市给排水.png");
            background-size: cover;
            background-position: center center;
          }
          .da {
            width: 21.28vw;
            height: 21.888vh;
            border-radius: 8px;
            background-image: url("./../assets/官网切图2.0/官网切图2.0/IMG_行业_数据中心.png");
            background-size: cover;
            background-position: center center;
          }
          .ea {
            width: 21.28vw;
            height: 21.888vh;
            border-radius: 8px;
            background-image: url("./../assets/半导体.png");

            background-size: cover;
            background-position: center center;
          }
          .fa {
            width: 21.28vw;
            height: 21.888vh;
            border-radius: 8px;
            background-image: url("./../assets/官网切图2.0/官网切图2.0/IMG_行业_商业办公.png");
            background-size: cover;
            background-position: center center;
          }
          .b {
            width: 21.28vw;
            height: 14.592vh;
            display: flex;
            .c {
              margin-top: 1.4592vh;
              width: 9vw;
              margin-left: 1.064vw;
              display: flex;
              padding-top: 2vh;
              img {
                height: 1.5vw;
              }
              div {
                margin-left: 0.8vw;
                color: rgba(51, 51, 51, 1);
                font-family: Microsoft YaHei;
                font-weight: bold;
                font-size: 1vw;
              }
            }
            .d {
              margin-left: 1.064vw;
              width: 10.64vw;
              .first {
                margin-top: 1vh;
                display: flex;
                height: 4vh;
                line-height: 4vh;
                color: rgba(51, 51, 51, 1);
                font-family: Microsoft YaHei;
                font-size: 0.8vw;
                .dian {
                  width: 0.3vh;
                  height: 0.3vh;
                  border-radius: 50%;
                  margin-right: 1vw;
                  background: rgba(102, 102, 102, 1);
                  margin-top: 1.85vh;
                }
              }
              .second {
                display: flex;
                height: 4vh;
                line-height: 4vh;
                color: rgba(51, 51, 51, 1);
                font-family: Microsoft YaHei;
                font-size: 0.8vw;
                .dian {
                  width: 0.3vh;
                  height: 0.3vh;
                  border-radius: 50%;
                  margin-right: 1vw;
                  background: rgba(102, 102, 102, 1);
                  margin-top: 1.85vh;
                }
              }
            }
          }
        }
        .a:hover {
          cursor: pointer;
          transform: scale(1.1);
          box-shadow: 0px 16px 50px rgba(0, 0, 0, 0.12),
            0px 8px 8px rgba(0, 0, 0, 0.02);
        }
      }
    }
  }
  .ryzz {
    width: 100vw;
    padding-bottom: 10vh;
    background: linear-gradient(
      180deg,
      rgba(249, 249, 249, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    .title {
      text-align: center;
      padding-top: 3vh;
      color: rgba(51, 51, 51, 1);
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 1.5vw;
      letter-spacing: 30%;
      .titleleft {
        margin-right: 1vw;
        margin-bottom: 1vh;
      }
      .titleright {
        margin-left: 1vw;
        margin-bottom: 1vh;
      }
    }
    .titlea {
      text-align: center;
    }
    .ryzzlub {
      margin-top: 7vh;
      margin-left: 15vw;
      width: 70vw;
      .lubimg {
        width: 25vw;
        margin-left: 5vw;
        text-align: center;
        .img {
          width: 100%;
        }
        .label {
          margin-top: 1.5vh;
          color: rgba(51, 51, 51, 1);
          font-family: Microsoft YaHei;
          font-weight: bold;
          font-size: 1.1vw;
        }
      }
    }
  }
  .xwzx {
    width: 100vw;
    height: 70vh;
    background-image: url("./../assets/官网背景/bg_解决方案.png");
    background-size: cover;
    background-position: center center;
    .title {
      text-align: center;
      margin-top: 5vh;
      color: rgba(51, 51, 51, 1);
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 1.5vw;
      letter-spacing: 30%;
      .titleleft {
        margin-right: 1vw;
        margin-bottom: 1vh;
      }
      .titleright {
        margin-left: 1vw;
        margin-bottom: 1vh;
      }
    }
    .titlea {
      text-align: center;
    }
    .allxwzx {
      width: 70vw;
      height: 50vh;
      margin-left: 15vw;
      margin-top: 5vh;
      display: flex;
      justify-content: space-around;
      .xwzximg {
        width: 45%;
        height: 100%;
        background-image: url("./../assets/官网切图汇总/官网切图补充/首页_新闻资讯.png");
        background-size: cover;
        background-position: center center;
        position: relative;
      }
      .xwzxlabel {
        width: 45%;
        height: 100%;
        .label {
          width: 95%;
          margin-top: 1.3%;
          margin-bottom: 4%;
          height: 28%;
          background: rgba(255, 255, 255, 1);
          box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.04),
            0px 2px 8px rgba(0, 0, 0, 0.04);
          border-radius: 5px;
          display: flex;
          align-items: center;
          cursor: pointer;
          .a {
            flex: 1;
            .c {
              color: rgba(153, 153, 153, 1);
              font-family: Barlow-SemiBold;
              font-size: 1.6vw;
              margin-left: 2.5vw;
            }
            .d {
              color: rgba(153, 153, 153, 1);
              font-family: Microsoft YaHei;
              font-size: 0.7vw;
              margin-left: 2.5vw;
            }
          }
          .b {
            flex: 2.5;
            .h {
              width: 80%;
              margin-left: 10%;
              color: rgba(51, 51, 51, 1);
              font-family: Microsoft YaHei;
              font-weight: bold;
              font-size: 1vw;
              line-height: 3.6vh;
            }
            .i {
              width: 80%;
              margin-left: 10%;
              color: rgba(102, 102, 102, 1);
              font-family: Microsoft YaHei;
              font-size: 0.8vw;
              line-height: 36px;
              word-break: break-all;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1; /* 这里是超出几行省略 */
            }
          }
          .e {
            width: 1px;
            height: 60%;
            background: rgba(153, 153, 153, 0.2);
            margin-top: 3%;
          }
        }
        .label:hover {
          background: rgba(29, 41, 52, 1);
          .c {
            color: rgba(255, 255, 255, 0.6);
          }
          .d {
            color: rgba(255, 255, 255, 0.6);
          }
          .e {
            background: rgba(153, 153, 153, 0.2);
          }
          .b {
            .h {
              color: rgba(255, 255, 255, 1);
            }
            .i {
              color: rgba(255, 255, 255, 1);
            }
          }
        }
      }
    }
  }
  .hzqy {
    margin-top: 2vh;
    width: 100vw;
    .title {
      text-align: center;
      margin-top: 5vh;
      color: rgba(51, 51, 51, 1);
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 1.5vw;
      letter-spacing: 30%;
      .titleleft {
        margin-right: 1vw;
        margin-bottom: 1vh;
      }
      .titleright {
        margin-left: 1vw;
        margin-bottom: 1vh;
      }
    }
    .titlea {
      text-align: center;
    }
    .gslg {
      margin-top: 10vh;
      width: 68vw;
      margin-left: 16vw;
      margin-bottom: 10vh;
    }
  }
}
</style>
