<template>
  <div class="allzyywright">
    <div class="title1">
      <div class="left"></div>
      <div class="title">系统介绍</div>
      <div class="big"></div>
      <div class="middle"></div>
      <div class="small"></div>
    </div>
    <p class="content1">
      IWecosys“水生态”数字化管控平台作为排水数据信息化监控管理平台，能够监测城市排水管网的运行状态，通过数据分析、数据建模的方式优化排水管网系统调度，指导城市排水系统的运行。
    </p>
    <div class="title3">
      <div class="left"></div>
      <div class="title">主要功能</div>
      <div class="big"></div>
      <div class="middle"></div>
      <div class="small"></div>
    </div>
    <div class="underimg">
      <div class="title">
        <div class="yuan"></div>
        <div class="label">管理驾驶舱</div>
      </div>
      <p class="content2">
        汇聚排水各部门关键数据资源，实现人、设备、环境、排水相关资源的可视化管理，构建集“总体态势、监测预警、事件管理、应急指挥、决策分析”于一体的智慧排水运营管理中心，实现城市排水运行综合状况“一屏”全面感知、全域可览
      </p>
      <div class="title">
        <div class="yuan"></div>
        <div class="label">管网监测</div>
      </div>
      <p class="content2">
        管网的流量排水设施进行在线管理，对当前管网可能存在的漏水点进行智能化预警，可视化呈现管网状态的实时在线监测、辅助预警分析、流量曲线分析、水质曲线分析、液位曲线分析、时段对比分析、同比数据曲线、环比数据曲线等数据及分析结果。
      </p>
      <div class="title">
        <div class="yuan"></div>
        <div class="label">汛期内涝监测预警</div>
      </div>
      <p class="content2">
        依托分析预警模型对汇聚的数据进行深度挖掘和分析研判，当监测数据达到系统设置的预警值后，智能控制泵站运行状态，同时将自动生成的内涝积水预警信息，帮助中心快速响应制定最优的调度措施。
      </p>
      <div class="title">
        <div class="yuan"></div>
        <div class="label">管网智能调度</div>
      </div>
      <p class="content2">
        通过获取的各泵站的实时需求、污水厂处理能力、管网蓄水状况以及雨水汛情等数据信息，结合大数据人工智能算法，自动选择泵站运行方案、管网调度措施等。
      </p>
    </div>
    <div class="flextu">
      <div class="img">
        <img
          src="./../assets/官网切图汇总/主营业务_软件产品中心04.png"
          alt=""
        />
      </div>
      <div class="img">
        <img
          src="./../assets/官网切图汇总/主营业务_软件产品中心05.png"
          alt=""
        />
      </div>
      <div class="img">
        <img
          src="./../assets/官网切图汇总/主营业务_软件产品中心06.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {};
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.allzyywright {
  width: 48vw;
  height: 100%;
  .title1 {
    display: flex;
    height: 5vh;
    .left {
      margin-top: 1.45vh;
      margin-left: 2.5vw;
      width: 0.7vh;
      height: 2.1vh;
      border-radius: 5px;
      background: rgba(61, 174, 109, 1);
    }
    .title {
      margin-left: 1vw;
      line-height: 5vh;
      color: rgba(51, 51, 51, 1);
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 2.5vh;
    }
    .big {
      margin-left: 0.5vw;
      margin-top: 2.05vh;
      width: 0.9vh;
      height: 0.9vh;
      background: rgba(61, 174, 109, 1);
      border-radius: 50%;
    }
    .middle {
      margin-left: 0.5vw;
      margin-top: 2.2vh;
      width: 0.6vh;
      height: 0.6vh;
      background: rgba(61, 174, 109, 1);
      border-radius: 50%;
    }
    .small {
      margin-left: 0.5vw;
      margin-top: 2.35vh;
      width: 0.3vh;
      height: 0.3vh;
      background: rgba(61, 174, 109, 1);
      border-radius: 50%;
    }
  }
  .title3 {
    display: flex;
    height: 5vh;
    margin-top: 2vh;
    margin-bottom: 3vh;
    .left {
      margin-top: 1.45vh;
      margin-left: 2.5vw;
      width: 0.7vh;
      height: 2.1vh;
      border-radius: 5px;
      background: rgba(61, 174, 109, 1);
    }
    .title {
      margin-left: 1vw;
      line-height: 5vh;
      color: rgba(51, 51, 51, 1);
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 2.5vh;
    }
    .big {
      margin-left: 0.5vw;
      margin-top: 2.05vh;
      width: 0.9vh;
      height: 0.9vh;
      background: rgba(61, 174, 109, 1);
      border-radius: 50%;
    }
    .middle {
      margin-left: 0.5vw;
      margin-top: 2.2vh;
      width: 0.6vh;
      height: 0.6vh;
      background: rgba(61, 174, 109, 1);
      border-radius: 50%;
    }
    .small {
      margin-left: 0.5vw;
      margin-top: 2.35vh;
      width: 0.3vh;
      height: 0.3vh;
      background: rgba(61, 174, 109, 1);
      border-radius: 50%;
    }
  }
  .content1 {
    margin-top: 2vh;
    width: 42vw;
    margin-left: 3vw;
    color: rgba(102, 102, 102, 1);
    font-family: Microsoft YaHei;
    font-size: 1.7vh;
    line-height: 3.4vh;
    margin-bottom: 4vh;
  }
  .contentimg {
    width: 42vw;
    margin-top: 2vh;
    margin-left: 3vw;
    margin-bottom: 3vh;
  }
  .underimg {
    width: 45vw;
    margin-left: 1.5vw;

    .title {
      height: 5vh;
      line-height: 5vh;
      display: flex;
      margin-left: 1vw;
      .yuan {
        width: 0.4vh;
        height: 0.4vh;
        border-radius: 50%;
        margin-top: 2.3vh;
        background: rgba(153, 153, 153, 1);
      }
      .label {
        margin-left: 0.5vw;
        color: rgba(51, 51, 51, 1);
        font-family: Microsoft YaHei;
        font-size: 1vw;
        font-weight: bold;
      }
    }
    .content2 {
      margin-left: 1.5vw;
      width: 42vw;
      color: rgba(102, 102, 102, 1);
      font-family: Microsoft YaHei;
      font-size: 0.8vw;
      line-height: 2.8vh;
      margin-top: 1vh;
      margin-bottom: 2vh;
    }
  }
  .flextu {
    width: 45vw;
    margin-left: 1.5vw;
    display: flex;
    .img {
      width: 15vw;
      text-align: center;
      img {
        width: 90%;
      }
      div {
        margin-top: 1vh;
        text-align: center;
        line-height: 3vh;
        color: rgba(51, 51, 51, 1);
        font-family: Microsoft YaHei;
        font-size: 1.2vh;
        margin-bottom: 2vh;
      }
    }
  }
}
</style>
